import { FocusManager } from "react-sunbeam";

// eslint-disable-next-line
export enum FocusKey {
  Root = "ROOT",

  Navigation = "NAVIGATION",
  NavigationSearch = "NAVIGATION_SEARCH",
  NavigationHome = "NAVIGATION_HOME",
  NavigationLive = "NAVIGATION_LIVE",
  NavigationCourses = "NAVIGATION_COURSES",
  NavigationSleep = "NAVIGATION_SLEEP",
  NavigationMusic = "NAVIGATION_MUSIC",
  NavigationParents = "NAVIGATION_PARENTS",

  PageContainer = "PAGE_CONTAINER", // TODO: Deprecate and remove
}

export const focusManager = new FocusManager({
  initialFocusPath: [FocusKey.Root],
});
