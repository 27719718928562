import { Box, Flex, Image } from "@chakra-ui/react";
import React from "react";
import InsightLogo from "../../assets/images/insight-logo.svg";
import LGLogo from "../../assets/images/lg-logo.svg";

const PartnershipLogo = () => {
  return (
    <Flex align="center" justify="center">
      <Image src={LGLogo} />
      <Box width="2px" height="25px" background="#636D6D" marginX="15px" />
      <Image src={InsightLogo} mt={1} />
    </Flex>
  );
};

export default PartnershipLogo;
