import { Flex, Heading, useInterval } from "@chakra-ui/react";
import dayjs from "dayjs";
import React, { useState } from "react";
import ReactPlayer from "react-player";
import { formatDuration } from "../../common/utils/helpers";

interface Props {
  libraryItemMediaLength?: number;
  playerRef: React.RefObject<ReactPlayer>;
}

const PlayerDuration = ({ libraryItemMediaLength, playerRef }: Props) => {
  const [duration, setDuration] = useState(0);
  const currentTimerValue = libraryItemMediaLength
    ? formatDuration(
        dayjs.duration(libraryItemMediaLength - duration, "seconds")
      )
    : "";

  useInterval(() => {
    setDuration(Math.floor(playerRef.current?.getCurrentTime() ?? 0));
  }, 1000);

  if (!currentTimerValue) return null;

  return (
    <Flex>
      {currentTimerValue.split("").map((char, index) => (
        <Flex
          key={index}
          justify="center"
          w={char === ":" ? "12px" : "30px"}
          textAlign="center"
        >
          <Heading
            fontSize="48px"
            fontWeight="normal"
            lineHeight={0.9}
            textAlign="center"
          >
            {char}
          </Heading>
        </Flex>
      ))}
    </Flex>
  );
};

export default PlayerDuration;
