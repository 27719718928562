import { Box, Flex } from "@chakra-ui/react";
import flatten from "lodash/flatten";
import React, { useEffect, useRef } from "react";
import useSWR from "swr";
import {
  getFilteredLibraryItems,
  searchLibraryItem,
} from "../../common/api/LibraryItems";
import useInfiniteScroll from "../../hooks/useInfiniteScroll";
import useOnScreen from "../../hooks/useOnScreen";
import { useSearchQuery } from "../../hooks/useSearchQuery";
import CarouselLoadingSpinner from "../Spinner/CarouselLoadingSpinner";
import SearchResultTile from "../Tiles/SearchResultTile";

const ITEMS_PER_PAGE = 20 as const;

const SearchResults = () => {
  const infiniteScrollRef = useRef(null);
  const { searchQuery } = useSearchQuery();

  const isLoadMoreComponentVisible = useOnScreen(infiniteScrollRef);

  const {
    data: searchResults,
    setSize,
    isLoadingInitialData,
    isReachingEnd,
    isValidating,
  } = useInfiniteScroll(
    (index: number) => {
      const offset = index * ITEMS_PER_PAGE;
      return [searchQuery, offset, ITEMS_PER_PAGE];
    },
    (query: string, offset: number, limit: number) => {
      if (query) {
        return searchLibraryItem({
          query: query,
          offset,
          limit,
          device_lang: "en",
          publishers_only_users: true,
          search_friends_of_friends: false,
          include_workshops: false,
        });
      }

      return [];
    },
    ITEMS_PER_PAGE
  );

  const flattenedSearchResults = flatten(searchResults).filter(
    (result) => result !== undefined
  );

  const { data: popularLibraryItems } = useSWR("/api/popular", () =>
    getFilteredLibraryItems({ sort_option: "most_played" })
  );

  useEffect(() => {
    if (isLoadMoreComponentVisible && !isReachingEnd && !isValidating) {
      setSize((size) => size + 1);
    }
  }, [isLoadMoreComponentVisible, isReachingEnd, setSize, isValidating]);

  return (
    <Box w="100%" mb="100px">
      <Flex mx={2} justify="flex-start" flexWrap="wrap">
        {flattenedSearchResults.map((libraryItem) => {
          if (
            libraryItem &&
            (libraryItem?.lang?.iso_639_1 === "en" ||
              libraryItem?.lang?.iso_639_1 === "m1")
          ) {
            return (
              <SearchResultTile
                key={libraryItem.id}
                libraryItem={libraryItem}
              />
            );
          }
        })}

        {!searchQuery &&
          popularLibraryItems?.libraryItems?.map((libraryItem) => {
            return (
              <SearchResultTile
                key={libraryItem.id}
                libraryItem={libraryItem}
              />
            );
          })}
      </Flex>
      <Box
        ref={infiniteScrollRef}
        display={isLoadingInitialData ? "none" : "auto"}
      >
        {!isReachingEnd && <CarouselLoadingSpinner />}
      </Box>
    </Box>
  );
};

export default SearchResults;
