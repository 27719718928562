import axios from "axios";
import dayjs from "dayjs";
import {
  doc,
  DocumentSnapshot,
  onSnapshot as onFirebaseSnapshot,
} from "firebase/firestore";
import queryString from "query-string";
import { EventOccurrence, EventSummary } from "../types/Event";
import { db } from "../utils/firebase";
import { Filter } from "./LibraryItems";
type LiveEventsAPIRequestResponse = {
  item_summary: { event_summary: EventSummary; type: "EVENTS" };
}[];

export const LIVE_EVENTS_REFRESH_INTERVAL = 1000 * 120; // 2 minutes
export const NUM_LIVE_HERO_EVENTS = 5;

export const getAllLive = async () => {
  const filterQuery: Filter = {
    type: "LIVE_STREAM",
    content_langs: ["en"],
    occurrence_types: ["LIVE", "FUTURE"],
    status: "APPROVED",
  };

  const query = queryString.stringify(filterQuery, {
    arrayFormat: "comma",
  });
  const { data } = await axios.get<LiveEventsAPIRequestResponse>(
    `${process.env.REACT_APP_FILTERING_API_URL}api/v1/events/filter?${query}`
  );

  return data.map((item) => item.item_summary.event_summary);
};

export const getFilteredLive = async (filter: Filter) => {
  const filterQuery: Filter = {
    type: "LIVE_STREAM",
    content_langs: ["en"],
    occurrence_types: ["LIVE", "FUTURE"],
    status: "APPROVED",
  };

  const query = filter
    ? queryString.stringify(
        { ...filterQuery, ...filter },
        {
          arrayFormat: "comma",
        }
      )
    : "";

  const { data } = await axios.get<LiveEventsAPIRequestResponse>(
    `${process.env.REACT_APP_FILTERING_API_URL}api/v1/events/filter?${query}`
  );

  return data.map((item) => item.item_summary.event_summary);
};

export const getLiveEvent = async (eventId: string) => {
  const { data } = await axios.get<EventSummary>(
    `${process.env.REACT_APP_EVENTS_API_URL}${eventId}/data/event.json`
  );

  return data;
};

export function subscribeEventOccurence(
  eventId: string,
  occurrenceId: string,
  onSnapshot: (occurrence: EventOccurrence) => void
) {
  const occurrenceRef = doc(
    db,
    `events/${eventId}/occurrences/${occurrenceId}`
  );

  return onFirebaseSnapshot(occurrenceRef, (occurence) => {
    const nextOccurrence = docToObject<EventOccurrence>(occurence);

    if (nextOccurrence) {
      onSnapshot(nextOccurrence);
    }
  });
}

export function docToObject<DocType>(firebaseDoc: DocumentSnapshot) {
  if (firebaseDoc.exists()) {
    const data = firebaseDoc.data();
    if (data) {
      data.id = firebaseDoc.id;
    }
    return data as DocType;
  }

  return null;
}

export function getLiveOccurenceStatus(occurrence: EventOccurrence) {
  const startTime = dayjs(occurrence.start_date.epoch);

  if (occurrence.has_ended) {
    return "ENDED";
  } else if (occurrence.has_started || dayjs().isAfter(startTime)) {
    return "IN_PROGRESS";
  } else {
    return "UPCOMING";
  }
}

export function getCurrentLiveDuration(occurrence: EventOccurrence) {
  const now = dayjs().valueOf();
  return now - occurrence.start_date.epoch;
}

export function getLiveDuration(occurrence: EventOccurrence) {
  return occurrence.end_date.epoch - occurrence.start_date.epoch;
}

export const isYogaEvent = (occurrence: EventOccurrence) => {
  return occurrence.broadcast_summary.content_type === "YOGA" ?? false;
};
