import { Box, Flex, Heading, HStack, Image } from "@chakra-ui/react";
import React, { memo } from "react";
import StarIcon from "../../assets/icons/icon-star.svg";
import { LibraryItemSummary } from "../../common/types/Item";

interface Props {
  course: LibraryItemSummary;
}

const CourseMeta = ({ course }: Props) => {
  return (
    <HStack spacing={3}>
      <Box background="courseYellow" padding="4px 12px" borderRadius="8px">
        <Heading size="xs" fontWeight={600}>
          COURSE
        </Heading>
      </Box>

      <Flex>
        <Image src={StarIcon} mr="0.5rem" />
        <Heading size="xs" fontWeight={600}>
          {course.rating_score}
        </Heading>
      </Flex>

      <Box h="6px" w="6px" bg="white" borderRadius={12} />

      <Heading size="xs" fontWeight={600}>
        {course.days} DAYS
      </Heading>
    </HStack>
  );
};

export default memo(CourseMeta);
