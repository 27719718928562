import React from "react";
import { getFilteredLibraryItems } from "../../common/api/LibraryItems";
import { SubTopic } from "../../common/types/Topic";
import LazyLibraryItemCarousel from "../Carousel/LazyLibraryItemCarousel";

interface Props {
  subTopic: SubTopic;
}

const SubTopicSection = ({ subTopic }: Props) => {
  function getCarouselItems() {
    return getFilteredLibraryItems(subTopic.filter);
  }

  return (
    <LazyLibraryItemCarousel
      focusKey={subTopic.title}
      title={subTopic.title}
      swrKey={subTopic.id}
      fetcherFn={getCarouselItems}
      size="md"
    />
  );
};

export default SubTopicSection;
