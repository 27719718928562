import { Box } from "@chakra-ui/react";
import React, { memo, useRef } from "react";
import { Focusable } from "react-sunbeam";
import { FocusKey, focusManager } from "../../common/global/focusManager";
import { BranchCampaign } from "../../common/utils/branch";
import { SIDEBAR_WIDTH_PX } from "../../common/utils/helpers";
import { handleKeyPress } from "../../common/utils/keyboard";
import { useAnalytics } from "../../hooks/useAnalytics";
import { useFocusKey } from "../../hooks/useFocusKey";
import useScrollIntoView from "../../hooks/useScrollIntoView";
import MemberPlusAdContent from "./MemberPlusAdContent";

interface Props {
  campaign: BranchCampaign;
}

const MemberPlusAd = ({ campaign }: Props) => {
  const carouselRef = useRef<HTMLDivElement>(null);
  const { scrollIntoView } = useScrollIntoView(carouselRef);
  const { viewImpression } = useAnalytics();
  const { activeNavItem } = useFocusKey();

  function onFocus() {
    scrollIntoView();
    viewImpression();
  }

  function onKeyPress(event: KeyboardEvent) {
    handleKeyPress(event, {
      onLeftArrow: () =>
        focusManager.setFocus([FocusKey.Navigation, activeNavItem.focusKey]),
    });
  }

  return (
    <Focusable onFocus={onFocus} onKeyPress={onKeyPress}>
      {({ focused }) => (
        <Box
          ml={SIDEBAR_WIDTH_PX}
          mr={8}
          mb={16}
          ref={carouselRef}
          background="bgGrayMedium"
          borderWidth="8px"
          borderColor={focused ? "white" : "bgGray"}
          borderRadius="60px"
        >
          <MemberPlusAdContent campaign={campaign} />
        </Box>
      )}
    </Focusable>
  );
};

export default memo(MemberPlusAd);
