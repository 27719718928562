import { Box } from "@chakra-ui/react";
import React, { memo } from "react";
import { useNavigate } from "react-router-dom";
import { Focusable } from "react-sunbeam";
import { SwiperSlide } from "swiper/react/swiper-react";
import { NUM_LIVE_HERO_EVENTS } from "../../common/api/LiveEvents";
import { RouteList } from "../../common/routes/router";
import { EventSummary } from "../../common/types/Event";
import { TrackingEventName } from "../../common/types/Tracking";
import { useAnalytics } from "../../hooks/useAnalytics";
import useScrollIntoView from "../../hooks/useScrollIntoView";
import HeroButton from "../Button/HeroButton";
import Carousel from "../Carousel/Carousel";
import LiveEventHeroSlide from "../Carousel/LiveEventHeroSlide";

interface Props {
  liveEvents: EventSummary[];
}

const LiveEventsHeroSection = ({ liveEvents }: Props) => {
  const navigate = useNavigate();
  const { trackClick } = useAnalytics();
  const featuredLiveEvents = liveEvents.slice(0, NUM_LIVE_HERO_EVENTS);
  const heroRef = React.useRef<HTMLDivElement>(null);
  const { scrollIntoView } = useScrollIntoView(heroRef);

  function handlePlayButtonPress(index: number) {
    trackClick({
      click_event_name: TrackingEventName.liveHeroSlideClicked,
      live_event_id: liveEvents[index].id,
    });
    navigate(`/${RouteList.Live}/${liveEvents[index].id}/play`);
  }

  return (
    <Box ref={heroRef}>
      <Focusable onFocus={scrollIntoView}>
        {({ focused }) => (
          <Carousel
            isFocused={focused}
            numberOfSlides={featuredLiveEvents.length}
            spaceBetween={0}
            offsetBefore={0}
            offsetAfter={0}
            onEnterItem={handlePlayButtonPress}
            autoplay={{
              delay: 7000,
              disableOnInteraction: false,
            }}
          >
            {featuredLiveEvents.map((event, index) => {
              return (
                <SwiperSlide key={event.id}>
                  <LiveEventHeroSlide
                    event={event}
                    heroButton={
                      <LiveEventSlideHeroButton
                        index={index}
                        isFocused={focused}
                        onClick={handlePlayButtonPress}
                      >
                        Watch live
                      </LiveEventSlideHeroButton>
                    }
                    currentSlideIndex={index}
                  />
                </SwiperSlide>
              );
            })}
          </Carousel>
        )}
      </Focusable>
    </Box>
  );
};

interface LiveEventSlideHeroButtonProps {
  index: number;
  isFocused: boolean;
  onClick: (index: number) => void;
}

const LiveEventSlideHeroButton: React.FC<LiveEventSlideHeroButtonProps> = ({
  index,
  isFocused,
  onClick,
  children,
}) => {
  function handleClick() {
    onClick(index);
  }

  return (
    <HeroButton
      bg={isFocused ? "livePink" : "buttonUnfocused"}
      color="white"
      isFocused={isFocused}
      onClick={handleClick}
      _hover={{ bg: "livePink" }}
    >
      {children}
    </HeroButton>
  );
};

export default memo(LiveEventsHeroSection);
