import { Box } from "@chakra-ui/react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { Focusable } from "react-sunbeam";
import { getFilteredLibraryItems } from "../common/api/LibraryItems";
import { TIME_FILTERS } from "../common/types/Timer";
import { Topic } from "../common/types/Topic";
import LazyLibraryItemCarousel from "../components/Carousel/LazyLibraryItemCarousel";
import TimeFilterTopicCarousel, {
  allTopic,
} from "../components/Carousel/TimeFilterTopicCarousel";
import BrowseSection from "../components/Section/BrowseSection";
import GeneralTopicHeroSection from "../components/Section/GeneralTopicHeroSection";
import CarouselLoadingSpinner from "../components/Spinner/CarouselLoadingSpinner";
import FullPageSpinner from "../components/Spinner/FullPageSpinner";
import useInfiniteScroll from "../hooks/useInfiniteScroll";
import useOnScreen from "../hooks/useOnScreen";

const TimeFilter = () => {
  const params = useParams();
  const topicId = params.id;
  const timeFilter = TIME_FILTERS.find((t) => t.id === topicId);

  if (!timeFilter) return <FullPageSpinner />;

  return <TimeFilterContent timeFilter={timeFilter} />;
};

interface Props {
  timeFilter: Topic;
}

const ITEMS_PER_PAGE = 15;

const TimeFilterContent = ({ timeFilter }: Props) => {
  const infiniteScrollRef = useRef(null);
  const isLoadMoreComponentVisible = useOnScreen(infiniteScrollRef);

  const totalTracks = useRef(0);
  const [activeTopic, setActiveTopic] = useState<Topic>(allTopic);

  const {
    data: libraryItems,
    setSize,
    isLoadingInitialData,
    isReachingEnd,
  } = useInfiniteScroll(
    (index: number) => {
      const offset = index * ITEMS_PER_PAGE;
      return [
        activeTopic.id === "all" ? `all-${timeFilter.id}` : activeTopic.id,
        offset,
        ITEMS_PER_PAGE,
      ];
    },
    async (topicId: string, offset: number, limit: number) => {
      const results = await getFilteredLibraryItems({
        length_option: timeFilter.id,
        sort_option: "newest",
        topics: topicId.includes("all") ? undefined : [topicId],
        limit,
        offset,
      });

      totalTracks.current = results.total ?? 0;

      return results.libraryItems;
    },
    ITEMS_PER_PAGE
  );

  useEffect(() => {
    if (isLoadMoreComponentVisible && !isReachingEnd) {
      setSize((size) => size + 1);
    }
  }, [isLoadMoreComponentVisible, isReachingEnd, setSize]);

  function getPopular() {
    return getFilteredLibraryItems({
      length_option: timeFilter.id,
      sort_option: "play_count_last_7_days",
    });
  }

  const formattedTotalTracks = useCallback(() => {
    if (totalTracks.current === 10000) {
      return "10,000+";
    }

    return (totalTracks.current ?? 0).toLocaleString();
  }, []);

  return (
    <Box>
      <GeneralTopicHeroSection
        heading={timeFilter.short_description}
        subHeading={
          isLoadingInitialData ? "\u00A0" : formattedTotalTracks() + " tracks"
        }
        h="40vh"
        pt={16}
        ml={3}
      />

      <Box>
        <TimeFilterTopicCarousel setActiveTopic={setActiveTopic} />

        {activeTopic.id === "all" && (
          <LazyLibraryItemCarousel
            focusKey={`TIME-FILTER-${timeFilter.id}-POPULAR`}
            title="Popular This Week"
            swrKey={`api/timeFilter/${timeFilter.id}/popular`}
            fetcherFn={getPopular}
            size="md"
          />
        )}

        <Focusable focusKey={timeFilter.id}>
          {({ focused }) => (
            <BrowseSection
              libraryItems={libraryItems?.flat()}
              isFocused={focused}
              topicId={timeFilter.id}
            />
          )}
        </Focusable>
        <Box ref={infiniteScrollRef}>
          {!isReachingEnd ? <CarouselLoadingSpinner /> : null}
        </Box>
      </Box>
    </Box>
  );
};

export default TimeFilter;
