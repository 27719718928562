import { Box } from "@chakra-ui/react";
import React, { memo, useState } from "react";
import { Waypoint } from "react-waypoint";
import useSWR from "swr";
import { LibraryItemAPIResponse } from "../../common/api/FeaturedList";
import { LibraryItemCardSize } from "../Tiles/LibraryItemTile";
import LibraryItemCarousel from "./LibraryItemCarousel";

interface Props {
  focusKey: string;
  swrKey: string;
  fetcherFn: () => Promise<LibraryItemAPIResponse>;
  title?: string;
  size?: LibraryItemCardSize;
}

const LazyLibraryItemCarousel = ({
  focusKey,
  swrKey,
  fetcherFn,
  title,
  size = "lg",
}: Props) => {
  const [shouldFetch, setShouldFetch] = useState(false);
  const { data, isValidating } = useSWR(shouldFetch ? swrKey : null, () =>
    fetcherFn()
  );

  function fetchData() {
    if (shouldFetch === false) {
      setShouldFetch(true);
    }
  }

  if (data?.libraryItems.length === 0) {
    return null;
  }

  return (
    <Waypoint onEnter={fetchData}>
      <Box>
        <LibraryItemCarousel
          focusKey={focusKey}
          title={title ?? data?.title ?? ""}
          size={size}
          libraryItems={data?.libraryItems}
          isLoading={isValidating}
        />
      </Box>
    </Waypoint>
  );
};

export default memo(LazyLibraryItemCarousel);
