export const QUOTES = [
  {
    quote:
      "If you want to master a habit, the key is to start with repetition, not perfection.",
    author: "James Clear",
  },
  {
    quote:
      "Your friendship with yourself sets the tone for every other relationship you have.",
    author: "Robert Holden",
  },
  {
    quote:
      "The right one does not stand in your way; they make space for you to step forward.",
    author: "Rupi Kaur",
  },
  {
    quote:
      "The happiness of your life depends upon the quality of your thoughts.",
    author: "Marcus Aurelius",
  },
  {
    quote: "Healing comes when we meet our wounded places with compassion.",
    author: "Stephen Levine",
  },
  {
    quote: "Do good and good will come to you.",
    author: "Unknown",
  },
  {
    quote: "Love is not what we become but who we already are.",
    author: "Stephen Levine",
  },
  {
    quote:
      "Once you have accepted your flaws, no one can use them against you.",
    author: "George R. R. Martin",
  },
  {
    quote:
      "Don't wait for the perfect moment. Take the moment and make it perfect.",
    author: "Aryn Kyle",
  },
  {
    quote:
      "Happiness is not the absence of problems; it is the ability to deal with them.",
    author: "Steve Maraboli",
  },
  {
    quote:
      "When you realize nothing is lacking, the whole world belongs to you.",
    author: "Lao Tzu",
  },
  {
    quote: "The highest form of knowledge is empathy.",
    author: "Bill Bullard",
  },
  {
    quote:
      "Your own self-realization is the greatest service you can render the world.",
    author: "George Bernard Shaw",
  },
  {
    quote:
      "We don't stop playing because we grow old; we grow old because we stop playing.",
    author: "Unknown",
  },
  {
    quote:
      "When you go into the space of nothingness, everything becomes known.",
    author: "Gautama Buddha",
  },
  {
    quote:
      "Love yourself unconditionally, just as you love those closest to you despite their faults.",
    author: "Les Brown",
  },
  {
    quote:
      "Meditation allows us to directly participate in our lives instead of living life as an afterthought.",
    author: "Stephen Levine",
  },
  {
    quote: "Mindfulness has never met a cognition it didn't like.",
    author: "Daniel J. Siegel",
  },
  {
    quote: "Forever is composed of nows.",
    author: "Emily Dickinson",
  },
  {
    quote: "Our greatest experiences are our quietest moments.",
    author: "Friedrich Nietzsche",
  },
  {
    quote:
      "Meditation is the ultimate mobile device; you can use it anywhere, anytime unobtrusively.",
    author: "Sharon Salzberg",
  },
  {
    quote: "He who knows others is wise. He who knows himself is enlightened.",
    author: "Lao Tzu",
  },
  {
    quote: "Do not let the behaviour of others destroy your inner peace.",
    author: "Dalai Lama",
  },
  {
    quote: "What you seek is seeking you.",
    author: "Rumi ",
  },
  {
    quote: "What you allow is what will continue. ",
    author: "Unknown",
  },
  {
    quote: "Where you stand, be the soul of that place. ",
    author: "Rumi ",
  },
  {
    quote: "You have to keep breaking your heart until it opens. ",
    author: "Rumi ",
  },
  {
    quote: "Love is the water of life, jump into this water. ",
    author: "Rumi ",
  },
  {
    quote: "There is a voice that doesn't use words. Listen.",
    author: "Rumi ",
  },
  {
    quote:
      "Lovers don't finally meet somewhere. They're in each other all along. ",
    author: "Rumi ",
  },
  {
    quote: "The cure for the pain is in the pain.",
    author: "Rumi ",
  },
  {
    quote: "Loneliness is a sign you are in desperate need of yourself.",
    author: "Rupi Kaur",
  },
  {
    quote:
      "Let go of your mind and then be mindful. Close your ears and listen!",
    author: "Rumi",
  },
  {
    quote: "He who has peace of mind disturbs neither himself nor another.",
    author: "Epicurus",
  },
  {
    quote:
      "I can control my passions and emotions if I can understand their nature.",
    author: "Spinoza",
  },
  {
    quote: "To love oneself is the beginning of a lifelong romance.",
    author: "Oscar Wilde",
  },
  {
    quote: "Act the way you’d like to be and soon you'll be the way you act.",
    author: "Leonard Cohen",
  },
  {
    quote:
      "Rushing will not make you heal any faster. Slow down and pace yourself.",
    author: "Alex Elle",
  },
  {
    quote: "A healthy outside starts from the inside.",
    author: "Robert Ulrich",
  },
  {
    quote:
      "If something is wrong, fix it now. But train yourself not to worry. Worry fixes nothing.",
    author: "Ernest Hemingway",
  },
  {
    quote:
      "Don't judge each day by the harvest you reap but by the seeds you plant.",
    author: "Robert Louis Stevenson",
  },
  {
    quote:
      "The sun itself is weak when it first rises and gathers strength and courage as the day gets on.",
    author: "Charles Dickens",
  },
  {
    quote: "Who looks outside dreams. Who looks inside awakens.",
    author: "Carl Jung",
  },
  {
    quote: "All that we are is the result of what we have thought.",
    author: "Buddha",
  },
  {
    quote: "If not now, when?",
    author: "Unknown",
  },
  {
    quote: "Breathe and let be.",
    author: "Unknown",
  },
  {
    quote: "Be right here, right now.",
    author: "Unknown",
  },
  {
    quote: "Wherever you are, be there totally.",
    author: "Eckhart Tolle",
  },
  {
    quote: "Awareness is the greatest agent for change.",
    author: "Eckhart Tolle",
  },
  {
    quote: "Be kind whenever possible. It is always possible.",
    author: "Dalai Lama",
  },
  {
    quote:
      "You cannot build a deep connection with someone who is disconnected from themselves.",
    author: "Yung Pueblo",
  },
  {
    quote:
      "Just one small positive thought in the morning can change your whole day.",
    author: "Dalai Lama",
  },
  {
    quote: "The future depends on what you do today.",
    author: "Mahatma Gandhi",
  },
  {
    quote: "Perfection is the mountain that has no peak.",
    author: "Emma Norris",
  },
  {
    quote:
      "Don't use your energy to worry. Use your energy to believe, trust, grow and heal.",
    author: "Unknown",
  },
  {
    quote: "Life takes you to unexpected places. Love brings you home.",
    author: "Melissa McClone",
  },
  {
    quote: "I am not what happened to me. I am what I choose to become.",
    author: "Carl Jung ",
  },
  {
    quote: "Where you spend your attention is where you spend your life.",
    author: "James Clear",
  },
  {
    quote: "You can't be a resource for others unless you nourish yourself. ",
    author: "Alexandra Stoddard",
  },
  {
    quote: "Calmness of mind is one of the beautiful jewels of wisdom.",
    author: "James Allen",
  },
  {
    quote: "In a gentle way, you can shake the world.",
    author: "Mahatma Gandhi",
  },
  {
    quote:
      "The only difference between ordinary and extraordinary is that little extra.",
    author: "Jimmy Johnson",
  },
  {
    quote:
      "At any given moment you have the power to say: this is not how the story is going to end.",
    author: "Christine Mason Miller",
  },
  {
    quote:
      "Sometimes the most important thing in a whole day is the rest we take between two deep breaths.",
    author: "Etty Hillesum",
  },
  {
    quote:
      "When we give ourselves compassion, we are opening our hearts in a way that can transform our lives.",
    author: "Kristin Neff",
  },
  {
    quote: "Self-care is not selfish. You cannot serve from an empty vessel.",
    author: "Eleanor Brownn",
  },
  {
    quote: "Those who flow as life flows know they need no other force.",
    author: "Lao Tsu",
  },
  {
    quote: "The little things? The little moments? They aren't little.",
    author: "Jon Kabat-Zinn",
  },
  {
    quote:
      "Open the window of your mind. Allow the fresh air, new lights and new truths to enter.",
    author: "Amit Ray",
  },
  {
    quote: "Peace comes from within. Do not seek it without.",
    author: "Buddha",
  },
  {
    quote: "You can't stop the waves, but you can learn to surf.",
    author: "Jon Kabat-Zinn",
  },
  {
    quote:
      "Feelings come and go like clouds in a windy sky. Conscious breathing is my anchor.",
    author: "Thich Nhat Hanh",
  },
  {
    quote:
      "Look past your thoughts, so you may drink the pure nectar of This Moment.",
    author: "Rumi",
  },
  {
    quote: "Adopt the pace of nature: Her secret is patience.",
    author: "Ralph Waldo Emerson",
  },
  {
    quote: "This is a wonderful day. I've never seen this one before.",
    author: "Maya Angelou",
  },
  {
    quote:
      "To understand the immeasurable, the mind must be extraordinarily quiet.",
    author: "Jiddu Krishnamurti",
  },
  {
    quote: "Your goal is not to battle with the mind, but to witness the mind.",
    author: "Swami Muktananda",
  },
  {
    quote: "In meditation, be like a cat purring.",
    author: "Tai Sheridan",
  },
  {
    quote:
      "The only way to live is by accepting each minute as an unrepeatable miracle.",
    author: "Tara Brach",
  },
  {
    quote:
      "The present moment is filled with joy and happiness. If you are attentive, you will see it.",
    author: "Thich Nhat Hanh",
  },
  {
    quote:
      "What would it be like if I could accept life. Accept this moment exactly as it is?",
    author: "Tara Brach",
  },
  {
    quote: "Walk as if you are kissing the Earth with your feet.",
    author: "Thich Nhat Hanh",
  },
  {
    quote:
      "The greatest communication is usually how we are rather than what we say.",
    author: "Joseph Goldstein",
  },
  {
    quote:
      "Be happy in the moment, that's enough. Each moment is all we need, not more.",
    author: "Mother Teresa",
  },
  {
    quote:
      "The real voyage of discovery consists not in seeking out new landscapes but in having new eyes.",
    author: "Marcel Proust",
  },
  {
    quote: "The challenge is not to be perfect — it is to be whole.",
    author: "Jane Fonda",
  },
  {
    quote:
      "Almost everything will work again if you unplug it for a few minutes, including you.",
    author: "Anne Lamott",
  },
  {
    quote: "No mud, no lotus.",
    author: "Thich Nhat Hanh",
  },
  {
    quote:
      "The more you know yourself, the more patience you have for what you see in others.",
    author: "Erik H. Erikson",
  },
  {
    quote: "There are some things you learn best in calm and some in storm.",
    author: "Willa Cather",
  },
  {
    quote:
      "Looking at beauty in the world is the first step of purifying the mind.",
    author: "Amit Ray",
  },
  {
    quote:
      "Our greatest weapon against stress is our ability to choose one thought over another.",
    author: "William James",
  },
  {
    quote: "By doing a little bit each day, you can get a lot accomplished.",
    author: "Gretchen Rubin",
  },
  {
    quote:
      "Never lose track of the horizon, even if in this moment you are not seeing it.",
    author: "Mark Nepo",
  },
  {
    quote:
      "The only life worth living is the one we take responsibility for choosing.",
    author: "Alexandra Stoddard",
  },
  {
    quote:
      "If you are willing to rethink how you see, you may be surprised what comes into view.",
    author: "Aaron Morris",
  },
  {
    quote: "Be still. The quieter you become, the more you can hear.",
    author: "Ram Dass",
  },
  {
    quote: "Those who don't believe in magic will never find it. ",
    author: "Roald Dahl ",
  },
  {
    quote:
      "We are what we repeatedly do. Excellence then is not an act but a habit. ",
    author: "Aristotle ",
  },
  {
    quote: "Never go to sleep without a request to your subconsciousness. ",
    author: "Thomas Edison",
  },
  {
    quote: "Improvement isn't inevitable. Change is. ",
    author: "Unknown",
  },
  {
    quote: "The key to growth is to learn to make promises and to keep them. ",
    author: "Stephen R. Covey ",
  },
  {
    quote:
      "How wonderful it is that nobody need wait a single moment before starting to improve the world. ",
    author: "Anne Frank",
  },
  {
    quote:
      "First, you have to believe in yourself before others can believe in you. ",
    author: "Mimi Ikonn ",
  },
  {
    quote: "When I let go of what I am, I become what I might be. ",
    author: "Lao tzu ",
  },
  {
    quote:
      "Yesterday I was clever, so I wanted to change the world. Today I am wise, so I am changing myself.",
    author: "Rumi ",
  },
  {
    quote: "The cave you fear to enter holds the treasure you seek. ",
    author: "Joseph Campbell",
  },
  {
    quote: "The point is not to pay back kindness but to pass it on. ",
    author: "Julia Alvarez",
  },
  {
    quote: "Light tomorrow with today.",
    author: "Elizabeth Barret Browning ",
  },
  {
    quote:
      "The art of listening is not to hear what someone says but to hear how they feel. ",
    author: "Bob Chapman ",
  },
  {
    quote:
      "Anything you want to ask a teacher, ask yourself and wait for the answer in silence. ",
    author: "Byron Katie ",
  },
  {
    quote:
      "A happy life consists not in the absence but in the mastery of hardships. ",
    author: "Hellen Keller",
  },
  {
    quote: "The world is changed by examples, not by opinions. ",
    author: "Paulo Coelho ",
  },
  {
    quote:
      "If you spend your whole life waiting for the storm, you'll never enjoy the sunshine. ",
    author: "Morris West ",
  },
  {
    quote:
      "If you are not willing to risk the unusual, you will have to settle for the ordinary. ",
    author: "Jim Rohn",
  },
  {
    quote:
      "Do not follow where the path may lead. Go instead where there is no path and leave a trail. ",
    author: "Doug Larson ",
  },
  {
    quote: "Don't be pushed by your problems, be led by your dreams. ",
    author: "Ralph Waldo Emerson",
  },
  {
    quote: "Those who wish to sing always find a song. ",
    author: "Swedish Proverb ",
  },
  {
    quote: "One kind word can warm three winter months. ",
    author: "Japanese Proverb ",
  },
  {
    quote:
      "When we are no longer able to change a situation, we are challenged to change ourselves. ",
    author: "Viktor Frankl",
  },
  {
    quote:
      "If you want to go fast, go alone. If you want to go far, go together. ",
    author: "Arikan Proverb ",
  },
  {
    quote: "In every walk with nature one receives far more than he seeks. ",
    author: "John Muir",
  },
  {
    quote:
      "In the depths of winter, I finally learned there was in me an invincible summer. ",
    author: "Albert Camus",
  },
  {
    quote:
      "Only when we are brave enough to explore the darkness will we discover the infinite power of our light. ",
    author: "Brené Brown ",
  },
  {
    quote: "Hope begins in the dark. ",
    author: "Anne Lamott",
  },
  {
    quote:
      "Often when you think you're at the end of something, you're at the beginning of something else. ",
    author: "Fred Rogers ",
  },
  {
    quote:
      "The best time to plant a tree was 20 years ago. The second best time is now. ",
    author: "Chinese Proverb",
  },
  {
    quote:
      "Happiness is not something ready made. It comes from your own actions.",
    author: "Dalai Lama XIV",
  },
  {
    quote:
      "Great things are done by a series of small things brought together.",
    author: "Vincent Van Gogh",
  },
  {
    quote:
      "Very often, a change of self is needed more than a change of scene.",
    author: "A.C. Benson",
  },
  {
    quote:
      "Don't be pushed around by the fears in your mind. Be led by the dreams in your heart.",
    author: "Roy T. Bennett",
  },
  {
    quote: "Work hard in silence, let your success be the noise.",
    author: "Frank Ocean",
  },
  {
    quote: "When you don't know what to do, be still.",
    author: "Jasmine Marie",
  },
  {
    quote: "If it's out of your hands, it deserves freedom from your mind too.",
    author: "Ivan Nuru",
  },
  {
    quote:
      "Remember that sometimes not getting what you want is a wonderful stroke of luck.",
    author: "Dalai Lama XIV",
  },
  {
    quote: "Love is the bridge between you and everything.",
    author: "Rumi ",
  },
  {
    quote: "Stress is caused by being 'here' and wanting to be 'there.'",
    author: "Eckhart Tolle",
  },
  {
    quote: "Sometimes in the waves of change, we find our true direction.",
    author: "Unknown",
  },
  {
    quote:
      "Even the smallest shift in perspective can bring about the greatest healing.",
    author: "Joshua Kai",
  },
  {
    quote: "The energy of the mind is the essence of life.",
    author: "Aristotle",
  },
  {
    quote:
      "We fall. We break. We fail. But then, we rise. We heal. We overcome. ",
    author: "Kiana Azizian ",
  },
  {
    quote:
      "The biggest communication problem is we do not listen to understand. We listen to reply. ",
    author: "Stephen R. Covey",
  },
  {
    quote: "Not until we are lost do we begin to understand ourselves.",
    author: "Henry David Thoreau",
  },
  {
    quote: "The best way out is always through. ",
    author: "Robert Frost",
  },
  {
    quote: "To the mind that is still, the whole universe surrenders.",
    author: "Lao Tzu",
  },
  {
    quote: "No cure that fails to engage our spirit can make us well.",
    author: "Victor Frankl",
  },
  {
    quote:
      "Out beyond ideas of wrongdoing and rightdoing, there is a field. I'll meet you there. ",
    author: "Rumi",
  },
  {
    quote: "Talk to yourself like you talk to someone you love.",
    author: "Brené Brown",
  },
  {
    quote:
      "Safety is not the absence of threat it is the presence of connection.",
    author: "Garbor Maté",
  },
  {
    quote: "What you believe you experience.",
    author: "Jiddu Krishnamurti",
  },
  {
    quote:
      "There are a thousand reasons to live this life, every one of them is sufficient.",
    author: "Marilynne Robinson",
  },
  {
    quote: "The present moment is the only time over which we have dominion.",
    author: "Thich Nhat Hanh",
  },
  {
    quote: "Don't go through life, grow through life.",
    author: "Eric Butterworth",
  },
  {
    quote:
      "There is hope in people, not in society, not in systems, but in you and me.",
    author: "Jiddu Krishnamurti",
  },
  {
    quote: "We live in the world when we love it.",
    author: "Rabindranath Tagore",
  },
  {
    quote: "Be not afraid of growing slowly; be afraid only of standing still.",
    author: "Chinese Proverb",
  },
  {
    quote: "These pains you feel are messengers. Listen to them.",
    author: "Rumi",
  },
  {
    quote:
      "When you let go of the outcome and stop chasing your desires, what you desire starts chasing you.",
    author: "Dr. Wayne Dryer",
  },
  {
    quote: "The best way out is always through.",
    author: "Robert Frost",
  },
  {
    quote:
      "Enjoy the little things, for one day you may look back and realize they were the big things.",
    author: "Robert Breault",
  },
  {
    quote: "Happiness is the highest good.",
    author: "Aristotle",
  },
  {
    quote: "Deep breathing is our nervous system’s love language.",
    author: "Dr. Lauren Fogel Mersy",
  },
  {
    quote:
      "Life is really just a lesson in finding balance between fear and courage.",
    author: "Cara E. Yar Khan",
  },
  {
    quote:
      "Often it’s the deepest pain which empowers you to grow into your highest self.",
    author: "Karen Salmansohn",
  },
  {
    quote: "Gratitude begins the moment we recognize that life is a gift.",
    author: "Sheiba Mary Philip",
  },
  {
    quote: "An eye for eye only ends up making the whole world blind.",
    author: "Mahatma Gandhi",
  },
  {
    quote: "Nature does not hurry, yet everything is accomplished.",
    author: "Lao Tzu",
  },
  {
    quote: "Once you have accepted your flaws no one can use them against you.",
    author: "George R. R. Martin",
  },
  {
    quote: "Your biggest commitment must always be to yourself.",
    author: "Bridgett Devoue",
  },
] as const;
