import { Box, Flex } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { focusManager } from "../../common/global/focusManager";
import { LibraryItemSummary } from "../../common/types/Item";
import { SIDEBAR_WIDTH_PX } from "../../common/utils/helpers";
import useKeyboardNavigation from "../../hooks/useKeyboardNavigation";
import CarouselHeading from "../Carousel/CarouselHeading";
import CarouselLoadingSpinner from "../Spinner/CarouselLoadingSpinner";
import SearchResultTile from "../Tiles/SearchResultTile";

interface Props {
  isFocused: boolean;
  topicId: string;
  libraryItems?: LibraryItemSummary[];
}

const BrowseSection = ({ libraryItems, isFocused, topicId }: Props) => {
  const [isFirstFocus, setIsFirstFocus] = useState(false);

  useKeyboardNavigation(isFocused, {
    onLeftArrow: () => focusManager.moveLeft(),
    onRightArrow: () => focusManager.moveRight(),
  });

  useEffect(() => {
    if (isFocused && libraryItems?.length && !isFirstFocus) {
      focusManager.setFocus(["ROOT", topicId, libraryItems?.[0].id]);
      setIsFirstFocus(true);
    }
  }, [isFirstFocus, isFocused, libraryItems, topicId]);

  useEffect(() => {
    if (!isFocused) {
      setIsFirstFocus(false);
    }
  }, [isFocused]);

  if (!libraryItems) return <CarouselLoadingSpinner />;

  return (
    <Box>
      <CarouselHeading content="Browse" />

      <Flex ml={SIDEBAR_WIDTH_PX} px={2} mb="100px" flexWrap="wrap">
        {libraryItems.map((libraryItem) => {
          return (
            <SearchResultTile libraryItem={libraryItem} key={libraryItem.id} />
          );
        })}
      </Flex>
    </Box>
  );
};

export default BrowseSection;
