import { HStack, Image, Text } from "@chakra-ui/react";
import React, { memo } from "react";
import MusicIcon from "../../assets/images/music_icon.svg";
import TalksIcon from "../../assets/images/talks_icon.svg";
import { LibraryItemSummary } from "../../common/types/Item";
import { mediaLengthString } from "../../common/utils/helpers";

interface Props {
  libraryItem: LibraryItemSummary;
}

const TrackLengthBadge = ({ libraryItem }: Props) => {
  const trackLength = mediaLengthString(libraryItem.media_length ?? 0);

  return (
    <HStack spacing={2} bg="#272B2B" p="2px 8px" borderRadius={6}>
      {libraryItem.content_type === "MUSIC" && (
        <Image src={MusicIcon} alt="Music icon" w={3} h={3} />
      )}

      {libraryItem.content_type === "TALKS" && (
        <Image src={TalksIcon} alt="Talks icon" w={3} h={3} />
      )}
      <Text
        textTransform="uppercase"
        color="white"
        fontSize={14}
        fontWeight={700}
      >
        {trackLength}
      </Text>
    </HStack>
  );
};

export default memo(TrackLengthBadge);
