import {
  Box,
  Flex,
  Heading,
  Image,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { Focusable } from "react-sunbeam";
import CoursesBackground from "../../assets/background/courses-background.png";
import LockIcon from "../../assets/icons/icon-lock.svg";
import MemberPlusLogo from "../../assets/member-plus.svg";
import { TrackingEventName } from "../../common/types/Tracking";
import { BranchCampaign } from "../../common/utils/branch";
import { handleKeyPress } from "../../common/utils/keyboard";
import { useAnalytics } from "../../hooks/useAnalytics";
import useScrollIntoView from "../../hooks/useScrollIntoView";
import HeroButton from "../Button/HeroButton";
import CourseQRModal from "../CourseQRModal/CouseQRModal";

const BG_IMAGE_GRADIENTS = [
  `radial-gradient(90% 80% at 70% 12%, rgba(21, 23, 23, 0) 25%, #151717 100%)`,
  `linear-gradient(90deg, #151717 0%, transparent 30%)`,
  `linear-gradient(0deg, #151717 12%, transparent 30%)`,
];

const CourseHeroSection = () => {
  const { trackClick, viewImpression } = useAnalytics();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { scrollToTop } = useScrollIntoView();

  function onKeyPress(event: KeyboardEvent) {
    handleKeyPress(event, {
      onEnter: () => {
        trackClick({
          click_event_name: TrackingEventName.unlockCoursesButtonClicked,
        });
        viewImpression();
        onOpen();
      },
    });
  }

  return (
    <Box>
      <Focusable onFocus={scrollToTop} onKeyPress={onKeyPress}>
        {({ focused }) => {
          return (
            <Flex
              width="100%"
              height="100vh"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              backgroundSize="cover"
              backgroundPosition="center"
              backgroundImage={`${BG_IMAGE_GRADIENTS.join(
                ","
              )}, url(${CoursesBackground})`}
            >
              <VStack mt={-24} spacing={4}>
                <Image src={MemberPlusLogo} alt="member plus logo" />

                <Heading
                  size="xl"
                  maxW="20ch"
                  mb="1rem"
                  textAlign="center"
                  position="relative"
                  textTransform="uppercase"
                >
                  Learn from the World&lsquo;s Best Teachers
                </Heading>

                <Heading size="sm" mb="3rem">
                  1,000+ Courses & much more!
                </Heading>

                <Box height={5} />

                <HeroButton
                  icon={LockIcon}
                  isFocused={focused}
                  bg={focused ? "courseYellow" : "buttonUnfocused"}
                  height="90px"
                  borderRadius="18px"
                  pb="5px"
                  iconSize="25px"
                  color="white"
                  iconFilter="invert(100%)"
                  _hover={{ bg: "courseYellow" }}
                  onClick={onOpen}
                >
                  Unlock all free for 1 year
                </HeroButton>
              </VStack>
            </Flex>
          );
        }}
      </Focusable>

      <CourseQRModal
        isOpen={isOpen}
        onClose={onClose}
        campaign={BranchCampaign.courseDetailModal}
      />
    </Box>
  );
};

export default CourseHeroSection;
