import { Flex, Image, Text } from "@chakra-ui/react";
import dayjs from "dayjs";
import React, { memo } from "react";
import VideoIconPink from "../../assets/icons/icon-video-pink.svg";
import VideoIcon from "../../assets/icons/icon-video.svg";
import { getLiveOccurenceStatus } from "../../common/api/LiveEvents";
import { EventSummary } from "../../common/types/Event";

interface Props {
  event: EventSummary;
}

const LiveEventBadge = ({ event }: Props) => {
  const eventStatus = getLiveOccurenceStatus(event._next_occurrences[0]);
  const startDate = new Date(event._next_occurrences[0].start_date.epoch);
  const startTime = dayjs(startDate).format("h:mm A");

  return (
    <Flex
      padding="2px 12px"
      background={eventStatus === "IN_PROGRESS" ? "livePink" : "white"}
      borderRadius={8}
      boxShadow="sm"
      align="center"
    >
      {eventStatus === "IN_PROGRESS" ? (
        <>
          <Image src={VideoIcon} alt="Video camera icon" w={5} h={5} mr={2} />
          <Text fontSize={21} fontWeight="bold" color="white">
            LIVE
          </Text>
        </>
      ) : (
        <>
          <Image
            src={VideoIconPink}
            alt="Video camera icon"
            w={5}
            h={5}
            mr={2}
          />
          <Text fontSize={21} fontWeight="bold" color="bgGrayMedium">
            {eventStatus === "UPCOMING" ? startTime : "ENDED"}
          </Text>
        </>
      )}
    </Flex>
  );
};

export default memo(LiveEventBadge);
