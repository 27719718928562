import { Modal, ModalContent, ModalOverlay } from "@chakra-ui/react";
import React, { memo, useEffect } from "react";
import { Focusable } from "react-sunbeam";
import { LibraryItemSummary } from "../../common/types/Item";
import { BranchCampaign } from "../../common/utils/branch";
import { useFocusKey } from "../../hooks/useFocusKey";
import useKeyboardNavigation from "../../hooks/useKeyboardNavigation";
import MemberPlusAdContent from "../MemberPlusAd/MemberPlusAdContent";
import CourseOverviewModalContent from "./CourseOverviewModalContent";

interface Props {
  course?: LibraryItemSummary;
  isOpen: boolean;
  onClose: () => void;
  campaign: BranchCampaign;
}

const CourseQRModal: React.FC<Props> = ({
  isOpen,
  onClose,
  course,
  campaign,
}) => {
  const { setModalIsOpen } = useFocusKey();

  useEffect(() => {
    setModalIsOpen(isOpen);
  }, [isOpen, setModalIsOpen]);

  useKeyboardNavigation(true, {
    onEnter: () => onClose(),
    onBackspace: () => onClose(),
  });

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="full"
      scrollBehavior="inside"
      isCentered
    >
      <ModalOverlay />
      <ModalContent
        margin="4rem"
        background="bgGrayMedium"
        borderRadius={30}
        h="100%"
      >
        {course ? (
          <CourseOverviewModalContent
            onClose={onClose}
            course={course}
            campaign={campaign}
          />
        ) : (
          <Focusable onKeyPress={onClose}>
            <MemberPlusAdContent campaign={campaign} />
          </Focusable>
        )}
      </ModalContent>
    </Modal>
  );
};

export default memo(CourseQRModal);
