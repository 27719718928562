import { Box, Heading, Text, VStack } from "@chakra-ui/react";
import React, { memo, useRef } from "react";
import { Focusable } from "react-sunbeam";
import { FocusKey, focusManager } from "../../common/global/focusManager";
import { LibraryItemSummary } from "../../common/types/Item";
import { SIDEBAR_WIDTH_PX } from "../../common/utils/helpers";
import { handleKeyPress } from "../../common/utils/keyboard";
import { useFocusKey } from "../../hooks/useFocusKey";
import useScrollIntoView from "../../hooks/useScrollIntoView";

interface Props {
  course: LibraryItemSummary;
}

const CourseInfo: React.FC<Props> = ({ course }) => {
  const courseInfoRef = useRef<HTMLDivElement>(null);
  const { scrollIntoView } = useScrollIntoView(courseInfoRef, 50);
  const { activeNavItem } = useFocusKey();

  function onKeyPress(event: KeyboardEvent) {
    handleKeyPress(event, {
      onLeftArrow: () =>
        focusManager.setFocus([FocusKey.Navigation, activeNavItem.focusKey]),
    });
  }

  return (
    <Focusable onKeyPress={onKeyPress} onFocus={scrollIntoView}>
      <VStack
        align="flex-start"
        width="50%"
        spacing={12}
        ml={SIDEBAR_WIDTH_PX}
        mr={8}
        ref={courseInfoRef}
      >
        <Heading size="md" textAlign="left" fontWeight={700}>
          What you&rsquo;ll learn
        </Heading>

        <Text color="white" noOfLines={22}>
          {course.long_description}
        </Text>

        <Box height={6} />

        <Box height="1px" width="100%" background="gray.500" opacity={0.5} />
      </VStack>
    </Focusable>
  );
};

export default memo(CourseInfo);
