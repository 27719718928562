import { useBreakpoint } from "@chakra-ui/react";
import React, { memo, useEffect, useMemo, useRef, useState } from "react";
import SwiperCore, { Autoplay } from "swiper";
import { Swiper } from "swiper/react/swiper-react";
import { AutoplayOptions } from "swiper/types";
import { FocusKey, focusManager } from "../../common/global/focusManager";
import { useFocusKey } from "../../hooks/useFocusKey";
import useKeyboardNavigation from "../../hooks/useKeyboardNavigation";

interface Props {
  isFocused: boolean;
  numberOfSlides: number;
  spaceBetween?: number;
  offsetBefore?: number;
  offsetAfter?: number;
  autoplay?: AutoplayOptions;
  children: React.ReactNode;
  onEnterItem?: (slideIndex: number) => void;
  onGetSwiper?: (swiper: SwiperCore) => void;
}

const Carousel: React.FC<Props> = ({
  isFocused,
  numberOfSlides,
  onEnterItem,
  spaceBetween = 10,
  offsetBefore = 215,
  offsetAfter = 70,
  autoplay = false,
  children,
  onGetSwiper,
}) => {
  const breakpoint = useBreakpoint();
  const [swiper, setSwiper] = useState<SwiperCore>();
  const activeIndex = useRef(0);
  const { activeNavItem } = useFocusKey();

  // When a carousel has < 5 items we need to use the "centeredSlides" option. The number
  // we return here should roughly be the number of cards that fit across the window.
  // TODO: We should calculate the number of cards across the window to improve this.
  const cardsWithinWindow = useMemo(() => {
    switch (breakpoint) {
      case "2xl":
        return 5;
      case "xl":
        return 4;
      case "lg":
        return 3;
      case "md":
        return 2;
      default:
        return 1;
    }
  }, [breakpoint]);

  useKeyboardNavigation(isFocused, {
    onRightArrow: () => {
      if (activeIndex.current + 1 === numberOfSlides) {
        return;
      }

      return swiper?.slideTo(activeIndex.current + 1);
    },
    onLeftArrow: () => {
      if (activeIndex.current === 0) {
        return focusManager.setFocus([
          FocusKey.Navigation,
          activeNavItem.focusKey,
        ]);
      }

      return swiper?.slideTo(activeIndex.current - 1);
    },
    onEnter: () => {
      if (onEnterItem) {
        onEnterItem(activeIndex.current);
      }
    },
  });

  function handleOnSlideChange(slides: SwiperCore) {
    activeIndex.current = slides.activeIndex;
  }

  useEffect(() => {
    if (swiper) onGetSwiper?.(swiper);
  }, [onGetSwiper, swiper]);

  return (
    <Swiper
      modules={[Autoplay]}
      autoplay={autoplay}
      slidesPerView="auto"
      slidesOffsetBefore={offsetBefore}
      slidesOffsetAfter={offsetAfter}
      spaceBetween={spaceBetween}
      normalizeSlideIndex={false}
      centeredSlides={numberOfSlides <= cardsWithinWindow}
      centeredSlidesBounds={numberOfSlides <= cardsWithinWindow}
      keyboard={{
        enabled: true,
      }}
      onInit={setSwiper}
      onSlideChange={handleOnSlideChange}
    >
      {children}
    </Swiper>
  );
};

export default memo(Carousel);
