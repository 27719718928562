import { Global } from "@emotion/react";
import React from "react";
import BrandonTextWebBlackWoff from "../../assets/fonts/BrandonTextWebBlack/font.woff";
import BrandonTextWebBlackWoff2 from "../../assets/fonts/BrandonTextWebBlack/font.woff2";
import BrandonTextWebBoldWoff from "../../assets/fonts/BrandonTextWebBold/font.woff";
import BrandonTextWebBoldWoff2 from "../../assets/fonts/BrandonTextWebBold/font.woff2";
import BrandonTextWebLightWoff from "../../assets/fonts/BrandonTextWebLight/font.woff";
import BrandonTextWebLightWoff2 from "../../assets/fonts/BrandonTextWebLight/font.woff2";
import BrandonTextWebMediumWoff from "../../assets/fonts/BrandonTextWebMedium/font.woff";
import BrandonTextWebMediumWoff2 from "../../assets/fonts/BrandonTextWebMedium/font.woff2";
import BrandonTextWebRegularWoff from "../../assets/fonts/BrandonTextWebRegular/font.woff";
import BrandonTextWebRegularWoff2 from "../../assets/fonts/BrandonTextWebRegular/font.woff2";

const Fonts = () => (
  <Global
    styles={`
      @font-face {
        font-family: 'Brandon';
        src: url(${BrandonTextWebLightWoff2}) format('woff2'),
             url(${BrandonTextWebLightWoff}) format('woff');
        font-weight: 300;
        font-style: normal;
        font-display: swap;
      }

      @font-face {
        font-family: 'Brandon';
        src: url(${BrandonTextWebRegularWoff2}) format('woff2'),
             url(${BrandonTextWebRegularWoff}) format('woff');
        font-weight: 400;
        font-style: normal;
        font-display: swap;
      }

      @font-face {
        font-family: 'Brandon';
        src: url(${BrandonTextWebMediumWoff2}) format('woff2'),
             url(${BrandonTextWebMediumWoff}) format('woff');
        font-weight: 500;
        font-style: normal;
        font-display: swap;
      }
       
      @font-face {
        font-family: 'Brandon';
        src: url(${BrandonTextWebBoldWoff2}) format('woff2'),
             url(${BrandonTextWebBoldWoff}) format('woff');
        font-weight: 700;
        font-style: normal;
        font-display: swap;
      }

      @font-face {
        font-family: 'Brandon';
        src: url(${BrandonTextWebBlackWoff2}) format('woff2'),
             url(${BrandonTextWebBlackWoff}) format('woff');
        font-weight: 800;
        font-style: normal;
        font-display: swap;
      }
   `}
  />
);

export default Fonts;
