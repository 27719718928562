import { Topic } from "./Topic";

export const TIME_FILTERS: Topic[] = [
  {
    id: "0_5",
    name: "5 mins",
    short_description: "5 minute tracks",
  },
  {
    id: "5_10",
    name: "10 mins",
    short_description: "10 minute tracks",
  },
  {
    id: "10_15",
    name: "15 mins",
    short_description: "15 minute tracks",
  },
  {
    id: "15_20",
    name: "20 mins",
    short_description: "20 minute tracks",
  },
  {
    id: "20_30",
    name: "30 mins",
    short_description: "30 minute tracks",
  },
  {
    id: "30_",
    name: "30+ mins",
    short_description: "30+ minute tracks",
  },
];
