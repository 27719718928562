import { Box, Flex, Heading, Text } from "@chakra-ui/react";
import React from "react";
import { Focusable } from "react-sunbeam";
import useSWR from "swr";
import HomeHeroBackground from "../../assets/images/background-home.jpg";
import { QUOTES } from "../../assets/quotes_en";
import {
  getAllLive,
  LIVE_EVENTS_REFRESH_INTERVAL,
} from "../../common/api/LiveEvents";
import { focusManager, FocusKey } from "../../common/global/focusManager";
import { getHomeHeroImage } from "../../common/utils/helpers";
import { useFocusKey } from "../../hooks/useFocusKey";
import useScrollIntoView from "../../hooks/useScrollIntoView";
import LiveEventsCarousel from "../Carousel/LiveEventsCarousel";
import { handleKeyPress } from "../../common/utils/keyboard";

const randomQuote = QUOTES[Math.floor(Math.random() * QUOTES.length)];
const backgroundImage = getHomeHeroImage();

const HomeHeroSection = () => {
  const { scrollToTop } = useScrollIntoView();
  const { activeNavItem } = useFocusKey();

  const { data: liveEvents, isValidating } = useSWR(
    "/api/home/live",
    () => getAllLive(),
    {
      refreshInterval: LIVE_EVENTS_REFRESH_INTERVAL,
    }
  );

  function onKeyPress(event: KeyboardEvent) {
    handleKeyPress(event, {
      onLeftArrow: () =>
        focusManager.setFocus([FocusKey.Navigation, activeNavItem.focusKey]),
    });
  }

  return (
    <Box
      height="89vh"
      backgroundImage={`linear-gradient(0deg, rgba(21, 23, 23, 0.1) 0%, rgba(21, 23, 23, 0.1) 100%), linear-gradient(0deg, #151717 0%, rgba(0, 0, 0, 0) 50%), ${`url(${
        backgroundImage ?? HomeHeroBackground
      } )`}`}
      backgroundPosition="center"
      backgroundSize="cover"
    >
      <Flex height="100%" flexDirection="column">
        <Flex
          flexDir="column"
          justify="space-between"
          align="center"
          height="inherit"
        >
          <Box />

          <Flex flexDir="column" justify="center" maxW="50%" pt={40}>
            <Box />

            <Heading
              fontSize="44px"
              textAlign="center"
              maxW="28ch"
              textShadow="0px 4px 54px rgba(0, 0, 0, 0.6)"
            >
              {randomQuote.quote}
            </Heading>

            <Box height="1rem" />

            <Text
              fontSize="md"
              textAlign="center"
              color="white"
              textShadow="0px 4px 54px rgba(0, 0, 0, 0.6)"
            >
              {randomQuote.author}
            </Text>
          </Flex>

          <Box />
        </Flex>

        {process.env.REACT_APP_LIVE_EVENT_FEATURE ? (
          <LiveEventsCarousel
            focusKey="HOME-LIVE-EVENTS"
            events={liveEvents ?? []}
            title="Live Events"
            isLoading={isValidating}
            onFocus={scrollToTop}
          />
        ) : (
          <Focusable onFocus={scrollToTop} onKeyPress={onKeyPress}>
            <Box />
          </Focusable>
        )}
      </Flex>
    </Box>
  );
};

export default HomeHeroSection;
