import { Box, Heading, Text, VStack } from "@chakra-ui/react";
import React, { memo } from "react";
import { CourseDay } from "../../common/types/Item";

interface Props {
  courseDay: CourseDay;
  isActive: boolean;
}

const CourseDayTile = ({ courseDay, isActive }: Props) => {
  return (
    <VStack
      spacing={4}
      width="1200px"
      align="flex-start"
      padding="70px"
      background="#1E2121"
      borderRadius="20px"
      height="100%"
      marginX={8}
      opacity={isActive ? 1 : 0.5}
      transition="0.3s"
    >
      <Heading size="xxs" color="textGray">
        LESSON {courseDay.day}
      </Heading>

      <Heading size="md" fontWeight={700} pr="25%">
        {courseDay.question}
      </Heading>

      <Box height={1} />

      <Text color="white" pr="10%">
        {courseDay.outline.length > 1200
          ? courseDay.outline.slice(0, 1200) + "..."
          : courseDay.outline}
      </Text>
    </VStack>
  );
};

export default memo(CourseDayTile);
