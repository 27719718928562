import { Flex, Text } from "@chakra-ui/react";
import React, { memo } from "react";
import { Link } from "react-router-dom";
import { RouteList } from "../../common/routes/router";
import { Topic } from "../../common/types/Topic";
import { useCursor } from "../../hooks/useCursor";

export type TopicCardSize = "md" | "lg";

interface Props {
  isActive: boolean;
  topic: Topic;
  size?: TopicCardSize;
  isTimeFilter?: boolean;
}

const TopicTile = ({
  isActive,
  topic,
  size = "md",
  isTimeFilter = false,
}: Props) => {
  const { isCursorVisible } = useCursor();

  const isSelected = !isCursorVisible && isActive;

  const height = 12;
  const width = size === "lg" ? 28 : 21.25;

  return (
    <Link
      to={
        isTimeFilter
          ? `${RouteList.Track}/${RouteList.TimeFilter}/${topic.id}`
          : `${RouteList.Topic}/${topic.id}`
      }
    >
      <Flex
        justify="center"
        align="center"
        h={`${height}rem`}
        w={`${width}rem`}
        bg="bgGrayMedium"
        borderWidth="8px"
        borderColor={isSelected ? "white" : "bgGray"}
        borderRadius="16px"
        transition="0.3s"
        _hover={{
          borderColor: isCursorVisible || isSelected ? "white" : "bgGray",
        }}
      >
        <Text fontSize="lg" textAlign="center" fontWeight={500} color="white">
          {topic.name}
        </Text>
      </Flex>
    </Link>
  );
};

export default memo(TopicTile);
