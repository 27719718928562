import {
  Box,
  Button,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import { Focusable } from "react-sunbeam";
import { handleKeyPress } from "../../common/utils/keyboard";

const YogaDisclaimer = () => {
  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });

  function onKeyPress(event: KeyboardEvent) {
    handleKeyPress(event, {
      onEnter: () => onClose(),
    });
  }

  return (
    <Focusable onKeyPress={onKeyPress}>
      <Modal
        size="3xl"
        isOpen={isOpen}
        onClose={onClose}
        closeOnEsc={false}
        closeOnOverlayClick={false}
        isCentered
      >
        <ModalOverlay />
        <ModalContent
          borderRadius={16}
          py={8}
          px={4}
          bg="bgGray"
          borderWidth="3px"
          borderColor="bgGrayMedium"
        >
          <ModalBody>
            <Heading size="lg">Before You Start</Heading>

            <Box height={8} />

            <Text fontSize={28}>
              <strong>
                Yoga can be a strenuous physical activity with risk of injury.
              </strong>{" "}
              Insight Timer recommends that you consult with your physician
              before beginning any exercise program. Your participation in our
              live-stream yoga classes is voluntary and at your own risk.
            </Text>

            <Box height={3} />

            <Text fontSize={28}>
              By participating, you agree that you are solely responsible for
              listening to your body, participating at your own pace and
              modifying any instructions or instructor-suggested physical
              adjustments to your level of ability and physical and mental
              condition.
            </Text>

            <Box height={8} />
          </ModalBody>

          <ModalFooter>
            <Button
              onClick={onClose}
              isFullWidth
              py={8}
              size="lg"
              bg="white"
              color="black"
              borderRadius={6}
            >
              Agree &amp; Continue
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Focusable>
  );
};

export default YogaDisclaimer;
