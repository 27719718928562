interface KeyPressEvents {
  onLeftArrow?: () => void;
  onRightArrow?: () => void;
  onUpArrow?: () => void;
  onDownArrow?: () => void;
  onBackspace?: () => void;
  onEnter?: () => void;
}

export function handleKeyPress(
  event: KeyboardEvent,
  {
    onLeftArrow,
    onRightArrow,
    onUpArrow,
    onDownArrow,
    onBackspace,
    onEnter,
  }: KeyPressEvents
) {
  if (!(event instanceof KeyboardEvent)) return;
  event.preventDefault();

  if (onLeftArrow && event.key === "ArrowLeft") {
    onLeftArrow();
  }

  if (onRightArrow && event.key === "ArrowRight") {
    onRightArrow();
  }

  if (onUpArrow && event.key === "ArrowUp") {
    onUpArrow();
  }

  if (onDownArrow && event.key === "ArrowDown") {
    onDownArrow();
  }

  if (onEnter && event.key === "Enter") {
    onEnter();
  }

  if (onBackspace && event.key === "Backspace") {
    onBackspace();
  }
}
