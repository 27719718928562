import axios from "axios";
import queryString from "query-string";
import { FeaturedListEntryPoint } from "../types/FeaturedList";
import { LibraryItemSummary } from "../types/Item";
import { Topic, TopicResponse } from "../types/Topic";
import { getFeaturedList, LibraryItemAPIResponse } from "./FeaturedList";

export interface Filter {
  ids?: string;
  publisher_ids?: string[];
  ignore_langs?: boolean;
  is_religious?: boolean;
  is_spiritual?: boolean;
  has_background_music?: boolean;
  device_lang?: string;
  content_langs?: string[];
  content_types?: string[];
  topics?: string[];
  genres?: string[];
  size?: number;
  limit?: number;
  offset?: number;
  owner_ids?: string[];
  tags?: string[];
  length_option?: string;
  sort_option?:
    | "newest"
    | "popular"
    | "most_played"
    | "highest_rated"
    | "play_count_last_7_days"
    | "start_date";
  type?: "LIVE_STREAM";
  occurrence_types?: string[];
  status?: "APPROVED";
  start_date_from?: number;
  start_date_to?: number;
}

interface LibraryItemFilterResponse {
  item_summary: {
    library_item_summary: LibraryItemSummary;
  };
}

export const getLibraryItem = async (libraryItemId?: string) => {
  if (!libraryItemId) {
    return null;
  }

  const { data } = await axios.get<LibraryItemSummary>(
    `${process.env.REACT_APP_LIBRARY_ITEMS_URL}${libraryItemId}/data/libraryitem.json`
  );

  return data;
};

export const getRelatedContentForTags = async (
  type: "SINGLE" | "COURSE",
  tags: string[],
  libraryItemId: string
) => {
  const urlType = type === "SINGLE" ? "single-tracks" : "courses";
  const url = `${
    process.env.REACT_APP_FILTERING_API_URL
  }api/v1/${urlType}/filter?tags=${tags.join(",")}`;

  return axios
    .get<{ item_summary: { library_item_summary: LibraryItemSummary } }[]>(url)
    .then((response) => {
      const libraryItems = response.data
        .map((item) => item.item_summary.library_item_summary)
        .filter((item) => item.id !== libraryItemId);

      return {
        libraryItems,
      };
    })
    .catch((error) => {
      console.error(error);
      return { libraryItems: [] };
    });
};

export const getFilteredLibraryItems = async (
  filter?: Filter
): Promise<LibraryItemAPIResponse> => {
  const query = filter
    ? queryString.stringify(filter, {
        arrayFormat: "comma",
      })
    : "";
  const { data, headers } = await axios.get<LibraryItemFilterResponse[]>(
    `${process.env.REACT_APP_FILTERING_API_URL}api/v1/single-tracks/filter?${query}`
  );

  const results = data.map((item) => item.item_summary.library_item_summary);

  return {
    libraryItems: results,
    total: parseInt(headers["x-total-count"]) ?? 0,
  };
};

export const getFilteredCourses = async (
  filter?: Filter
): Promise<LibraryItemAPIResponse> => {
  const query = filter
    ? queryString.stringify(filter, {
        arrayFormat: "comma",
      })
    : "";
  const { data } = await axios.get<LibraryItemFilterResponse[]>(
    `${process.env.REACT_APP_FILTERING_API_URL}api/v1/courses/filter?${query}`
  );

  const results = data.map((item) => item.item_summary.library_item_summary);

  return {
    libraryItems: results,
    total: results.length,
  };
};

export const getMoreFromTeacher = async (
  publisherId: string,
  libraryItemId?: string
): Promise<LibraryItemAPIResponse> => {
  const libraryItems = await getFilteredLibraryItems({
    publisher_ids: [publisherId],
  });
  const courses = await getFilteredCourses({
    publisher_ids: [publisherId],
  });

  const items = [...courses.libraryItems, ...libraryItems.libraryItems].filter(
    (item) => item?.id !== libraryItemId
  );

  return {
    libraryItems: items,
    total: items.length,
  };
};

export const getCourse = async (courseId: string) => {
  const { data } = await axios.get<LibraryItemSummary>(
    `${process.env.REACT_APP_FILTERING_API_URL}api/v1/courses/${courseId}`
  );

  return data;
};

export const getPopularLibraryItemsByTags = async (tags: string) => {
  const libraryItems = await getFilteredLibraryItems({
    tags: [tags],
    size: 15,
    sort_option: "most_played",
  });

  return libraryItems;
};

export const getPopularCoursesByTags = async (
  tags: string
): Promise<LibraryItemAPIResponse> => {
  const items = await getFilteredCourses({
    tags: [tags],
    size: 15,
    sort_option: "popular",
  });

  return {
    title: "Popular Courses",
    libraryItems: items.libraryItems,
    total: items.libraryItems.length,
  };
};

export const getFilteredTopics = async (filter?: Filter) => {
  const query = filter
    ? queryString.stringify(filter, {
        arrayFormat: "comma",
      })
    : "";
  const { data } = await axios.get<TopicResponse[]>(
    `${process.env.REACT_APP_FILTERING_API_URL}api/v1/topics/filter?${query}`
  );

  const results = data[0].topic_groups[0].topics;
  return results;
};

interface SearchParams {
  query: string;
  offset: number;
  limit: number;
  device_lang: string;
  publishers_only_users?: boolean;
  search_friends_of_friends?: boolean;
  include_workshops?: boolean;
}

export const searchLibraryItem = async (filter: SearchParams) => {
  const query = queryString.stringify(filter);
  const { data } = await axios.get<LibraryItemFilterResponse[]>(
    `${process.env.REACT_APP_FILTERING_API_URL}api/v1/top?${query}`
  );

  const results = data.map((item) => item.item_summary.library_item_summary);

  return results;
};

interface FeaturedListFilter {
  offset: number;
  limit: number;
  device_lang: string;
  content_langs: string[];
}

export const getFeaturedLibraryItemByTopic = async (
  topic: Topic,
  filter: FeaturedListFilter
): Promise<LibraryItemAPIResponse> => {
  if (topic.id === "anxiety") {
    return await getFeaturedList(FeaturedListEntryPoint.Anxiety);
  }

  const query = filter
    ? queryString.stringify(filter, {
        arrayFormat: "comma",
      })
    : "";

  const { data } = await axios.get<LibraryItemFilterResponse[]>(
    `${process.env.REACT_APP_FILTERING_API_URL}api/v1/tags/${topic.id}/featured?${query}`
  );

  const items = data.map((item) => item.item_summary.library_item_summary);

  return {
    libraryItems: items,
    total: items.length,
  };
};
