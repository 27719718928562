import { Box } from "@chakra-ui/react";
import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Focusable } from "react-sunbeam";
import { SwiperSlide } from "swiper/react/swiper-react";
import { RouteList } from "../../common/routes/router";
import { Topic } from "../../common/types/Topic";
import { TrackingEventName } from "../../common/types/Tracking";
import { useAnalytics } from "../../hooks/useAnalytics";
import useScrollIntoView from "../../hooks/useScrollIntoView";
import TopicTile, { TopicCardSize } from "../Tiles/TopicTile";
import Carousel from "./Carousel";
import CarouselHeading from "./CarouselHeading";

interface Props {
  title: string;
  topics: Topic[];
  isTimeFilter?: boolean;
  size?: TopicCardSize;
}

const TopicCarousel: React.FC<Props> = ({
  title,
  topics,
  isTimeFilter,
  size,
}) => {
  const navigate = useNavigate();
  const carouselRef = useRef(null);
  const { scrollIntoView } = useScrollIntoView(carouselRef);
  const { trackClick } = useAnalytics();

  function onEnterItem(index: number) {
    if (topics) {
      const topicId = topics[index].id;

      if (isTimeFilter) {
        trackClick({
          click_event_name: TrackingEventName.timeTileClicked,
          time_id: topicId,
        });
        navigate(`${RouteList.Track}/${RouteList.TimeFilter}/${topicId}`);
      } else {
        trackClick({
          click_event_name: TrackingEventName.topicTileClicked,
          topic_id: topicId,
        });
        navigate(`${RouteList.Topic}/${topicId}`);
      }
    }
  }

  return (
    <Focusable onFocus={scrollIntoView}>
      {({ focused }) => {
        return (
          <Box ref={carouselRef}>
            <CarouselHeading content={title} />

            <Carousel
              isFocused={focused}
              numberOfSlides={topics.length}
              onEnterItem={onEnterItem}
            >
              {topics.map((topic) => {
                return (
                  <SwiperSlide key={topic.id} style={{ width: "auto" }}>
                    {({ isActive }) => {
                      return (
                        <TopicTile
                          isActive={isActive && focused}
                          topic={topic}
                          size={size}
                          isTimeFilter={isTimeFilter}
                        />
                      );
                    }}
                  </SwiperSlide>
                );
              })}
            </Carousel>
          </Box>
        );
      }}
    </Focusable>
  );
};

export default TopicCarousel;
