import React, { memo } from "react";
import {
  Route,
  Routes as ReactRouterRoutes,
  useNavigate,
} from "react-router-dom";
import { useFocusKey } from "../../hooks/useFocusKey";
import useKeyboardNavigation from "../../hooks/useKeyboardNavigation";
import { useScrollWheelNavigation } from "../../hooks/useScrollWheelNavigation";
import CourseOverview from "../../pages/CourseOverview";
import Courses from "../../pages/Courses";
import GeneralTopic from "../../pages/GeneralTopic";
import Home from "../../pages/Home";
import LiveEventEntry from "../../pages/LiveEventEntry";
import LiveEvents from "../../pages/LiveEvents";
import Music from "../../pages/Music";
import Parents from "../../pages/Parents";
import Player from "../../pages/Player";
import Search from "../../pages/Search";
import Sleep from "../../pages/Sleep";
import TimeFilter from "../../pages/TimeFilter";
import TrackOverview from "../../pages/TrackOverview";
import { FocusKey, focusManager } from "../global/focusManager";
import { RouteList } from "./router";

export interface RouteProps {
  id?: string;
}

interface Props {
  isFocused: boolean;
}

const Routes = ({ isFocused }: Props) => {
  const navigate = useNavigate();
  const { lastRootFocusKey, modalIsOpen } = useFocusKey();

  useScrollWheelNavigation();

  useKeyboardNavigation(isFocused && !modalIsOpen, {
    onUpArrow: () => focusManager.moveUp(),
    onDownArrow: () => focusManager.moveDown(),
    onBackspace: () => {
      navigate(-1);

      setTimeout(() => {
        return focusManager.setFocus([FocusKey.Root, lastRootFocusKey]);
      }, 500);
    },
  });

  return (
    <ReactRouterRoutes>
      <Route index element={<Home />} />
      <Route path={RouteList.Search} element={<Search />} />
      <Route path={RouteList.Live} element={<LiveEvents />} />
      <Route path={`${RouteList.Live}/:id/play`} element={<LiveEventEntry />} />
      <Route path={RouteList.Courses} element={<Courses />} />
      <Route
        path={`${RouteList.CourseOverview}/:id`}
        element={<CourseOverview />}
      />
      <Route path={RouteList.Sleep} element={<Sleep />} />
      <Route path={RouteList.Music} element={<Music />} />
      <Route path={RouteList.Parents} element={<Parents />} />
      <Route path={`${RouteList.Topic}/:id`} element={<GeneralTopic />} />
      <Route path={`${RouteList.Track}/:id`} element={<TrackOverview />} />
      <Route path={`${RouteList.Track}/:id/play`} element={<Player />} />
      <Route
        path={`${RouteList.Track}/${RouteList.TimeFilter}/:id`}
        element={<TimeFilter />}
      />
    </ReactRouterRoutes>
  );
};

export default memo(Routes);
