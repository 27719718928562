import { Box } from "@chakra-ui/react";
import React, { Dispatch, memo, SetStateAction, useState } from "react";
import { Focusable } from "react-sunbeam";
import Swiper from "swiper";
import { SwiperSlide } from "swiper/react/swiper-react";
import { POPULAR_TOPICS, Topic } from "../../common/types/Topic";
import useScrollIntoView from "../../hooks/useScrollIntoView";
import Carousel from "./Carousel";
import TabItem from "./TabItem";

export const allTopic: Topic = {
  id: "all",
  name: "All",
  short_description: "",
};

interface Props {
  setActiveTopic: Dispatch<SetStateAction<Topic>>;
}

const TimeFilterTopicCarousel = ({ setActiveTopic }: Props) => {
  const [swiper, setSwiper] = useState<Swiper>();
  const { scrollToTop } = useScrollIntoView();
  const topics: Topic[] = [allTopic, ...POPULAR_TOPICS];

  return (
    <Focusable onFocus={scrollToTop}>
      {({ focused }) => {
        return (
          <Box ml="10px" mb="2.5rem">
            <Carousel
              isFocused={focused}
              numberOfSlides={topics.length}
              spaceBetween={70}
              onGetSwiper={(swiperObj) => {
                setSwiper(swiperObj);
              }}
            >
              {topics.map((topic, index) => {
                return (
                  <SwiperSlide key={topic.id} style={{ width: "auto" }}>
                    {({ isActive }) => {
                      return (
                        <TabItem
                          topic={topic}
                          isActive={isActive}
                          onActive={setActiveTopic}
                          isLast={index === topics.length - 1}
                          cursor="pointer"
                          onClick={() => {
                            swiper?.slideTo(index);
                          }}
                        />
                      );
                    }}
                  </SwiperSlide>
                );
              })}
            </Carousel>

            <Box ml="215px" mt="-3px" height="3px" bg="bgGrayLight" />
          </Box>
        );
      }}
    </Focusable>
  );
};

export default memo(TimeFilterTopicCarousel);
