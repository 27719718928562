import { Box, Heading, Spinner, Stack } from "@chakra-ui/react";
import React from "react";

const LiveEventVideoLoading = () => {
  return (
    <Stack width="100vw" height="100vh" justify="center" align="center">
      <Heading>Good things take time</Heading>
      <Heading size="sm">Live event starting soon</Heading>
      <Box height={4} />
      <Spinner size="lg" />
    </Stack>
  );
};

export default LiveEventVideoLoading;
