import axios from "axios";
import compact from "lodash/compact";
import { FeaturedItem, FeaturedListEntryPoint } from "../types/FeaturedList";
import { LibraryItemSummary } from "../types/Item";

const API_URL = `https://featured-lists.insighttimer-api.net`;

export interface FeaturedListAPIResponse {
  items: FeaturedItem[];
  lang: {
    iso_639_1: string;
    name: string;
  };
  name: string;
  ui_entry_point: FeaturedListEntryPoint;
}

export interface LibraryItemAPIResponse {
  libraryItems: LibraryItemSummary[];
  title?: string;
  total?: number;
}

export const getFeaturedList = async (
  entryPoint: FeaturedListEntryPoint
): Promise<LibraryItemAPIResponse> => {
  const { data } = await axios.get<FeaturedListAPIResponse>(
    `${API_URL}/${entryPoint}/data/en/featured_list.json`
  );

  const items = compact(
    data.items
      .filter((item) => item.item_summary.type !== "PLAYLISTS")
      .map((item) => item.item_summary.library_item_summary)
  );

  return {
    title: data.name,
    libraryItems: items,
    total: items.length,
  };
};
