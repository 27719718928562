import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
  components: {
    Heading: {
      sizes: {
        xxl: {
          fontWeight: 700,
          fontSize: 70,
          lineHeight: "80px",
          textTransform: "uppercase",
        },
        xl: {
          fontWeight: 700,
          fontSize: 60,
          textTransform: "uppercase",
        },
        lg: {
          fontWeight: 700,
          fontSize: 54,
        },
        md: {
          fontWeight: 500,
          fontSize: 36,
        },
        sm: {
          fontWeight: 500,
          fontSize: 28,
          lineHeight: "38px",
        },
        xs: {
          fontWeight: 500,
          fontSize: 24,
        },
        xxs: {
          fontWeight: 600,
          fontSize: 20,
        },
      },
      defaultProps: {
        size: "md",
      },
    },
    Text: {
      baseStyle: {
        fontWeight: 400,
        fontSize: "md",
        color: "textGray",
      },
    },
    Button: {
      baseStyle: {
        fontWeight: 500,
      },
    },
  },
  styles: {
    global: {
      body: {
        bg: "bgGray",
        color: "white",
        fontSize: "28px",
      },
    },
  },
  fonts: {
    heading: "Brandon",
    body: "Brandon",
  },
  fontSizes: {
    xs: "17px",
    sm: "22px",
    md: "24px",
    slg: "28px",
    lg: "32px",
    xl: "44px",
    "2xl": "70px",
  },
  colors: {
    bgGray: "#151717",
    bgGrayDark: "#1F2121",
    bgGrayMedium: "#272B2B",
    bgGrayLight: "#656565",
    altGray: "#D5D8D8",
    textGray: "#D0D1D1",
    textGrayDark: "#636D6D",
    textGrayMedium: "#899090",
    courseYellow: "#E1B033",
    livePink: "#BD3E6F",
    livePinkLight: "#F2D8E2",
    buttonUnfocused: "#464747",
  },
});

export default theme;
