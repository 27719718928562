import { Box, Flex, Image, Text } from "@chakra-ui/react";
import React, { memo } from "react";
import QRBackground from "../../assets/images/qr-background.png";
import MemberPlusImage from "../../assets/member-plus.svg";
import { BranchCampaign } from "../../common/utils/branch";
import PartnershipLogo from "../CourseQRModal/PartnershipLogo";
import MemberPlusQRCode from "./MemberPlusQRCode";

interface Props {
  campaign: BranchCampaign;
}

const MemberPlusAdContent = ({ campaign }: Props) => {
  return (
    <Flex
      flexDir="column"
      align="center"
      paddingY="85px"
      borderRadius="40px"
      transition="0.3s"
    >
      <Image src={MemberPlusImage} />

      <Text fontSize="2xl" fontWeight="bold" color="white">
        Exclusive offer for LG customers
      </Text>

      <Box maxW="60%" mb="35px">
        <Text fontSize="lg" fontWeight="normal" textAlign="center">
          Get <strong style={{ color: "white" }}>1 year free</strong> unlock
          hundreds of multi-day courses from the world’s best teachers.
        </Text>
      </Box>

      <Box position="relative" width="100%" mb="30px">
        <Box
          position="absolute"
          width="100%"
          top="50%"
          transform="translateY(-50%)"
        >
          <Box
            width="100%"
            height="100%"
            position="absolute"
            background="bgGrayDark"
            opacity="0.55"
          />
          <Box
            background="linear-gradient(90deg, #1E2121 0%, bgGrayMedium 0.01%, rgba(30, 33, 33, 0) 100%)"
            width="200px"
            height="300px"
            position="absolute"
            top="0"
            left="0"
          />
          <Box
            background="linear-gradient(270deg, #1E2121 0%, bgGrayMedium 0.01%, rgba(30, 33, 33, 0) 100%)"
            width="200px"
            height="300px"
            position="absolute"
            top="0"
            right="0"
          />
          <Image src={QRBackground} width="100%" />
        </Box>

        <Flex alignItems="center" justifyContent="center">
          <Flex
            borderWidth="12px"
            borderColor="courseYellow"
            borderRadius="40px"
            position="relative"
            transition="0.3s"
            width="400px"
            height="400px"
            background="white"
            align="center"
            justify="center"
          >
            <MemberPlusQRCode campaign={campaign} />
          </Flex>
        </Flex>
      </Box>
      <PartnershipLogo />
    </Flex>
  );
};

export default memo(MemberPlusAdContent);
