import {
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Text,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { formatDuration } from "../../common/utils/helpers";

interface Props {
  libraryItemMediaLength?: number;
  isShowControl: boolean;
  isSeeking: boolean;
  playerRef: React.RefObject<ReactPlayer>;
}

const LibraryItemPlayerControl = ({
  libraryItemMediaLength,
  isShowControl,
  isSeeking,
  playerRef,
}: Props) => {
  const [duration, setDuration] = useState(0);

  useEffect(() => {
    let intervalId: number | null = null;

    if (isShowControl) {
      intervalId = window.setInterval(() => {
        setDuration(Math.floor(playerRef.current?.getCurrentTime() ?? 0));
      }, 1000);
    } else {
      if (intervalId) window.clearInterval(intervalId);
    }

    return () => {
      if (intervalId) {
        window.clearInterval(intervalId);
      }
    };
  }, [isShowControl, playerRef]);

  function handleOnChange(newDuration: number) {
    playerRef.current?.seekTo(newDuration);
    setDuration(newDuration);
  }

  return (
    <Slider
      value={duration}
      min={0}
      max={libraryItemMediaLength}
      step={1}
      focusThumbOnChange={false}
      marginBottom={12}
      onChange={handleOnChange}
    >
      <SliderTrack bg="rgba(255,255,255,0.25)" h="8px">
        <SliderFilledTrack bg="white" />
      </SliderTrack>

      <SliderThumb boxSize={6}>
        <Text
          opacity={isSeeking ? 1 : 0}
          mt={16}
          color="white"
          fontWeight={500}
          fontSize="sm"
          transition="opacity 0.3s"
        >
          {libraryItemMediaLength &&
            formatDuration(dayjs.duration(duration, "seconds"))}
        </Text>
      </SliderThumb>
    </Slider>
  );
};

export default LibraryItemPlayerControl;
