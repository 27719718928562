import { Flex, Heading, VStack } from "@chakra-ui/react";
import React, { memo, useState } from "react";
import { Focusable } from "react-sunbeam";
import { FocusKey, focusManager } from "../../common/global/focusManager";
import { useFocusKey } from "../../hooks/useFocusKey";
import useKeyboardNavigation from "../../hooks/useKeyboardNavigation";
import useScrollIntoView from "../../hooks/useScrollIntoView";

interface Props {
  title: string;
  description: string;
  bgImage: string;
}

const TopicHeroSection: React.FC<Props> = ({ title, description, bgImage }) => {
  const { scrollToTop } = useScrollIntoView();
  const [isFocused, setIsFocused] = useState(false);
  const { activeNavItem } = useFocusKey();

  useKeyboardNavigation(isFocused, {
    onLeftArrow: () =>
      focusManager.setFocus([FocusKey.Navigation, activeNavItem.focusKey]),
  });

  function handleOnFocus() {
    setIsFocused(true);
    scrollToTop();
  }

  function handleOnBlur() {
    setIsFocused(false);
  }

  return (
    <Focusable onBlur={handleOnBlur} onFocus={handleOnFocus}>
      <Flex
        width="100%"
        height="80vh"
        justify="center"
        align="center"
        backgroundImage={`linear-gradient(0deg, rgba(21, 23, 23, 1) 0%, rgba(0, 0, 0, 0.7) 15%, rgba(0, 0, 0, 0) 80%), url(${bgImage})`}
        backgroundSize="cover"
        backgroundPosition="center"
      >
        <VStack spacing={4} mt={16}>
          <Heading size="lg" textAlign="center">
            {title}
          </Heading>

          <Heading size="sm" textAlign="center" maxW="30ch" fontWeight={400}>
            {description}
          </Heading>
        </VStack>
      </Flex>
    </Focusable>
  );
};

export default memo(TopicHeroSection);
