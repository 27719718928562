import { Box } from "@chakra-ui/react";
import React from "react";
import ReactPlayer from "react-player/lazy";
import { LibraryItemSummary } from "../../common/types/Item";

interface Props {
  libraryItem: LibraryItemSummary;
  isPlaying: boolean;
  playerRef: React.RefObject<ReactPlayer>;
  onEnded: () => void;
  onPause: () => void;
  onPlay: () => void;
}

const LibraryItemPlayer = ({
  libraryItem,
  isPlaying,
  playerRef,
  onEnded,
  onPause,
  onPlay,
}: Props) => {
  return (
    <Box position="absolute" pointerEvents="none">
      <ReactPlayer
        ref={playerRef}
        url={libraryItem.media_paths?.length ? libraryItem.media_paths[1] : ""}
        playing={isPlaying}
        onEnded={onEnded}
        onPause={onPause}
        onPlay={onPlay}
      />
    </Box>
  );
};

export default LibraryItemPlayer;
