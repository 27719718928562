import React from "react";
import { useSearchParams } from "react-router-dom";
import { Focusable } from "react-sunbeam";
import { FocusKey } from "./common/global/focusManager";
import Routes from "./common/routes/Routes";
import SidebarNav from "./components/SidebarNav/SidebarNav";
import { HIDE_SIDEBAR_KEY } from "./hooks/useHideSidebarNav";

const InsightTV = () => {
  const [searchParams] = useSearchParams();
  const showSidebar = searchParams.get(HIDE_SIDEBAR_KEY) !== "true";

  return (
    <>
      {showSidebar && (
        <Focusable focusKey={FocusKey.Navigation}>
          {({ focused }) => {
            return <SidebarNav isFocused={focused} />;
          }}
        </Focusable>
      )}

      <Focusable focusKey={FocusKey.Root}>
        {({ focused }) => {
          return <Routes isFocused={focused} />;
        }}
      </Focusable>
    </>
  );
};

export default InsightTV;
