import { Box } from "@chakra-ui/react";
import React from "react";
import MusicBackgroundImage from "../assets/images/background-music.jpg";
import { getFeaturedList } from "../common/api/FeaturedList";
import {
  getFilteredLibraryItems,
  getPopularCoursesByTags,
  getPopularLibraryItemsByTags,
} from "../common/api/LibraryItems";
import { FeaturedListEntryPoint } from "../common/types/FeaturedList";
import { MUSIC_SUBTOPICS } from "../common/types/Topic";
import { BranchCampaign } from "../common/utils/branch";
import LazyLibraryItemCarousel from "../components/Carousel/LazyLibraryItemCarousel";
import MemberPlusAd from "../components/MemberPlusAd/MemberPlusAd";
import SubTopicSection from "../components/Section/SubTopicSection";
import TopicHeroSection from "../components/Section/TopicHeroSection";

const Music = () => {
  function getFeatured() {
    return getFeaturedList(FeaturedListEntryPoint.Music);
  }

  function getPopular() {
    return getPopularLibraryItemsByTags("music");
  }

  function getCourses() {
    return getPopularCoursesByTags("music");
  }

  function getNewAndTrending() {
    return getFilteredLibraryItems({
      topics: ["music"],
      content_types: ["music"],
      sort_option: "newest",
    });
  }

  return (
    <Box>
      <TopicHeroSection
        title="Music"
        description="Thousands of free music tracks and stories to help you get a better nights sleep"
        bgImage={MusicBackgroundImage}
      />

      <LazyLibraryItemCarousel
        focusKey="MUSIC-FEATURED"
        title="Featured"
        swrKey="api/music/featured"
        fetcherFn={getFeatured}
      />

      <LazyLibraryItemCarousel
        focusKey="MUSIC-POPULAR"
        title="Popular"
        swrKey="api/music/popular"
        fetcherFn={getPopular}
      />

      <LazyLibraryItemCarousel
        focusKey="MUSIC-COURSES"
        title="Courses"
        swrKey="api/music/courses"
        fetcherFn={getCourses}
      />

      <Box height="50px" />

      <MemberPlusAd campaign={BranchCampaign.music} />

      {MUSIC_SUBTOPICS.map((subTopic) => {
        return <SubTopicSection key={subTopic.id} subTopic={subTopic} />;
      })}

      <LazyLibraryItemCarousel
        focusKey="MUSIC-NEW-AND-TRENDING"
        title="New and Trending"
        swrKey="api/music/newAndTrending"
        fetcherFn={getNewAndTrending}
      />
    </Box>
  );
};

export default Music;
