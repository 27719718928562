import { VStack } from "@chakra-ui/react";
import React, { memo, useEffect, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import CoursesIcon from "../../assets/images/courses_icon.svg";
import HomeIcon from "../../assets/images/home_icon.svg";
import LiveIcon from "../../assets/images/live_icon.svg";
import MusicIcon from "../../assets/images/music_icon.svg";
import ParentsIcon from "../../assets/images/parents_icon.svg";
import SearchIcon from "../../assets/images/search_icon.svg";
import SleepIcon from "../../assets/images/sleep_icon.svg";
import { FocusKey, focusManager } from "../../common/global/focusManager";
import { RouteList } from "../../common/routes/router";
import { TrackingEventName } from "../../common/types/Tracking";
import { useAnalytics } from "../../hooks/useAnalytics";
import { useFocusKey } from "../../hooks/useFocusKey";
import useKeyboardNavigation from "../../hooks/useKeyboardNavigation";
import SidebarNavMenuItem from "./SidebarNavMenuItem";

export interface NavigationItem {
  title: string;
  focusKey: FocusKey;
  route: RouteList;
  icon: string;
  trackingEvent: TrackingEventName;
}

export const navCategories: NavigationItem[] = [
  {
    title: "Search",
    focusKey: FocusKey.NavigationSearch,
    route: RouteList.Search,
    icon: SearchIcon,
    trackingEvent: TrackingEventName.searchMenuClicked,
  },
  {
    title: "Home",
    focusKey: FocusKey.NavigationHome,
    route: RouteList.Home,
    icon: HomeIcon,
    trackingEvent: TrackingEventName.homeMenuClicked,
  },
  ...(process.env.REACT_APP_LIVE_EVENT_FEATURE
    ? [
        {
          title: "Live Events",
          focusKey: FocusKey.NavigationLive,
          route: RouteList.Live,
          icon: LiveIcon,
          trackingEvent: TrackingEventName.liveMenuClicked,
        },
      ]
    : []),
  {
    title: "Courses",
    focusKey: FocusKey.NavigationCourses,
    route: RouteList.Courses,
    icon: CoursesIcon,
    trackingEvent: TrackingEventName.coursesMenuClicked,
  },
  {
    title: "Sleep",
    focusKey: FocusKey.NavigationSleep,
    route: RouteList.Sleep,
    icon: SleepIcon,
    trackingEvent: TrackingEventName.sleepMenuClicked,
  },
  {
    title: "Music",
    focusKey: FocusKey.NavigationMusic,
    route: RouteList.Music,
    icon: MusicIcon,
    trackingEvent: TrackingEventName.musicMenuClicked,
  },
  {
    title: "Parents",
    focusKey: FocusKey.NavigationParents,
    route: RouteList.Parents,
    icon: ParentsIcon,
    trackingEvent: TrackingEventName.parentsMenuClicked,
  },
];

interface Props {
  isFocused: boolean;
  isCollapsed: boolean;
  onRouteChange: () => void;
}

const SidebarNavItems = ({ isFocused, isCollapsed, onRouteChange }: Props) => {
  const navbarIndex = useRef(0);
  const navigate = useNavigate();
  const { trackClick } = useAnalytics();
  const location = useLocation();
  const { setActiveNavItem } = useFocusKey();

  useKeyboardNavigation(isFocused, {
    onEnter: () => {
      const navItem = navCategories[navbarIndex.current];
      onRouteChange();
      trackClick({ click_event_name: navItem.trackingEvent });
      return navigate(navItem.route);
    },
    onRightArrow: () => {
      return focusManager.setFocus([FocusKey.Root]);
    },
    onUpArrow: () => {
      if (navbarIndex.current === 0) return;
      return focusManager.moveUp();
    },
    onDownArrow: () => {
      if (navbarIndex.current === navCategories.length - 1) return;
      return focusManager.moveDown();
    },
  });

  useEffect(() => {
    const currentPath = location.pathname.split("/")[1];
    const navItem = navCategories.find((nav) => nav.route === currentPath);
    if (navItem) setActiveNavItem(navItem);
  }, [location.pathname, setActiveNavItem]);

  function handleSetIndex(index: number) {
    navbarIndex.current = index;
  }

  return (
    <VStack align="flex-start" spacing={4}>
      {navCategories.map((navItem, index) => {
        return (
          <Link key={navItem.focusKey} to={navItem.route}>
            <SidebarNavMenuItem
              index={index}
              navItem={navItem}
              navbarIsOpen={isCollapsed}
              onFocus={handleSetIndex}
            />
          </Link>
        );
      })}
    </VStack>
  );
};

export default memo(SidebarNavItems);
