import { Flex, Image } from "@chakra-ui/react";
import React from "react";
import PauseIconImage from "../../assets/icons/pause_icon.svg";

interface Props {
  isPlaying: boolean;
}

const PauseIcon = ({ isPlaying }: Props) => {
  if (isPlaying) {
    return null;
  }

  return (
    <Flex
      overflow="hidden"
      pos="absolute"
      align="center"
      w="100%"
      h="100%"
      pb={20}
      justify="center"
    >
      <Image
        src={PauseIconImage}
        filter="drop-shadow(0 1px 5px rgba(0, 0, 0, 0.5))"
      />
    </Flex>
  );
};

export default PauseIcon;
