import { Box, Flex } from "@chakra-ui/react";
import React, { memo } from "react";
import { Link } from "react-router-dom";
import { RouteList } from "../../common/routes/router";
import { LibraryItemSummary } from "../../common/types/Item";
import { getLibraryItemImageUrl } from "../../common/utils/helpers";
import { useCursor } from "../../hooks/useCursor";
import LibraryItemTileContent from "./LibraryItemTileContent";

export type LibraryItemCardSize = "md" | "lg";

export const ITEM_TILE_HEIGHT_MD = "430px" as const;
export const ITEM_TILE_WIDTH_MD = "342px" as const;
export const ITEM_TILE_HEIGHT_LG = "592px" as const;
export const ITEM_TILE_WIDTH_LG = "424px" as const;

interface Props {
  libraryItem: LibraryItemSummary;
  size?: LibraryItemCardSize;
  isActive?: boolean;
}

const LibraryItemTile: React.FC<Props> = ({
  libraryItem,
  isActive,
  size = "md",
}) => {
  const { isCursorVisible } = useCursor();

  const isSelected = !isCursorVisible && isActive;

  const libraryItemImageUrl = getLibraryItemImageUrl(libraryItem.id);

  return (
    <Link
      to={
        libraryItem.item_type === "COURSES"
          ? `/${RouteList.CourseOverview}/${libraryItem.id}`
          : `/${RouteList.Track}/${libraryItem.id}`
      }
    >
      <Box
        pos="relative"
        h={size === "lg" ? ITEM_TILE_HEIGHT_LG : ITEM_TILE_HEIGHT_MD}
        w={size === "lg" ? ITEM_TILE_WIDTH_LG : ITEM_TILE_WIDTH_MD}
        role="group"
      >
        <Flex
          flexDir="column"
          justify="flex-end"
          align="flex-start"
          h="inherit"
          w="inherit"
          px={4}
          py={6}
          borderWidth="8px"
          borderColor={isSelected ? "white" : "bgGray"}
          backgroundImage={libraryItemImageUrl}
          backgroundPosition="center"
          backgroundSize="cover"
          borderRadius={16}
          transition="0.3s"
          _groupHover={{
            borderColor: isCursorVisible || isSelected ? "white" : "bgGray",
          }}
        >
          <LibraryItemTileContent libraryItem={libraryItem} />
        </Flex>

        <Box
          pos="absolute"
          bottom={0}
          h="inherit"
          w="inherit"
          bg="linear-gradient(194deg, rgba(21, 23, 23, 0) 44%, rgba(21, 23, 23, 0.9) 76.88%, #151717 105%)"
          borderRadius={16}
          borderWidth="8px"
          borderColor={isSelected ? "white" : "bgGray"}
          transition="0.3s"
          zIndex={10}
          _groupHover={{
            borderColor: isCursorVisible || isSelected ? "white" : "bgGray",
          }}
        />
      </Box>
    </Link>
  );
};

export default memo(LibraryItemTile);
