import { Box, Flex, Heading, HStack, Text, VStack } from "@chakra-ui/react";
import React from "react";
import { NUM_LIVE_HERO_EVENTS } from "../../common/api/LiveEvents";
import { EventSummary } from "../../common/types/Event";
import {
  getPublisherImageUrl,
  SIDEBAR_WIDTH_PX,
} from "../../common/utils/helpers";
import HeroBackgroundImage from "../Background/HeroBackgroundImage";
import LiveEventBadge from "../Badge/LiveEventBadge";

interface Props {
  event: EventSummary;
  heroButton: React.ReactNode;
  currentSlideIndex?: number;
  showDescription?: boolean;
  descriptionNoOfLines?: number;
}

const LiveEventHeroSlide = ({
  event,
  heroButton,
  currentSlideIndex,
  showDescription = true,
  descriptionNoOfLines = 3,
}: Props) => {
  const titleIsLong = event.title.length > 35;

  return (
    <Flex align="center" h="85vh">
      <HeroBackgroundImage
        imageUrl={getPublisherImageUrl(event.owner.id, "large")}
      />

      <Flex ml={SIDEBAR_WIDTH_PX}>
        <VStack align="flex-start" spacing="22px" w="50%" zIndex={5} pl={4}>
          <VStack align="flex-start">
            <LiveEventBadge event={event} />
            <Heading
              size={titleIsLong ? "lg" : "xxl"}
              noOfLines={3}
              textTransform="uppercase"
            >
              {event.title}
            </Heading>
          </VStack>

          <Heading size="sm">with {event.owner.name}</Heading>

          {showDescription && (
            <Text w="70%" fontSize="md" noOfLines={descriptionNoOfLines}>
              {event.description}
            </Text>
          )}

          <Box />

          {heroButton}

          {currentSlideIndex != null && (
            <HStack pos="absolute" bottom="50px">
              {[...Array(NUM_LIVE_HERO_EVENTS)].map((_, index) => (
                <Box
                  key={index}
                  w="96px"
                  h="7px"
                  borderRadius={12}
                  bg="white"
                  opacity={currentSlideIndex === index ? 1 : 0.16}
                />
              ))}
            </HStack>
          )}
        </VStack>
      </Flex>
    </Flex>
  );
};

export default LiveEventHeroSlide;
