import { Box, Flex, Heading, Text } from "@chakra-ui/react";
import React from "react";
import { LibraryItemSummary } from "../../common/types/Item";
import { getPublisherImageUrl } from "../../common/utils/helpers";
import CourseDayCountBadge from "../Badge/CourseDayCountBadge";

interface Props {
  course: LibraryItemSummary;
}

const CourseQRModalImage = ({ course }: Props) => {
  const publisherImageUrl = getPublisherImageUrl(course.publisher.id, "medium");

  return (
    <Box
      height="86%"
      border="solid 2px #414B5A"
      borderRadius="60px 60px 0 0"
      borderBottom="none"
      padding="15px 15px 0 15px"
    >
      <Box
        height="100%"
        border="solid 2px #414B5A"
        borderRadius="40px 40px 0 0"
        borderBottom="none"
        overflow="hidden"
      >
        <Flex
          align="flex-end"
          p={4}
          h="65%"
          w="100%"
          minW="425px"
          paddingTop="100%"
          backgroundImage={publisherImageUrl}
          backgroundSize="cover"
          backgroundPosition="center"
        >
          {course.days && <CourseDayCountBadge days={course.days} />}
        </Flex>

        <Box padding="30px">
          <Heading mb="10px">{course.title}</Heading>
          <Text>by {course.publisher.name}</Text>
        </Box>
      </Box>
    </Box>
  );
};

export default CourseQRModalImage;
