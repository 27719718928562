import { Box } from "@chakra-ui/react";
import React from "react";
import { useParams } from "react-router-dom";
import { Focusable } from "react-sunbeam";
import {
  getFeaturedLibraryItemByTopic,
  getFilteredCourses,
  getFilteredLibraryItems,
} from "../common/api/LibraryItems";
import { FocusKey, focusManager } from "../common/global/focusManager";
import { POPULAR_TOPICS, Topic } from "../common/types/Topic";
import { BranchCampaign } from "../common/utils/branch";
import { handleKeyPress } from "../common/utils/keyboard";
import LazyLibraryItemCarousel from "../components/Carousel/LazyLibraryItemCarousel";
import MemberPlusAd from "../components/MemberPlusAd/MemberPlusAd";
import GeneralTopicHeroSection from "../components/Section/GeneralTopicHeroSection";
import { useFocusKey } from "../hooks/useFocusKey";
import useScrollIntoView from "../hooks/useScrollIntoView";

const GeneralTopic = () => {
  const params = useParams();
  const topicId = params.id;
  const topic = POPULAR_TOPICS.find((t) => t.id === topicId);

  if (!topic) return null;

  return <GeneralTopicContent topic={topic} />;
};

interface GeneralTopicContentProps {
  topic: Topic;
}

const GeneralTopicContent = ({ topic }: GeneralTopicContentProps) => {
  const { scrollToTop } = useScrollIntoView();
  const { activeNavItem } = useFocusKey();

  function getFeatured() {
    return getFeaturedLibraryItemByTopic(topic, {
      offset: 0,
      limit: 20,
      device_lang: "en",
      content_langs: ["en"],
    });
  }

  function getPopular() {
    return getFilteredLibraryItems({
      topics: [topic.id],
      limit: 20,
      sort_option: "play_count_last_7_days",
    });
  }

  function getCourses() {
    return getFilteredCourses({
      topics: [topic.id],
      limit: 20,
      sort_option: "popular",
    });
  }

  function onKeyPress(event: KeyboardEvent) {
    handleKeyPress(event, {
      onLeftArrow: () =>
        focusManager.setFocus([FocusKey.Navigation, activeNavItem.focusKey]),
    });
  }

  return (
    <Box>
      <Focusable onFocus={scrollToTop} onKeyPress={onKeyPress}>
        <GeneralTopicHeroSection
          heading={topic.name}
          subHeading={topic.short_description}
        />
      </Focusable>

      <Box marginTop="-8rem">
        <LazyLibraryItemCarousel
          focusKey={`TOPIC-FEATURED-${topic.id}`}
          title="Featured"
          swrKey={`api/${topic.id}/featured`}
          fetcherFn={getFeatured}
        />

        <LazyLibraryItemCarousel
          focusKey={`TOPIC-POPULAR-${topic.id}`}
          title="Popular"
          swrKey={`api/${topic.id}/popular`}
          fetcherFn={getPopular}
        />

        <LazyLibraryItemCarousel
          focusKey={`TOPIC-COURSES-${topic.id}`}
          title="Courses"
          swrKey={`api/${topic.id}/courses`}
          fetcherFn={getCourses}
        />

        <MemberPlusAd campaign={BranchCampaign.generalTopic} />
      </Box>
    </Box>
  );
};

export default GeneralTopic;
