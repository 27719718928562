import branch, { SessionData } from "branch-sdk";
import queryString from "query-string";
import { v4 as uuidv4 } from "uuid";

type TBranchDataCallback = (data: SessionData | null) => void;

export const QR_CODE_WEB_URL =
  "https://insighttimer.com/subscribe/lgtv" as const;

// eslint-disable-next-line
export enum BranchCampaign {
  homepage = "lgtv_memberplus_home",
  libraryItem = "lgtv_memberplus_library_item",
  courseDetailModal = "lgtv_memberplus_course_detail_modal",
  courseDetail = "lgtv_memberplus_course_item",
  courses = "lgtv_memberplus_courses",
  coursesModal = "lgtv_memberplus_courses_modal",
  generalTopic = "lgtv_memberplus_topic",
  sleep = "lgtv_memberplus_sleep",
  music = "lgtv_memberplus_music",
  parents = "lgtv_memberplus_parents",
}

export let initialized = false;

export const branchInit = (cb: TBranchDataCallback) => {
  initialized = true;

  if (!process.env.REACT_APP_BRANCH_API_KEY) {
    console.warn("branch.init: branch api key is not set");
    return;
  }

  return branch.init(
    process.env.REACT_APP_BRANCH_API_KEY,
    {},
    (error, data) => {
      if (error != null) {
        console.warn("branch.init: ERROR", error);
      }
      cb(data);
    }
  );
};

export function branchData(cb: TBranchDataCallback) {
  return branch.data((error, data) => {
    if (error != null) {
      console.warn("branch.data: ERROR", error);
    }
    cb(data);
  });
}

export const saveUTMs = (
  location: Location,
  utmSessionData: SessionData | null
) => {
  const data_parsed: Record<string, unknown> | undefined =
    utmSessionData?.data_parsed;
  const queries = queryString.parse(location.search);
  const utmData: Record<string, unknown> = {};
  // only store relevant utm values (if present)
  if (data_parsed && !data_parsed) {
    // save utms from branch
    if (data_parsed["~campaign"]) {
      utmData.utm_campaign = data_parsed["~campaign"];
    }
    if (data_parsed["~channel"]) {
      utmData.utm_source = data_parsed["~channel"];
    }
    if (data_parsed["~feature"]) {
      utmData.utm_medium = data_parsed["~feature"];
    }

    if (data_parsed["$sharer_user_id"]) {
      utmData["$sharer_user_id"] = data_parsed["$sharer_user_id"];
    }
    if (data_parsed["$share_link_id"]) {
      utmData["$share_link_id"] = data_parsed["$share_link_id"];
    }
    if (data_parsed["$share_created_date"]) {
      utmData["$share_created_date"] = data_parsed["$share_created_date"];
    }
  } else if (queries) {
    // save utms from query parameters
    if (queries["utm_source"]) utmData["utm_source"] = queries["utm_source"];
    if (queries["utm_medium"]) utmData["utm_medium"] = queries["utm_medium"];
    if (queries["utm_campaign"]) {
      utmData["utm_campaign"] = queries["utm_campaign"];
    }
    if (queries["utm_content"]) utmData["utm_content"] = queries["utm_content"];
    if (queries["utm_term"]) utmData["utm_term"] = queries["utm_term"];
  }

  if (!branchData) {
    const utmDataJson = JSON.stringify(utmData);
    localStorage.setItem("IT-branchAttributionData", utmDataJson);
  }
};

export const branchLink = (
  linkData: branch.DeepLinkData,
  cb: (error: branch.BranchError, link: branch.BranchError) => void
) => {
  if (!initialized) {
    branchInit(() => {
      branch.link(linkData, cb);
    });
  } else {
    branch.link(linkData, cb);
  }
};

export function getBranchUrl(weblink: string, campaign: BranchCampaign) {
  const shareLinkId = uuidv4();
  const date = new Date().valueOf();

  return new Promise<string>((resolve, reject) => {
    branchLink(
      {
        campaign: campaign,
        data: {
          $share_link_id: shareLinkId,
          $share_created_date: date,
          $ios_passive_deepview: "false",
          $android_passive_deepview: "false",
          $desktop_url: weblink,
          $fallback_url: weblink,
          $ios_url: weblink,
          $android_url: weblink,
          $web_only: true,
        },
      },
      (err, generatedLink) => {
        if (err) {
          reject(err);
          console.error(err);
        }
        resolve(generatedLink ? generatedLink : "");
      }
    );
  });
}
