import { Box, Flex, Heading, Text } from "@chakra-ui/react";
import React, { memo } from "react";
import { Link } from "react-router-dom";
import {
  getCurrentLiveDuration,
  getLiveDuration,
} from "../../common/api/LiveEvents";
import { RouteList } from "../../common/routes/router";
import { EventSummary } from "../../common/types/Event";
import { getPublisherImageUrl } from "../../common/utils/helpers";
import { useCursor } from "../../hooks/useCursor";
import LiveEventTileImage from "./LiveEventTileImage";
import LiveEventTileProgressBar from "./LiveEventTileProgressBar";

export type LibraryItemCardSize = "md" | "lg";

interface Props {
  event: EventSummary;
  isActive?: boolean;
  size?: "md" | "lg";
}

const LiveEventTile: React.FC<Props> = ({ event, isActive, size = "md" }) => {
  const publisherImage = getPublisherImageUrl(event.owner.id, "medium");
  const nextOccurrence = event._next_occurrences[0];
  const liveDuration = getLiveDuration(nextOccurrence);
  const currentLiveDuration = getCurrentLiveDuration(nextOccurrence);
  const hasEventStarted =
    Date.now().valueOf() > nextOccurrence.start_date.epoch;

  const { isCursorVisible } = useCursor();

  const isSelected = !isCursorVisible && isActive;

  return (
    <Link to={`/${RouteList.Live}/${event.id}/play`} role="group">
      <Flex
        w={size === "md" ? "572px" : "100%"}
        align="center"
        padding={4}
        bg={isSelected ? "white" : "bgGrayMedium"}
        borderRadius="12px"
        transition="transform 0.3s"
        _groupHover={{
          bg: isCursorVisible || isSelected ? "white" : "bgGrayMedium",
        }}
      >
        <Flex flex={1}>
          <LiveEventTileImage image={publisherImage} event={event} />

          <Flex flexDir="column" justify="center" flex={1} position="relative">
            <Heading
              fontSize="lg"
              lineHeight="40px"
              color={isSelected ? "black" : "white"}
              noOfLines={2}
              _groupHover={{
                color: isCursorVisible || isSelected ? "black" : "white",
              }}
            >
              {event.title}
            </Heading>

            <Box height={1} />

            <Heading
              size="xs"
              color="textGrayMedium"
              lineHeight="34px"
              noOfLines={1}
            >
              with {event.owner.name}
            </Heading>

            {hasEventStarted && (
              <LiveEventTileProgressBar
                isActive={isSelected ?? false}
                progressPc={currentLiveDuration / liveDuration}
              />
            )}
          </Flex>
        </Flex>

        {size === "lg" && (
          <>
            <Box
              w="1px"
              h="120px"
              mx="50px"
              bg={isActive ? "altGray" : "textGrayDark"}
            />

            <Text
              fontSize="slg"
              lineHeight="38px"
              flex={1}
              noOfLines={3}
              pr={8}
              color={isActive ? "textGrayDark" : "textGrayMedium"}
              _groupHover={{
                color: isActive ? "textGrayDark" : "textGrayMedium",
              }}
            >
              {event.description}
            </Text>
          </>
        )}
      </Flex>
    </Link>
  );
};

export default memo(LiveEventTile);
