import { Box, Grid, GridItem } from "@chakra-ui/react";
import React, { memo } from "react";
import IconDelete from "../../assets/icons/icon-delete.svg";
import IconSpace from "../../assets/icons/icon-space.svg";
import { useSearchQuery } from "../../hooks/useSearchQuery";
import KeyboardButton from "./KeyboardButton";

const KEYBOARD_CHARACTERS = "abcdefghijklmnopqrstuvwxyz1234567890" as const;

const Keyboard = () => {
  const { searchQuery, setSearchQuery } = useSearchQuery();

  function onCharacter(character: string) {
    setSearchQuery(searchQuery + character);
  }

  function onSpace() {
    setSearchQuery(searchQuery + " ");
  }

  function onDelete() {
    setSearchQuery(searchQuery.slice(0, -1));
  }

  return (
    <Box position="sticky" zIndex={20}>
      <Box position="sticky" top={240}>
        <Grid templateColumns="repeat(6, 1fr)" gap={1}>
          <GridItem colSpan={3}>
            <KeyboardButton
              image={IconSpace}
              character="space"
              onKeyPress={onSpace}
            />
          </GridItem>

          <GridItem colSpan={3}>
            <KeyboardButton
              image={IconDelete}
              character="delete"
              onKeyPress={onDelete}
            />
          </GridItem>

          {KEYBOARD_CHARACTERS.split("").map((char) => {
            return (
              <Box key={char} w="70px">
                <KeyboardButton character={char} onKeyPress={onCharacter} />
              </Box>
            );
          })}
        </Grid>
      </Box>
    </Box>
  );
};

export default memo(Keyboard);
