import React, { Box } from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import { Focusable } from "react-sunbeam";
import useSWR from "swr";
import PlayIcon from "../assets/icons/play_icon.svg";
import {
  getLibraryItem,
  getMoreFromTeacher,
  getRelatedContentForTags,
} from "../common/api/LibraryItems";
import { FocusKey, focusManager } from "../common/global/focusManager";
import { LibraryItemSummary } from "../common/types/Item";
import { TrackingEventName } from "../common/types/Tracking";
import { BranchCampaign } from "../common/utils/branch";
import { handleKeyPress } from "../common/utils/keyboard";
import HeroButton from "../components/Button/HeroButton";
import LazyLibraryItemCarousel from "../components/Carousel/LazyLibraryItemCarousel";
import MemberPlusAd from "../components/MemberPlusAd/MemberPlusAd";
import TrackMeta from "../components/Meta/TrackMeta";
import TrackOverviewHeroSection from "../components/Section/TrackOverviewHeroSection";
import FullPageSpinner from "../components/Spinner/FullPageSpinner";
import { useAnalytics } from "../hooks/useAnalytics";
import { useFocusKey } from "../hooks/useFocusKey";

const TrackOverview = () => {
  const params = useParams();
  const libraryItemId = params.id;

  const { data: libraryItem, isValidating } = useSWR(
    `api/libraryItem/${libraryItemId}`,
    () => getLibraryItem(libraryItemId)
  );

  if (isValidating || !libraryItem) {
    return <FullPageSpinner />;
  }

  return <TrackOverviewContent libraryItem={libraryItem} />;
};

interface TrackOverviewContentProps {
  libraryItem: LibraryItemSummary;
}

const TrackOverviewContent = ({ libraryItem }: TrackOverviewContentProps) => {
  const navigate = useNavigate();
  const { trackClick } = useAnalytics();
  const { activeNavItem } = useFocusKey();

  function onPlayTrackPressed(event: KeyboardEvent) {
    handleKeyPress(event, {
      onEnter: () => {
        trackClick({
          click_event_name: TrackingEventName.playTrackButtonClicked,
          library_item_id: libraryItem.id,
          library_item_type: libraryItem.content_type,
        });
        handlePlayButtonPress();
      },
      onLeftArrow: () =>
        focusManager.setFocus([FocusKey.Navigation, activeNavItem.focusKey]),
    });
  }

  function handlePlayButtonPress() {
    navigate(`play`);
  }

  function getMoreFromTeacherTracks() {
    return getMoreFromTeacher(libraryItem.publisher.id, libraryItem.id);
  }

  function getRelatedLibraryItems() {
    return getRelatedContentForTags(
      "SINGLE",
      libraryItem.tags.slice(0, 1),
      libraryItem.id
    );
  }

  function getRelatedCourses() {
    return getRelatedContentForTags(
      "COURSE",
      libraryItem.tags.slice(0, 1),
      libraryItem.id
    );
  }

  return (
    <Box>
      <TrackOverviewHeroSection
        item={libraryItem}
        heroButton={
          <Focusable onKeyPress={onPlayTrackPressed}>
            {({ focused }) => {
              return (
                <HeroButton
                  isFocused={focused}
                  icon={PlayIcon}
                  onClick={handlePlayButtonPress}
                >
                  Play Track
                </HeroButton>
              );
            }}
          </Focusable>
        }
        preHeadingElements={<TrackMeta libraryItem={libraryItem} />}
      />

      <LazyLibraryItemCarousel
        focusKey={`RELATED-TRACKS-${libraryItem.id}`}
        title="Related Tracks"
        swrKey={`api/libraryItem/${libraryItem.id}/related/library-items`}
        fetcherFn={getRelatedLibraryItems}
        size="md"
      />

      <LazyLibraryItemCarousel
        focusKey={`MORE-FROM-TEACHER-${libraryItem.id}`}
        title="More From This Teacher"
        swrKey={`api/libraryItem/${libraryItem.id}/more-from-teacher`}
        fetcherFn={getMoreFromTeacherTracks}
        size="md"
      />

      <LazyLibraryItemCarousel
        focusKey={`RELATED-COURSES-${libraryItem.id}`}
        title="Related Courses"
        swrKey={`api/libraryItem/${libraryItem.id}/related/courses`}
        fetcherFn={getRelatedCourses}
      />

      <MemberPlusAd campaign={BranchCampaign.libraryItem} />

      <Box height={24} />
    </Box>
  );
};

export default TrackOverview;
