import { Flex, Spinner } from "@chakra-ui/react";
import React, { useState } from "react";
import { Focusable } from "react-sunbeam";
import { FocusKey, focusManager } from "../../common/global/focusManager";
import { useFocusKey } from "../../hooks/useFocusKey";
import useKeyboardNavigation from "../../hooks/useKeyboardNavigation";

const FullPageSpinner = () => {
  const [isFocused, setIsFocused] = useState(false);
  const { activeNavItem } = useFocusKey();

  useKeyboardNavigation(isFocused, {
    onLeftArrow: () =>
      focusManager.setFocus([FocusKey.Navigation, activeNavItem.focusKey]),
  });

  function handleOnFocus() {
    setIsFocused(true);
  }

  function handleOnBlur() {
    setIsFocused(false);
  }

  return (
    <Focusable onFocus={handleOnFocus} onBlur={handleOnBlur}>
      <Flex w="100vw" h="100vh" justify="center" align="center">
        <Spinner size="xl" />
      </Flex>
    </Focusable>
  );
};

export default FullPageSpinner;
