import { Box, Flex } from "@chakra-ui/react";
import React, { memo, useRef } from "react";
import { Focusable } from "react-sunbeam";
import { SwiperSlide } from "swiper/react/swiper-react";
import { LibraryItemSummary } from "../../common/types/Item";
import useScrollIntoView from "../../hooks/useScrollIntoView";
import CourseDayTile from "../Tiles/CourseDayTile";
import Carousel from "./Carousel";
import CarouselHeading from "./CarouselHeading";

interface Props {
  course: LibraryItemSummary;
}

const CourseDayCarousel: React.FC<Props> = ({ course }) => {
  const carouselRef = useRef<HTMLDivElement>(null);
  const { scrollIntoView } = useScrollIntoView(carouselRef);

  return (
    <Focusable onFocus={scrollIntoView}>
      {({ focused }) => {
        return (
          <Flex ref={carouselRef} flexDir="column" py={8}>
            <CarouselHeading content="Day Overview" />

            <Box my={8}>
              <Carousel
                isFocused={focused}
                numberOfSlides={course.course_days?.length ?? 0}
                spaceBetween={-40}
                offsetBefore={185}
              >
                {course.course_days?.map((courseDay) => {
                  return (
                    <SwiperSlide
                      key={courseDay.id}
                      style={{
                        width: "auto",
                        height: "auto",
                      }}
                    >
                      {({ isActive }) => (
                        <CourseDayTile
                          courseDay={courseDay}
                          isActive={isActive}
                        />
                      )}
                    </SwiperSlide>
                  );
                })}
              </Carousel>
            </Box>
          </Flex>
        );
      }}
    </Focusable>
  );
};

export default memo(CourseDayCarousel);
