import { Avatar, AvatarGroup, Flex, Heading } from "@chakra-ui/react";
import React, { memo } from "react";
import useSWR from "swr";
import { getTotalMedFriends } from "../../common/api/MedFriends";
import { getPublisherImageUrl } from "../../common/utils/helpers";

interface Props {
  itemId: string;
  borderColor?: string;
}

const PlayerMeditatorCount = ({ itemId, borderColor = "bgGray" }: Props) => {
  const { data: medfriends, isValidating } = useSWR(
    `/api/medfriends/community/${itemId}`,
    () =>
      getTotalMedFriends({
        limit: 3,
      })
  );

  const meditatorCount = medfriends != null ? parseInt(medfriends.total) : 0;

  if (isValidating || meditatorCount === 0 || isNaN(meditatorCount)) {
    return null;
  }

  return (
    <Flex align="center">
      <AvatarGroup mr="15px" max={3} spacing="-12px">
        {medfriends?.users.map((user) => {
          return (
            <Avatar
              w="42px"
              h="42px"
              key={user.id}
              src={getPublisherImageUrl(user.id)}
              borderWidth="4px"
              borderColor={borderColor}
            />
          );
        })}
      </AvatarGroup>

      <Heading size="xs">
        {meditatorCount.toLocaleString()} meditating now
      </Heading>
    </Flex>
  );
};

export default memo(PlayerMeditatorCount);
