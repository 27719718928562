// eslint-disable-next-line
export enum RouteList {
  Home = "",
  Search = "search",
  Live = "live",
  Courses = "courses",
  Sleep = "sleep",
  Music = "music",
  Parents = "parents",
  Track = "track",
  CourseOverview = "course",
  Topic = "topic",
  TimeFilter = "time-filter",
}
