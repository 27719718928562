import { Box } from "@chakra-ui/react";
import React from "react";
import SleepBackgroundImage from "../assets/images/background-sleep.jpg";
import { getFeaturedList } from "../common/api/FeaturedList";
import {
  getFilteredLibraryItems,
  getPopularCoursesByTags,
  getPopularLibraryItemsByTags,
} from "../common/api/LibraryItems";
import { FeaturedListEntryPoint } from "../common/types/FeaturedList";
import { SLEEP_SUBTOPICS } from "../common/types/Topic";
import { BranchCampaign } from "../common/utils/branch";
import LazyLibraryItemCarousel from "../components/Carousel/LazyLibraryItemCarousel";
import MemberPlusAd from "../components/MemberPlusAd/MemberPlusAd";
import SubTopicSection from "../components/Section/SubTopicSection";
import TopicHeroSection from "../components/Section/TopicHeroSection";

const Sleep = () => {
  function getFeatured() {
    return getFeaturedList(FeaturedListEntryPoint.Sleep);
  }

  function getPopular() {
    return getPopularLibraryItemsByTags("sleep");
  }

  function getCourses() {
    return getPopularCoursesByTags("sleep");
  }

  function getNewAndTrending() {
    return getFilteredLibraryItems({
      topics: ["sleep"],
      sort_option: "newest",
    });
  }

  return (
    <Box>
      <TopicHeroSection
        title="Sleep"
        description="Thousands of free music tracks and stories to help you get a better nights sleep"
        bgImage={SleepBackgroundImage}
      />

      <LazyLibraryItemCarousel
        focusKey="SLEEP-FEATURED"
        title="Featured"
        swrKey="api/sleep/featured"
        fetcherFn={getFeatured}
      />

      <LazyLibraryItemCarousel
        focusKey="SLEEP-POPULAR"
        title="Popular"
        swrKey="api/sleep/popular"
        fetcherFn={getPopular}
      />

      <LazyLibraryItemCarousel
        focusKey="SLEEP-COURSES"
        title="Courses"
        swrKey="api/sleep/courses"
        fetcherFn={getCourses}
      />

      <Box height="50px" />

      <MemberPlusAd campaign={BranchCampaign.sleep} />

      {SLEEP_SUBTOPICS.map((subTopic) => {
        return <SubTopicSection key={subTopic.id} subTopic={subTopic} />;
      })}

      <LazyLibraryItemCarousel
        focusKey="SLEEP-NEW-AND-TRENDING"
        title="New & Trending"
        swrKey="api/sleep/newAndTrending"
        fetcherFn={getNewAndTrending}
      />
    </Box>
  );
};

export default Sleep;
