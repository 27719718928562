import { Box, Text } from "@chakra-ui/react";
import React, { memo } from "react";

interface Props {
  days: number;
}

const CourseDayCountBadge = ({ days }: Props) => {
  return (
    <Box
      padding="2px 8px"
      background="courseYellow"
      borderRadius={6}
      mb="2px"
      boxShadow="sm"
    >
      <Text color="white" fontSize={14} fontWeight={600}>
        {days} DAY COURSE
      </Text>
    </Box>
  );
};

export default memo(CourseDayCountBadge);
