import { Box } from "@chakra-ui/react";
import React from "react";
import { EventSummary } from "../../common/types/Event";
import { SIDEBAR_WIDTH_PX } from "../../common/utils/helpers";
import CarouselHeading from "../Carousel/CarouselHeading";
import CarouselLoadingSpinner from "../Spinner/CarouselLoadingSpinner";
import LiveListTile from "../Tiles/LiveListTile";

interface Props {
  title: string;
  isLoading: boolean;
  events?: EventSummary[];
}

const LiveEventsSection = ({ title, isLoading, events }: Props) => {
  if (isLoading) {
    return <CarouselLoadingSpinner />;
  }
  return (
    <Box py={10}>
      <CarouselHeading content={title} />
      <Box ml={SIDEBAR_WIDTH_PX} mr={16}>
        {events?.map((event) => {
          return <LiveListTile key={event.id} event={event} />;
        })}
      </Box>
    </Box>
  );
};

export default LiveEventsSection;
