import dayjs from "dayjs";
import dayAdvancedFormat from "dayjs/plugin/advancedFormat";
import dayDuration from "dayjs/plugin/duration";
import utc from "dayjs/plugin/utc";
import ParentsBackgroundImageDay from "../../assets/images/background-parents-day.jpg";
import ParentsBackgroundImageEvening from "../../assets/images/background-parents-evening.jpg";
import ParentsBackgroundImageNight from "../../assets/images/background-parents-night.jpg";

dayjs.extend(dayDuration);

export type ImageSize = "small" | "medium" | "large" | "xlarge";
export type ImageShape = "square" | "rectangle";

export const SIDEBAR_WIDTH = 200 as const;
export const SIDEBAR_WIDTH_PX = `${SIDEBAR_WIDTH}px` as const;

export const getLibraryItemImageUrl = (
  id: string,
  size: ImageSize = "medium",
  shape: ImageShape = "square"
) => {
  return `https://libraryitems.insighttimer-api.net/${id}/pictures/${shape}_${size}.jpeg`;
};

export const getPublisherImageUrl = (
  id: string,
  size: "small" | "medium" | "large" = "small"
) => {
  return `https://users.insighttimer-api.net/${id}/pictures/square_${size}.jpeg`;
};

export const mediaLengthString = (mediaLength: number) => {
  const duration = dayjs.duration({ seconds: mediaLength });
  const mediaLengthInSeconds = duration.asSeconds();
  const mediaLengthInMinutes = duration.asMinutes();

  if (mediaLengthInSeconds < 60) {
    return `${Math.floor(duration.asSeconds())} ${
      mediaLengthInSeconds === 1 ? "SEC" : "SECS"
    }`;
  }

  return `${Math.floor(mediaLengthInMinutes)} ${
    mediaLengthInMinutes < 2 ? "MIN" : "MINS"
  }`;
};

export function getParentsBgImageForTimeOfDay() {
  const currentHour = new Date().getHours();

  if (currentHour > 0 && currentHour < 5) {
    return ParentsBackgroundImageNight;
  } else if (currentHour >= 5 && currentHour < 9) {
    return ParentsBackgroundImageEvening;
  } else if (currentHour >= 9 && currentHour < 18) {
    return ParentsBackgroundImageDay;
  } else if (currentHour >= 18 && currentHour < 22) {
    return ParentsBackgroundImageEvening;
  } else {
    return ParentsBackgroundImageNight;
  }
}

export function formatDuration(duration: dayDuration.Duration) {
  dayjs.extend(dayDuration);
  dayjs.extend(utc);

  if (duration.asMinutes() >= 60) {
    return dayjs.utc(duration.asMilliseconds()).format("hh:mm:ss");
  } else {
    return dayjs.utc(duration.asMilliseconds()).format("mm:ss");
  }
}

export function today() {
  dayjs.extend(dayAdvancedFormat);
  return dayjs().hour(0).minute(0).second(0).millisecond(0);
}

export function getHomeHeroImage() {
  const maxImageNumber = 48;
  const randomImageNumber = Math.floor(Math.random() * maxImageNumber) + 1;

  return `${process.env.REACT_APP_CURATED_IMAGES_API_URL}lgtv/${randomImageNumber}.jpg`;
}
