import { Box, Flex, Heading, Image, Text } from "@chakra-ui/react";
import React, { memo, useRef } from "react";
import { Focusable } from "react-sunbeam";
import IconLocation from "../../assets/icons/icon-location.svg";
import { FocusKey, focusManager } from "../../common/global/focusManager";
import { LibraryItemSummary } from "../../common/types/Item";
import {
  getPublisherImageUrl,
  SIDEBAR_WIDTH_PX,
} from "../../common/utils/helpers";
import { handleKeyPress } from "../../common/utils/keyboard";
import { useFocusKey } from "../../hooks/useFocusKey";
import useScrollIntoView from "../../hooks/useScrollIntoView";

interface Props {
  course: LibraryItemSummary;
}

const CoursePublisher: React.FC<Props> = ({ course }) => {
  const publisherDetailsRef = useRef<HTMLDivElement>(null);
  const { scrollIntoView } = useScrollIntoView(publisherDetailsRef);
  const { activeNavItem } = useFocusKey();
  const publisherImage = getPublisherImageUrl(course.publisher.id, "medium");
  const publisherBio = course?.publisher_intro
    ? course.publisher_intro.length > 2000
      ? course.publisher_intro.slice(0, 2000)
      : course.publisher_intro
    : "This teacher has no biography.";

  function onKeyPress(event: KeyboardEvent) {
    handleKeyPress(event, {
      onLeftArrow: () =>
        focusManager.setFocus([FocusKey.Navigation, activeNavItem.focusKey]),
    });
  }

  return (
    <Box
      ml={SIDEBAR_WIDTH_PX}
      ref={publisherDetailsRef}
      marginRight={8}
      width="50%"
    >
      <Focusable onKeyPress={onKeyPress} onFocus={scrollIntoView}>
        <Box marginTop="4rem">
          <Flex alignItems="center">
            <Image
              src={publisherImage}
              width="185px"
              height="185px"
              borderRadius="185px"
              mr={8}
            />

            <Box>
              <Heading size="md" mb="0.75rem">
                {course.publisher.name}
              </Heading>

              {course.publisher.region && (
                <Flex>
                  <Image src={IconLocation} mr={2} />
                  <Heading
                    size="xxs"
                    textTransform="uppercase"
                    color="textGray"
                  >
                    {course.publisher.region.name}
                  </Heading>
                </Flex>
              )}
            </Box>
          </Flex>
        </Box>

        <Text size="sm" marginY="4rem" color="white" noOfLines={18}>
          {publisherBio}
        </Text>
      </Focusable>
    </Box>
  );
};

export default memo(CoursePublisher);
