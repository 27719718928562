import axios from "axios";
import queryString from "query-string";
import { UserSummary } from "../../common/types/User";

interface MedFriendsResponse {
  item_summary: {
    type: string;
    user_summary: UserSummary;
  };
}

interface Filter {
  limit: number;
}

export const getTotalMedFriends = async (filter: Filter) => {
  const query = queryString.stringify(filter);

  const { data, headers } = await axios.get<MedFriendsResponse[]>(
    `${process.env.REACT_APP_FILTERING_API_URL}api/v1/medfriends/community?${query}`
  );

  const results = data.map((item) => item.item_summary.user_summary);

  return {
    users: results,
    total: headers["x-total-count"],
  };
};
