import { Box, Image } from "@chakra-ui/react";
import React, { memo } from "react";
import { EventSummary } from "../../common/types/Event";
import LiveEventBadge from "../Badge/LiveEventBadge";

interface Props {
  image: string;
  event: EventSummary;
}

const LiveEventTileImage = ({ image, event }: Props) => {
  return (
    <Box position="relative" flexShrink={0} mr={4}>
      <Image src={image} w="154px" h="154px" borderRadius={12} mr={5} />
      <Box position="absolute" bottom={2} left={2}>
        <LiveEventBadge event={event} />
      </Box>
    </Box>
  );
};

export default memo(LiveEventTileImage);
