import { Box } from "@chakra-ui/react";
import React from "react";
import { getFeaturedList } from "../common/api/FeaturedList";
import { getFilteredLibraryItems } from "../common/api/LibraryItems";
import { FeaturedListEntryPoint } from "../common/types/FeaturedList";
import { TIME_FILTERS } from "../common/types/Timer";
import { POPULAR_TOPICS } from "../common/types/Topic";
import { BranchCampaign } from "../common/utils/branch";
import LazyLibraryItemCarousel from "../components/Carousel/LazyLibraryItemCarousel";
import LibraryItemCarousel from "../components/Carousel/LibraryItemCarousel";
import TopicCarousel from "../components/Carousel/TopicCarousel";
import MemberPlusAd from "../components/MemberPlusAd/MemberPlusAd";
import HomeHeroSection from "../components/Section/HomeHeroSection";
import useRecentlyPlayed from "../hooks/useRecentlyPlayed";

const Home = () => {
  const { recentlyPlayed } = useRecentlyPlayed();

  function getFeaturedItems() {
    return getFeaturedList(FeaturedListEntryPoint.Guided);
  }

  function getCourses() {
    return getFeaturedList(FeaturedListEntryPoint.Courses);
  }

  function getStaffPicks() {
    return getFeaturedList(FeaturedListEntryPoint.StaffPick);
  }

  function getPopularThisWeek() {
    return getFilteredLibraryItems({
      sort_option: "play_count_last_7_days",
      limit: 15,
    });
  }

  function getNewAndTrending() {
    return getFilteredLibraryItems({
      sort_option: "newest",
      limit: 15,
    });
  }

  function getForBeginners() {
    return getFeaturedList(FeaturedListEntryPoint.Beginners);
  }

  return (
    <>
      <HomeHeroSection />

      <LazyLibraryItemCarousel
        focusKey="HOME-FEATURED"
        title="Featured"
        swrKey="api/home/featured"
        fetcherFn={getFeaturedItems}
      />

      <MemberPlusAd campaign={BranchCampaign.homepage} />

      {recentlyPlayed.length > 0 && (
        <LibraryItemCarousel
          focusKey="HOME-RECENTLY-PLAYED"
          title="Recently Played"
          libraryItems={recentlyPlayed}
          isLoading={false}
          size="md"
        />
      )}

      <LazyLibraryItemCarousel
        focusKey="HOME-COURSES"
        title="Featured Courses"
        swrKey="api/home/courses"
        fetcherFn={getCourses}
      />

      <LazyLibraryItemCarousel
        focusKey="HOME-STAFF-PICKS"
        swrKey="api/home/staffPicks"
        fetcherFn={getStaffPicks}
        size="md"
      />

      <LazyLibraryItemCarousel
        focusKey="HOME-POPULAR-THIS-WEEK"
        title="Popular This Week"
        swrKey="api/home/popularThisWeek"
        size="md"
        fetcherFn={getPopularThisWeek}
      />

      <TopicCarousel
        title="Browse by time"
        topics={TIME_FILTERS}
        isTimeFilter
      />

      <LazyLibraryItemCarousel
        focusKey="HOME-NEW-AND-TRENDING"
        title="New And Trending"
        swrKey="api/home/newAndTrending"
        size="md"
        fetcherFn={getNewAndTrending}
      />

      <LazyLibraryItemCarousel
        focusKey="HOME-FOR-BEGINNERS"
        title="For Beginners"
        swrKey="api/home/beginners"
        size="md"
        fetcherFn={getForBeginners}
      />

      <TopicCarousel title="Browse by topic" topics={POPULAR_TOPICS} />

      <Box height={24} />
    </>
  );
};

export default Home;
