import { Box } from "@chakra-ui/react";
import React from "react";
import { getFeaturedList } from "../common/api/FeaturedList";
import {
  getFilteredLibraryItems,
  getPopularCoursesByTags,
  getPopularLibraryItemsByTags,
} from "../common/api/LibraryItems";
import { FeaturedListEntryPoint } from "../common/types/FeaturedList";
import { PARENTS_SUBTOPICS } from "../common/types/Topic";
import { BranchCampaign } from "../common/utils/branch";
import { getParentsBgImageForTimeOfDay } from "../common/utils/helpers";
import LazyLibraryItemCarousel from "../components/Carousel/LazyLibraryItemCarousel";
import MemberPlusAd from "../components/MemberPlusAd/MemberPlusAd";
import SubTopicSection from "../components/Section/SubTopicSection";
import TopicHeroSection from "../components/Section/TopicHeroSection";

const Parents = () => {
  const bgImage = getParentsBgImageForTimeOfDay();

  function getFeatured() {
    return getFeaturedList(FeaturedListEntryPoint.Kids);
  }

  function getPopular() {
    return getPopularLibraryItemsByTags("kids");
  }

  function getCourses() {
    return getPopularCoursesByTags("kids");
  }

  function getNewAndTrending() {
    return getFilteredLibraryItems({
      topics: ["children"],
      sort_option: "newest",
    });
  }

  return (
    <Box>
      <TopicHeroSection
        title="Parents"
        description="Practice these with your kids to help ease their nerves and boost self-confidence"
        bgImage={bgImage}
      />

      <LazyLibraryItemCarousel
        focusKey="PARENTS-FEATURED"
        title="Featured"
        swrKey="api/parents/featured"
        fetcherFn={getFeatured}
      />

      <LazyLibraryItemCarousel
        focusKey="PARENTS-POPULAR"
        title="Popular"
        swrKey="api/parents/popular"
        fetcherFn={getPopular}
      />

      <LazyLibraryItemCarousel
        focusKey="PARENTS-COURSES"
        title="Courses"
        swrKey="api/parents/courses"
        fetcherFn={getCourses}
      />

      <Box height="50px" />

      <MemberPlusAd campaign={BranchCampaign.parents} />

      {PARENTS_SUBTOPICS.map((subTopic) => {
        return <SubTopicSection key={subTopic.id} subTopic={subTopic} />;
      })}

      <LazyLibraryItemCarousel
        focusKey="PARENTS-NEW-AND-TRENDING"
        title="New and Trending"
        swrKey="api/parents/newAndTrending"
        fetcherFn={getNewAndTrending}
      />
    </Box>
  );
};

export default Parents;
