import { Box, Flex } from "@chakra-ui/react";
import React, { memo, useEffect, useState } from "react";
import { FocusKey, focusManager } from "../../common/global/focusManager";
import useScrollIntoView from "../../hooks/useScrollIntoView";
import InsightTimerLogo from "./InsightTimerLogo";
import SideBarNavItems from "./SideBarNavItems";

interface Props {
  isFocused: boolean;
}

const SidebarNav = ({ isFocused }: Props) => {
  const { scrollToTop } = useScrollIntoView();
  const [isCollapsed, setIsCollapsed] = useState(false);

  useEffect(() => {
    setIsCollapsed(isFocused);
  }, [isFocused]);

  function handleMouseEnter() {
    setIsCollapsed(true);
  }

  function handleMouseLeave() {
    setIsCollapsed(false);
  }

  function handleRouteChange() {
    setIsCollapsed(true);
    focusManager.setFocus([FocusKey.Root]);
    scrollToTop();
  }

  return (
    <Box
      pos="fixed"
      top="0"
      width={isCollapsed ? "100vw" : "12.5rem"}
      height="100vh"
      zIndex={25}
      background={
        isCollapsed
          ? "linear-gradient(90deg, rgba(0,0,0,1) 0%, transparent 100%)"
          : "linear-gradient(90deg, rgba(0,0,0,0.5) 0%, transparent 100%)"
      }
      transition="0.3s"
    >
      <Flex flexDir="column" align="flex-start" h="100%">
        <Box
          h="100%"
          px={12}
          py={20}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <InsightTimerLogo />

          <Box height="70px" />

          <SideBarNavItems
            isFocused={isFocused}
            isCollapsed={isCollapsed}
            onRouteChange={handleRouteChange}
          />
        </Box>
      </Flex>
    </Box>
  );
};

export default memo(SidebarNav);
