import { Heading } from "@chakra-ui/react";
import React, { memo } from "react";
import { SIDEBAR_WIDTH_PX } from "../../common/utils/helpers";

interface Props {
  content: string;
}

const CarouselHeading: React.FC<Props> = ({ content }: Props) => {
  return (
    <Heading ml={SIDEBAR_WIDTH_PX} pl={4} mb={4} fontWeight={700} fontSize={38}>
      {content}
    </Heading>
  );
};

export default memo(CarouselHeading);
