import { Box, Flex } from "@chakra-ui/react";
import React, { memo, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Focusable } from "react-sunbeam";
import { SwiperSlide } from "swiper/react/swiper-react";
import { RouteList } from "../../common/routes/router";
import { LibraryItemSummary } from "../../common/types/Item";
import { TrackingEventName } from "../../common/types/Tracking";
import { useAnalytics } from "../../hooks/useAnalytics";
import { useFocusKey } from "../../hooks/useFocusKey";
import useScrollIntoView from "../../hooks/useScrollIntoView";
import CarouselLoadingSpinner from "../Spinner/CarouselLoadingSpinner";
import LibraryItemTile, { LibraryItemCardSize } from "../Tiles/LibraryItemTile";
import Carousel from "./Carousel";
import CarouselHeading from "./CarouselHeading";

interface Props {
  focusKey: string;
  title: string;
  isLoading: boolean;
  libraryItems?: LibraryItemSummary[];
  size?: LibraryItemCardSize;
}

const LibraryItemCarousel: React.FC<Props> = ({
  focusKey,
  title,
  isLoading,
  libraryItems,
  size = "md",
}) => {
  const navigate = useNavigate();
  const carouselRef = useRef<HTMLDivElement>(null);
  const { scrollIntoView } = useScrollIntoView(carouselRef);
  const { trackClick } = useAnalytics();
  const { updateLastFocus } = useFocusKey();

  function viewTrack(index: number) {
    if (libraryItems) {
      const trackId = libraryItems[index].id;
      const isCourse = libraryItems[index].item_type === "COURSES";

      updateLastFocus();

      if (isCourse) {
        trackClick({
          click_event_name: TrackingEventName.courseTileClicked,
          course_id: trackId,
        });
        navigate(`/${RouteList.CourseOverview}/${trackId}`);
      } else {
        trackClick({
          click_event_name: TrackingEventName.libraryItemTileClicked,
          library_item_id: trackId,
          library_item_type: libraryItems[index].content_type,
        });
        navigate(`/${RouteList.Track}/${trackId}`);
      }
    }
  }

  return (
    <Focusable focusKey={focusKey} onFocus={scrollIntoView}>
      {({ focused }) => {
        return (
          <Flex ref={carouselRef} flexDir="column" py={8}>
            <CarouselHeading content={title} />

            {isLoading || libraryItems == null ? (
              <CarouselLoadingSpinner />
            ) : (
              <Box>
                <Carousel
                  isFocused={focused}
                  numberOfSlides={libraryItems.length}
                  onEnterItem={viewTrack}
                >
                  {libraryItems.map((item) => {
                    return (
                      <SwiperSlide key={item.id} style={{ width: "auto" }}>
                        {({ isActive }) => {
                          return (
                            <LibraryItemTile
                              libraryItem={item}
                              size={size}
                              isActive={focused && isActive}
                            />
                          );
                        }}
                      </SwiperSlide>
                    );
                  })}
                </Carousel>
              </Box>
            )}
          </Flex>
        );
      }}
    </Focusable>
  );
};

export default memo(LibraryItemCarousel);
