import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useState,
} from "react";
import { FocusKey, focusManager } from "../common/global/focusManager";
import {
  navCategories,
  NavigationItem,
} from "../components/SidebarNav/SideBarNavItems";

const HOME_NAV_ITEM = navCategories[1];

interface Context {
  lastRootFocusKey: FocusKey | string;
  updateLastFocus: () => void;
  modalIsOpen: boolean;
  setModalIsOpen: (isOpen: boolean) => void;
  activeNavItem: NavigationItem;
  setActiveNavItem: Dispatch<SetStateAction<NavigationItem>>;
}

const FocusKeyContext = createContext<Context>({
  lastRootFocusKey: FocusKey.Root,
  updateLastFocus: () => undefined,
  modalIsOpen: false,
  setModalIsOpen: () => undefined,
  activeNavItem: HOME_NAV_ITEM,
  setActiveNavItem: () => undefined,
});

export const FocusKeyProvider: React.FC = ({ children }) => {
  // lastRootFocusKey tracks the last used Carousel in the main "Root" panel, this
  // is so we can navigate back to it when navigating away from the sidebar.
  const [lastRootFocusKey, setLastRootFocusKey] = useState<FocusKey | string>(
    FocusKey.Root
  );
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [activeNavItem, setActiveNavItem] = useState(HOME_NAV_ITEM);

  function updateLastFocus() {
    const focusPath = focusManager.getFocusPath();
    setLastRootFocusKey(focusPath[focusPath.length - 1]);
  }

  return (
    <FocusKeyContext.Provider
      value={{
        lastRootFocusKey,
        updateLastFocus,
        modalIsOpen,
        setModalIsOpen,
        activeNavItem,
        setActiveNavItem,
      }}
    >
      {children}
    </FocusKeyContext.Provider>
  );
};

export const useFocusKey = () => {
  const context = useContext(FocusKeyContext);

  return context;
};
