import { Box, Heading, Image } from "@chakra-ui/react";
import React, { memo } from "react";
import { NavigationItem } from "./SideBarNavItems";

interface Props {
  navItem: NavigationItem;
  navbarIsOpen: boolean;
  isActive: boolean;
}

const SidebarNavMenuItemContents = ({
  navItem,
  navbarIsOpen,
  isActive,
}: Props) => {
  return (
    <>
      <Image
        src={navItem.icon}
        width="30px"
        height="30px"
        filter={isActive ? "invert(100%)" : undefined}
        _groupHover={{
          filter: "invert(100%)",
        }}
      />

      <Box
        width="40px"
        visibility={navbarIsOpen ? "visible" : "hidden"}
        opacity={navbarIsOpen ? 1 : 0}
      />

      {navbarIsOpen && (
        <Heading
          w="350px"
          size="sm"
          mb="1px"
          color={isActive ? "gray.800" : "white"}
          visibility={navbarIsOpen ? "visible" : "hidden"}
          _groupHover={{
            color: "gray.800",
          }}
        >
          {navItem.title}
        </Heading>
      )}
    </>
  );
};

export default memo(SidebarNavMenuItemContents);
