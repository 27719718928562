import { Filter } from "../api/LibraryItems";

export const SUBTOPIC_CAROUSEL_LIMIT = 15;

export interface TopicGroups {
  name: string;
  topics: Topic[];
}

export interface TopicResponse {
  id: string;
  topic_groups: TopicGroups[];
}

export interface Topic {
  id: string;
  name: string;
  short_description: string;
}

export const POPULAR_TOPICS: Topic[] = [
  {
    id: "abundance",
    name: "Abundance",
    short_description:
      "An abundance mentality opens us to new possibilities and leads to creativity. By contract, a mindset grounded in scarcity is restrictive, which can make us feel anxious and fearful Meditation and the use of affirmations help shift the mindset from scarcity to abundance.",
  },
  {
    id: "acceptance",
    name: "Acceptance",
    short_description:
      "Acceptance means consciously allowing things to be as they are. It involves letting go of the desire to protest and make change. Meditation can help recognize feelings in the present moment and view experiences more compassionately.",
  },
  {
    id: "affirmations",
    name: "Affirmations",
    short_description:
      "Affirmation Meditation is the practice of positive thinking and self-empowerment. An affirmation is anything repeated out loud or in thoughts which register in our subconscious mind. Effective affirmations are positive, personal, specific, and in the present tense. The aim is for thoughts to consume our awareness to manifests goals or changes in behavior.",
  },
  {
    id: "anxiety",
    name: "Anxiety",
    short_description:
      "Anxiety is our body’s way of preparing for a ‘fight or flight’ response. It is usually triggered by perceived future events, many which do not eventuate. A regular daily meditation practice can help anxiety sufferers to focus on staying in the present moment and seeing a situations for what it is.",
  },
  {
    id: "mindfulness",
    name: "Mindfulness",
    short_description:
      "Mindfulness refers to meditation practices or exercises that simply notice feelings and thoughts as they come and go, without judgement.",
  },
  {
    id: "motivation",
    name: "Motivation",
    short_description:
      "Meditation can bring about clarity and focus that renews the mind. This creates space for new ideas and inspirations from which to flourish. This can motivate us on to achieve great things, and feel more energized.",
  },
  {
    id: "relax",
    name: "Relax",
    short_description:
      "The ability to relax is essential to our wellbeing. Our bodies release a surge of hormones in response to stress that make our hearts race and muscles tense. Meditation can help combat stress by activating the body’s natural relaxation response, slow the breathing rate, relaxing muscles and reducing blood pressure.",
  },
  {
    id: "selfconfidence",
    name: "Self Confidence",
    short_description:
      "Cultivating self love and compassion through meditation can help build self confidence, inner peace and positive emotions. When negative thoughts or feelings arise during meditation, we practice simply noticing them in the moment. This leaves us better able to handle negative internal dialogue outside of meditation.",
  },
  {
    id: "sleep",
    name: "Sleep",
    short_description:
      "Around one in three people suffer from mild insomnia. If you wake consistently at night or suffer from restless sleep patterns, these guided meditations, talks, and music tracks will help calm the mind and relax the body in preparation for sleep.",
  },
  {
    id: "stress",
    name: "Stress",
    short_description:
      "Stress is an ever-present issue in our lives as a result of modern living. Its long-term effects can be detrimental to our health and can lead to anxiety and depression. Meditation and mindfulness can help to counter these effects by lower stress hormones and decreasing inflammation in the body. It can be as simple as taking a few moments out of your day to breath and focus on the present moment.",
  },
];

export interface SubTopic {
  id: string;
  title: string;
  filter: Filter;
}

export const SLEEP_SUBTOPICS: SubTopic[] = [
  {
    id: "sleep/sleep-music",
    title: "Sleep Music",
    filter: {
      content_types: ["music"],
      topics: ["sleep"],
      limit: SUBTOPIC_CAROUSEL_LIMIT,
    },
  },
  {
    id: "sleep/soundscapes",
    title: "Soundscapes",
    filter: {
      content_types: ["music"],
      topics: ["sleep"],
      genres: ["nature_sounds"],
      limit: SUBTOPIC_CAROUSEL_LIMIT,
    },
  },
  {
    id: "sleep/bedtime-tales",
    title: "Bedtime Tales",
    filter: {
      content_types: ["talks"],
      topics: ["sleep"],
      sort_option: "newest",
      limit: SUBTOPIC_CAROUSEL_LIMIT,
    },
  },
  {
    id: "sleep/sleep-meditations",
    title: "Sleep Meditations",
    filter: {
      content_types: ["guided"],
      topics: ["sleep"],
      limit: SUBTOPIC_CAROUSEL_LIMIT,
    },
  },
  {
    id: "sleep/sleep-for-parents",
    title: "Sleep for Parents",
    filter: {
      topics: ["kids-sleep"],
      sort_option: "most_played",
      limit: SUBTOPIC_CAROUSEL_LIMIT,
    },
  },
];

export const MUSIC_SUBTOPICS: SubTopic[] = [
  {
    id: "music/ambient-sound",
    title: "Ambient Sounds",
    filter: {
      topics: ["ambientmusic"],
      genres: ["ambient_music"],
      content_types: ["music"],
      limit: SUBTOPIC_CAROUSEL_LIMIT,
    },
  },
  {
    id: "music/classic-music",
    title: "Classical Music",
    filter: {
      content_types: ["music"],
      topics: ["classicalmusic"],
      limit: SUBTOPIC_CAROUSEL_LIMIT,
    },
  },
  {
    id: "music/sound-healings",
    title: "Sound Healing",
    filter: {
      content_types: ["music"],
      topics: ["soundhealing"],
      limit: SUBTOPIC_CAROUSEL_LIMIT,
    },
  },
  {
    id: "music/chanting-and-mantras",
    title: "Chanting & Mantras",
    filter: {
      content_types: ["music"],
      topics: ["chantingmantras"],
      limit: SUBTOPIC_CAROUSEL_LIMIT,
    },
  },
  {
    id: "music/drumming",
    title: "Drumming",
    filter: {
      content_types: ["music"],
      topics: ["drumming"],
      genres: ["drumming"],
      limit: SUBTOPIC_CAROUSEL_LIMIT,
    },
  },
  {
    id: "music/nature-sounds",
    title: "Nature Sounds",
    filter: {
      content_types: ["music"],
      topics: ["naturesounds"],
      limit: SUBTOPIC_CAROUSEL_LIMIT,
    },
  },
  {
    id: "music/instrumental-music",
    title: "Instrumental Music",
    filter: {
      content_types: ["music"],
      topics: ["instrumentalmusic"],
      limit: SUBTOPIC_CAROUSEL_LIMIT,
    },
  },
  {
    id: "music/binaural-beats",
    title: "Binaural Beats",
    filter: {
      content_types: ["music"],
      topics: ["binauralbeats"],
      limit: SUBTOPIC_CAROUSEL_LIMIT,
    },
  },
];

export const PARENTS_SUBTOPICS: SubTopic[] = [
  {
    id: "parents/sleep",
    title: "Sleep",
    filter: {
      topics: ["kids-sleep"],
      limit: SUBTOPIC_CAROUSEL_LIMIT,
    },
  },

  {
    id: "parents/stories",
    title: "Stories",
    filter: {
      topics: ["kids-sleep"],
      content_types: ["talks"],
      limit: SUBTOPIC_CAROUSEL_LIMIT,
    },
  },
];
