import AgoraRTC, {
  IAgoraRTCClient,
  IAgoraRTCRemoteUser,
  IRemoteAudioTrack,
  IRemoteVideoTrack,
} from "agora-rtc-sdk-ng";
import { useEffect, useState } from "react";

const APP_ID = process.env.REACT_APP_AGORA_APP_ID ?? "";

const useLiveStream = (liveStreamId: string) => {
  const [cam, setCam] = useState<IRemoteVideoTrack | null>(null);
  const [mic, setMic] = useState<IRemoteAudioTrack | null>(null);
  const [couldNotCreateStream, setCouldNotCreateStream] = useState(false);

  useEffect(() => {
    let rtc: IAgoraRTCClient;

    async function initAgoraStream() {
      try {
        rtc = AgoraRTC.createClient({ mode: "live", codec: "vp8" });
        rtc.setClientRole("audience");

        rtc.on("user-published", async (publishedUser, mediaType) => {
          await rtc.subscribe(publishedUser, mediaType);
          await rtc.setStreamFallbackOption(publishedUser.uid, 1); // LOW_STREAM
          handlePublishEvent(publishedUser, mediaType, true);
        });

        rtc.on("user-unpublished", async (unpublishedUser, mediaType) => {
          handlePublishEvent(unpublishedUser, mediaType, false);
        });

        await rtc.join(APP_ID, liveStreamId, null, null);
      } catch (error) {
        console.error("Could not create Agora stream.");
        console.error(error);
        setCouldNotCreateStream(true);
      }
    }

    initAgoraStream();

    return () => {
      rtc.leave();
    };
  }, [liveStreamId]);

  function handlePublishEvent(
    rtcUser: IAgoraRTCRemoteUser,
    mediaType: "audio" | "video",
    isPublish: boolean
  ) {
    if (mediaType === "video") {
      setCam(isPublish ? rtcUser.videoTrack ?? null : null);
    } else {
      setMic(isPublish ? rtcUser.audioTrack ?? null : null);
    }
  }

  return { cam, mic, couldNotCreateStream };
};

export default useLiveStream;
