import { Unsubscribe } from "firebase/auth";
import React, { useCallback, useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import useSWR from "swr";
import {
  getLiveEvent,
  getLiveOccurenceStatus,
  LIVE_EVENTS_REFRESH_INTERVAL,
  subscribeEventOccurence,
} from "../common/api/LiveEvents";
import { LiveStreamStatus } from "../common/types/Event";
import LiveStreamPlayer from "../components/Player/LiveStreamPlayer";
import FullPageSpinner from "../components/Spinner/FullPageSpinner";
import useLiveStream from "../hooks/useLiveStream";
import LiveEventLobby from "./LiveEventLobby";
import LiveEventVideoLoading from "../components/Spinner/LiveEventVideoLoading";

const LiveEventEntry = () => {
  const params = useParams();
  const eventId = params.id;

  if (!eventId) {
    return <FullPageSpinner />;
  }

  return <LiveEventPageNavigator eventId={eventId} />;
};

interface Props {
  eventId: string;
}

const LiveEventPageNavigator = ({ eventId }: Props) => {
  const [, setSearchParams] = useSearchParams();
  const [streamStatus, setStreamStatus] = useState<LiveStreamStatus>("UNKNOWN");
  const { data: event } = useSWR(
    `/api/event/${eventId}`,
    () => getLiveEvent(eventId),
    {
      refreshInterval: LIVE_EVENTS_REFRESH_INTERVAL,
    }
  );
  const { cam } = useLiveStream(event?._next_occurrences[0]?.id ?? "");

  const handleEventEnded = useCallback(() => {
    setStreamStatus("ENDED");
  }, []);

  useEffect(() => {
    let unsubscribe: Unsubscribe;

    if (event) {
      unsubscribe = subscribeEventOccurence(
        event.id,
        event._next_occurrences[0].id,
        (occurrence) => {
          const status = getLiveOccurenceStatus(occurrence);
          setStreamStatus(status);

          // Show sidebar again when stream has ended
          if (status === "ENDED") {
            setSearchParams({}, { replace: true });
          }
        }
      );
    }

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [event, handleEventEnded, setSearchParams]);

  if (!event || streamStatus === "UNKNOWN") {
    return <FullPageSpinner />;
  }

  if (streamStatus === "ENDED") {
    return <LiveEventLobby event={event} hasEnded />;
  }

  if (streamStatus === "UPCOMING") {
    return <LiveEventLobby event={event} hasEnded={false} />;
  }

  if (cam === null) return <LiveEventVideoLoading />;

  return <LiveStreamPlayer event={event} onEventEnded={handleEventEnded} />;
};

export default LiveEventEntry;
