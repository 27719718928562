import { useEffect } from "react";
import { handleKeyPress } from "../common/utils/keyboard";

interface Props {
  onLeftArrow?: () => void;
  onRightArrow?: () => void;
  onUpArrow?: () => void;
  onDownArrow?: () => void;
  onEnter?: () => void;
  onBackspace?: () => void;
}

const useKeyboardNavigation = (
  isFocused: boolean,
  {
    onLeftArrow,
    onRightArrow,
    onUpArrow,
    onDownArrow,
    onBackspace,
    onEnter,
  }: Props
) => {
  useEffect(() => {
    const listener = (event: KeyboardEvent) => {
      handleKeyPress(event, {
        onRightArrow,
        onLeftArrow,
        onUpArrow,
        onDownArrow,
        onBackspace,
        onEnter,
      });
    };

    if (isFocused) {
      window.addEventListener("keydown", listener);
    } else {
      window.removeEventListener("keydown", listener);
    }

    return () => {
      window.removeEventListener("keydown", listener);
    };
  }, [
    isFocused,
    onBackspace,
    onDownArrow,
    onEnter,
    onLeftArrow,
    onRightArrow,
    onUpArrow,
  ]);
};

export default useKeyboardNavigation;
