import { Box, Flex, Heading, VStack } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { isYogaEvent } from "../../common/api/LiveEvents";
import { EventSummary } from "../../common/types/Event";
import useHideSidebarNav from "../../hooks/useHideSidebarNav";
import useKeyboardNavigation from "../../hooks/useKeyboardNavigation";
import useLiveStream from "../../hooks/useLiveStream";
import LiveEventBadge from "../Badge/LiveEventBadge";
import FullPageSpinner from "../Spinner/FullPageSpinner";
import PlayerBottomGradient from "./PlayerBottomGradient";
import PlayerMeditatorCount from "./PlayerMeditatorCount";
import YogaDisclaimer from "./YogaDisclaimer";

const VIDEO_ID = "stream-video";
const CONTAINER_ID = "stream-video-container";

interface Props {
  event: EventSummary;
  onEventEnded: () => void;
}

const LiveStreamPlayer = ({ event, onEventEnded }: Props) => {
  useHideSidebarNav();
  const { cam, mic, couldNotCreateStream } = useLiveStream(
    event._next_occurrences[0].id
  );
  const [showMeta, setShowMeta] = useState(true);
  const showYogaDisclaimer = isYogaEvent(event._next_occurrences[0]);

  useKeyboardNavigation(true, {
    onEnter: () => setShowMeta(true),
    onLeftArrow: () => setShowMeta(true),
    onRightArrow: () => setShowMeta(true),
    onUpArrow: () => setShowMeta(true),
    onDownArrow: () => setShowMeta(true),
  });

  useEffect(() => {
    if (couldNotCreateStream) {
      onEventEnded();
    }
  }, [couldNotCreateStream, onEventEnded]);

  useEffect(() => {
    if (cam) {
      cam.play(VIDEO_ID, { fit: "contain" });
    }
  }, [cam]);

  useEffect(() => {
    if (mic) {
      mic.play();
    }
  }, [mic]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowMeta(false);
    }, 7000);

    return () => clearTimeout(timer);
  }, [showMeta]);

  if (!mic && !cam) {
    return <FullPageSpinner />;
  }

  return (
    <Flex
      id={CONTAINER_ID}
      justify="flex-start"
      align="flex-end"
      w="100vw"
      h="100vh"
    >
      {showYogaDisclaimer && <YogaDisclaimer />}

      {/* This Box will become the stream video */}
      <Box
        id={VIDEO_ID}
        pos="absolute"
        width="100%"
        height="100vh"
        bg="bgGray"
        zIndex={1}
      />

      <PlayerBottomGradient isVisible={showMeta} />

      <VStack
        align="flex-start"
        zIndex={10}
        mb={20}
        ml={20}
        opacity={showMeta ? 1 : 0}
        transition="0.3s"
      >
        <LiveEventBadge event={event} />
        <Heading size="lg" mb={3} textShadow="0px 4px 54px rgba(0, 0, 0, 0.6)">
          {event.title}
        </Heading>
        <Heading
          size="sm"
          mb={16}
          pb={8}
          fontWeight={400}
          textShadow="0px 4px 54px rgba(0, 0, 0, 0.6)"
        >
          {event.owner.name}
        </Heading>

        <PlayerMeditatorCount itemId={event.id} borderColor="white" />
      </VStack>
    </Flex>
  );
};

export default LiveStreamPlayer;
