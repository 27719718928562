import { Box, Flex, Heading } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player/lazy";
import { useNavigate, useParams } from "react-router-dom";
import { Focusable } from "react-sunbeam";
import useSWR from "swr";
import { getLibraryItem } from "../common/api/LibraryItems";
import { TrackingEventName } from "../common/types/Tracking";
import { getLibraryItemImageUrl } from "../common/utils/helpers";
import LibraryItemPlayer from "../components/Player/LibraryItemPlayer";
import LibraryItemPlayerControl from "../components/Player/LibraryItemPlayerControl";
import PauseIcon from "../components/Player/PauseIcon";
import PlayerBottomGradient from "../components/Player/PlayerBottomGradient";
import PlayerDuration from "../components/Player/PlayerDuration";
import PlayerMeditatorCount from "../components/Player/PlayerMeditatorCount";
import FullPageSpinner from "../components/Spinner/FullPageSpinner";
import { useAnalytics } from "../hooks/useAnalytics";
import useHideSidebarNav from "../hooks/useHideSidebarNav";
import useKeyboardNavigation from "../hooks/useKeyboardNavigation";
import useRecentlyPlayed from "../hooks/useRecentlyPlayed";

const Player = () => {
  useHideSidebarNav();
  const { storeRecentlyPlayed } = useRecentlyPlayed();
  const [isPlaying, setIsPlaying] = useState(true);
  const [showPlayerControls, setShowPlayerControls] = useState(true);
  const [isSeeking, setIsSeeking] = useState(false);
  const playerRef = useRef<ReactPlayer>(null);
  const params = useParams();
  const navigate = useNavigate();
  const { trackClick } = useAnalytics();
  const libraryItemId = params.id;
  const imageUrl = getLibraryItemImageUrl(
    libraryItemId ?? "",
    "xlarge",
    "rectangle"
  );

  const { data: libraryItem, isValidating } = useSWR(
    `/api/libraryItem/${libraryItemId}`,
    () => getLibraryItem(libraryItemId),
    {
      revalidateOnReconnect: false,
    }
  );

  useKeyboardNavigation(true, {
    onEnter: () => {
      trackClick({
        click_event_name: TrackingEventName.pausePlayButtonClicked,
        library_item_id: libraryItemId,
        library_item_type: libraryItem?.content_type,
      });
      togglePlayState();
    },
    onLeftArrow: () => {
      setIsSeeking(true);
      trackClick({
        click_event_name: TrackingEventName.seekRewindClicked,
        library_item_id: libraryItemId,
        library_item_type: libraryItem?.content_type,
      });
      playerRef.current?.seekTo(playerRef.current.getCurrentTime() - 15);
    },
    onRightArrow: () => {
      setIsSeeking(true);
      trackClick({
        click_event_name: TrackingEventName.seekForwardClicked,
        library_item_id: libraryItemId,
        library_item_type: libraryItem?.content_type,
      });
      playerRef.current?.seekTo(playerRef.current.getCurrentTime() + 30);
    },
    onDownArrow: () => {
      setIsSeeking(true);
    },
  });

  useEffect(() => {
    setShowPlayerControls(true);

    const timer = setTimeout(() => {
      setIsSeeking(false);
      setShowPlayerControls(false);
    }, 4000);

    return () => clearTimeout(timer);
  }, [isSeeking]);

  useEffect(() => {
    if (libraryItem) storeRecentlyPlayed(libraryItem);
  }, [libraryItem, storeRecentlyPlayed]);

  if (isValidating || !libraryItem) return <FullPageSpinner />;

  function onTrackEnded() {
    navigate(`/track/${libraryItemId}`);
  }

  function togglePlayState() {
    setIsPlaying((prev) => !prev);
  }

  function showControls() {
    setShowPlayerControls(true);
  }

  function hideControls() {
    setShowPlayerControls(false);
  }

  function onPlayerPause() {
    setIsPlaying(false);
  }

  function onPlayerPlay() {
    setIsPlaying(true);
  }

  return (
    <Focusable>
      <LibraryItemPlayer
        playerRef={playerRef}
        libraryItem={libraryItem}
        isPlaying={isPlaying}
        onEnded={onTrackEnded}
        onPause={onPlayerPause}
        onPlay={onPlayerPlay}
      />

      {/* Click to Pause overlay */}
      <Box
        pos="absolute"
        h="100vh"
        w="100vw"
        zIndex="5"
        bg="none"
        onClick={togglePlayState}
      />

      <PauseIcon isPlaying={isPlaying} />

      <PlayerBottomGradient isVisible />

      <Flex
        flexDir="column"
        justify="flex-end"
        width="100vw"
        height="100vh"
        backgroundImage={`url(${imageUrl})`}
        backgroundSize="cover"
        backgroundPosition="center"
        padding="80px"
      >
        <Box
          w="100%"
          zIndex={10}
          onMouseEnter={showControls}
          onMouseLeave={hideControls}
        >
          <Box
            opacity={showPlayerControls || !isPlaying ? 1 : 0}
            pointerEvents={showPlayerControls || !isPlaying ? "auto" : "none"}
            transition="0.3s"
          >
            <Heading size="lg" marginBottom={3}>
              {libraryItem.title}
            </Heading>

            <Heading fontSize={30} marginBottom={12} fontWeight={500}>
              {libraryItem.publisher.name}
            </Heading>

            <LibraryItemPlayerControl
              playerRef={playerRef}
              libraryItemMediaLength={libraryItem.media_length}
              isSeeking={isSeeking}
              isShowControl={showPlayerControls}
            />
          </Box>

          <Flex justify="space-between" align="flex-end">
            <PlayerMeditatorCount itemId={libraryItem.id} />

            <PlayerDuration
              playerRef={playerRef}
              libraryItemMediaLength={libraryItem.media_length}
            />
          </Flex>
        </Box>
      </Flex>
    </Focusable>
  );
};

export default Player;
