import { Box, Flex, Heading, Text, VStack } from "@chakra-ui/react";
import React, { useRef } from "react";
import { Focusable } from "react-sunbeam";
import { LibraryItemSummary } from "../../common/types/Item";
import {
  getLibraryItemImageUrl,
  SIDEBAR_WIDTH_PX,
} from "../../common/utils/helpers";
import useScrollIntoView from "../../hooks/useScrollIntoView";
import HeroBackgroundImage from "../Background/HeroBackgroundImage";

interface Props {
  item: LibraryItemSummary;
  preHeadingElements?: React.ReactNode;
  heroButton?: React.ReactNode;
}

const TrackOverviewHeroSection = ({
  item,
  preHeadingElements,
  heroButton,
}: Props) => {
  const heroRef = useRef<HTMLDivElement>(null);
  const { scrollIntoView } = useScrollIntoView(heroRef);
  const imageUrl = getLibraryItemImageUrl(item.id, "large", "square");
  const titleIsLong = item.title.length > 50;

  return (
    <Focusable onFocus={scrollIntoView}>
      <Box
        pos="relative"
        width="100%"
        height="88vh"
        overflow="hidden"
        ref={heroRef}
      >
        <HeroBackgroundImage imageUrl={imageUrl} />

        <Flex w="inherit" h="inherit" flexDir="column" justify="center" pt={12}>
          <VStack
            justify="flex-start"
            align="flex-start"
            maxW="40%"
            ml={SIDEBAR_WIDTH_PX}
            spacing="22px"
            zIndex={5}
          >
            {preHeadingElements}

            <Heading
              size={titleIsLong ? "lg" : "xxl"}
              noOfLines={3}
              textTransform="uppercase"
            >
              {item.title}
            </Heading>

            <Heading size="sm">with {item.publisher.name}</Heading>

            <Text fontSize="slg" noOfLines={4}>
              {item.short_description}
            </Text>

            <Box height="1rem" />

            {heroButton}
          </VStack>
        </Flex>
      </Box>
    </Focusable>
  );
};

export default TrackOverviewHeroSection;
