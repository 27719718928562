import { Box, Flex } from "@chakra-ui/react";
import React, { useState } from "react";
import { Focusable } from "react-sunbeam";
import { focusManager } from "../common/global/focusManager";
import { SIDEBAR_WIDTH_PX } from "../common/utils/helpers";
import Keyboard from "../components/Search/Keyboard";
import SearchBar from "../components/Search/SearchBar";
import SearchResults from "../components/Section/SearchResults";
import useKeyboardNavigation from "../hooks/useKeyboardNavigation";
import { SearchQueryProvider } from "../hooks/useSearchQuery";

const Search = () => {
  const [isFocused, setIsFocused] = useState(false);

  useKeyboardNavigation(isFocused, {
    onLeftArrow: () => focusManager.moveLeft(),
    onRightArrow: () => focusManager.moveRight(),
  });

  function handleOnFocus() {
    setIsFocused(true);
  }

  function handleOnBlur() {
    setIsFocused(false);
  }

  return (
    <Box minH="100vh">
      <Focusable onFocus={handleOnFocus} onBlur={handleOnBlur}>
        <SearchQueryProvider>
          <SearchBar />

          <Flex ml={SIDEBAR_WIDTH_PX} justify="space-evenly">
            <Keyboard />
            <SearchResults />
          </Flex>
        </SearchQueryProvider>
      </Focusable>
    </Box>
  );
};

export default Search;
