import { Box, Flex } from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Focusable } from "react-sunbeam";
import { SwiperSlide } from "swiper/react/swiper-react";
import { RouteList } from "../../common/routes/router";
import { EventSummary } from "../../common/types/Event";
import { TrackingEventName } from "../../common/types/Tracking";
import { useAnalytics } from "../../hooks/useAnalytics";
import CarouselLoadingSpinner from "../Spinner/CarouselLoadingSpinner";
import LiveEventTile from "../Tiles/LiveEventTile";
import Carousel from "./Carousel";
import CarouselHeading from "./CarouselHeading";

interface Props {
  focusKey: string;
  title: string;
  isLoading: boolean;
  events: EventSummary[];
  onFocus: () => void;
}

const LiveEventsCarousel: React.FC<Props> = ({
  focusKey,
  title,
  events,
  isLoading,
  onFocus,
}) => {
  const navigate = useNavigate();
  const { trackClick } = useAnalytics();

  function handleEnterLiveEvent(index: number) {
    const event = events[index];

    if (event) {
      trackClick({
        click_event_name: TrackingEventName.liveTileClicked,
        live_event_id: event.id,
      });
      navigate(`/${RouteList.Live}/${event.id}/play`);
    }
  }

  return (
    <Focusable focusKey={focusKey} onFocus={onFocus}>
      {({ focused }) => {
        return (
          <Flex flexDir="column" py={8}>
            <CarouselHeading content={title} />

            {isLoading || events == null ? (
              <CarouselLoadingSpinner />
            ) : (
              <Box>
                <Carousel
                  isFocused={focused}
                  numberOfSlides={events.length}
                  spaceBetween={20}
                  onEnterItem={handleEnterLiveEvent}
                >
                  {events.map((event) => {
                    return (
                      <SwiperSlide key={event.id} style={{ width: "auto" }}>
                        {({ isActive }) => {
                          return (
                            <LiveEventTile
                              event={event}
                              isActive={focused && isActive}
                            />
                          );
                        }}
                      </SwiperSlide>
                    );
                  })}
                </Carousel>
              </Box>
            )}
          </Flex>
        );
      }}
    </Focusable>
  );
};

export default LiveEventsCarousel;
