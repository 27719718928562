import { Box } from "@chakra-ui/react";
import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Focusable } from "react-sunbeam";
import { FocusKey, focusManager } from "../../common/global/focusManager";
import { RouteList } from "../../common/routes/router";
import { EventSummary } from "../../common/types/Event";
import { TrackingEventName } from "../../common/types/Tracking";
import { handleKeyPress } from "../../common/utils/keyboard";
import { useAnalytics } from "../../hooks/useAnalytics";
import { useFocusKey } from "../../hooks/useFocusKey";
import useScrollIntoView from "../../hooks/useScrollIntoView";
import LiveEventTile from "./LiveEventTile";

interface Props {
  event: EventSummary;
}

const LiveListTile = ({ event }: Props) => {
  const navigate = useNavigate();
  const { trackClick } = useAnalytics();
  const tileRef = useRef<HTMLDivElement>(null);
  const { scrollIntoView } = useScrollIntoView(tileRef, 200);
  const { activeNavItem } = useFocusKey();

  function onKeyPress(keyboardEvent: KeyboardEvent) {
    handleKeyPress(keyboardEvent, {
      onLeftArrow: () =>
        focusManager.setFocus([FocusKey.Navigation, activeNavItem.focusKey]),
      onEnter: () => {
        trackClick({
          click_event_name: TrackingEventName.liveTileClicked,
          live_event_id: event.id,
        });
        navigate(`/${RouteList.Live}/${event.id}/play`);
      },
    });
  }

  return (
    <Focusable
      focusKey={`LIVE-EVENT-TILE-${event.id}`}
      onFocus={scrollIntoView}
      onKeyPress={onKeyPress}
    >
      {({ focused }) => {
        return (
          <Box mb="30px" ref={tileRef} pl={4}>
            <LiveEventTile event={event} isActive={focused} size="lg" />
          </Box>
        );
      }}
    </Focusable>
  );
};

export default LiveListTile;
