import { logEvent } from "firebase/analytics";
import React, { createContext, useContext, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { ClickEventAttributes } from "../common/types/Tracking";
import { analytics } from "../common/utils/firebase";

interface Context {
  trackClick: (eventAttributes: ClickEventAttributes) => void;
  viewImpression: () => void;
}

const AnalyticsContext = createContext<Context>({
  trackClick: () => null,
  viewImpression: () => null,
});

export const AnalyticsProvider: React.FC = ({ children }) => {
  const location = useLocation();
  const currentRoute = useRef(location.pathname);
  const previousRoute = useRef(location.pathname);

  useEffect(() => {
    logEvent(analytics, "page_view", {
      event_name: "page_view",
      name: "page_view",
      page_name: location.pathname,
      previous_page_name: currentRoute.current,
      user_agent: navigator.userAgent,
    });

    previousRoute.current = currentRoute.current;
    currentRoute.current = location.pathname;
  }, [location]);

  function trackClick(eventAttributes: ClickEventAttributes) {
    const trackingData = {
      event_name: "click",
      page_name: location.pathname,
      previous_page_name: previousRoute.current,
      user_agent: navigator.userAgent,
      ...eventAttributes,
    };

    logEvent(analytics, "click", trackingData);
  }

  function viewImpression() {
    const trackingData = {
      event_name: "view",
      view_event_name: "lgtv_memberplus_qr_code",
      page_name: location.pathname,
      previous_page_name: previousRoute.current,
      user_agent: navigator.userAgent,
    };

    logEvent(analytics, "view", trackingData);
  }

  return (
    <AnalyticsContext.Provider value={{ trackClick, viewImpression }}>
      {children}
    </AnalyticsContext.Provider>
  );
};

export const useAnalytics = () => {
  const context = useContext(AnalyticsContext);
  return context;
};
