import React, { createContext, useContext, useEffect, useState } from "react";
import { handleKeyPress } from "../common/utils/keyboard";

interface Context {
  isCursorVisible: boolean;
}

const CursorContext = createContext<Context>({
  isCursorVisible: false,
});

export const CursorProvider: React.FC = ({ children }) => {
  const [isCursorVisible, setIsCursorVisible] = useState(false);

  // if the key is press the set isCursorVisible to false
  useEffect(() => {
    const onKeyPress = () => {
      setIsCursorVisible(false);
    };
    document.addEventListener("keyup", (event) => {
      handleKeyPress(event, {
        onLeftArrow: onKeyPress,
        onRightArrow: onKeyPress,
        onUpArrow: onKeyPress,
        onDownArrow: onKeyPress,
        onEnter: onKeyPress,
      });
    });

    return () => {
      document.removeEventListener("keyup", onKeyPress);
    };
  }, []);

  // if the cursor is moving set isCursorVisible to true
  useEffect(() => {
    const onMouseMove = () => {
      if (!isCursorVisible) setIsCursorVisible(true);
    };

    document.addEventListener("mousemove", onMouseMove);

    return () => {
      document.removeEventListener("mousemove", onMouseMove);
    };
  }, [isCursorVisible]);

  // if user use mouse wheel set isCursorVisible to false
  useEffect(() => {
    const onMouseWheel = () => {
      if (isCursorVisible) setIsCursorVisible(false);
    };

    document.addEventListener("wheel", onMouseWheel);

    return () => {
      document.removeEventListener("wheel", onMouseWheel);
    };
  }, [isCursorVisible]);

  return (
    <CursorContext.Provider value={{ isCursorVisible }}>
      {children}
    </CursorContext.Provider>
  );
};

export const useCursor = () => {
  const context = useContext(CursorContext);
  return context;
};
