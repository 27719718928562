import { Box } from "@chakra-ui/react";
import React, { memo } from "react";

interface Props {
  isActive: boolean;
  progressPc: number;
}

const LiveEventTileProgressBar = ({ isActive, progressPc }: Props) => {
  return (
    <Box
      w="100%"
      h="7px"
      bg={isActive ? "livePinkLight" : "#452E39"}
      position="absolute"
      bottom={-4}
      borderRadius={8}
    >
      <Box
        width={`${progressPc * 100 < 100 ? progressPc * 100 : 100}%`}
        height="100%"
        bg="livePink"
        borderRadius={8}
      />
    </Box>
  );
};

export default memo(LiveEventTileProgressBar);
