import { Box } from "@chakra-ui/react";
import React, { memo, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Focusable } from "react-sunbeam";
import { FocusKey, focusManager } from "../../common/global/focusManager";
import { RouteList } from "../../common/routes/router";
import { LibraryItemSummary } from "../../common/types/Item";
import { TrackingEventName } from "../../common/types/Tracking";
import { SIDEBAR_WIDTH } from "../../common/utils/helpers";
import { handleKeyPress } from "../../common/utils/keyboard";
import { useAnalytics } from "../../hooks/useAnalytics";
import useScrollIntoView from "../../hooks/useScrollIntoView";
import { SEARCH_QUERY, useSearchQuery } from "../../hooks/useSearchQuery";
import LibraryItemTile from "./LibraryItemTile";

interface Props {
  libraryItem: LibraryItemSummary;
}

const SearchResultTile: React.FC<Props> = ({ libraryItem }) => {
  const navigate = useNavigate();
  const searchResultRef = useRef<HTMLDivElement>(null);
  const { scrollIntoView } = useScrollIntoView(searchResultRef, 325);
  const { trackClick } = useAnalytics();
  const { searchQuery } = useSearchQuery();

  function onKeyPress(event: KeyboardEvent) {
    handleKeyPress(event, {
      onEnter: () => {
        trackClick({
          click_event_name: TrackingEventName.libraryItemTileClicked,
          library_item_id: libraryItem.id,
          library_item_type: libraryItem.content_type,
        });

        // This navigate is to replace current url so when we navigate back the url will be /search?query=self
        // rather than /search. With this `searchQuery we can fill the search bar and search results
        navigate(`/${RouteList.Search}?${SEARCH_QUERY}=${searchQuery}`, {
          replace: true,
        });

        navigate(`/${RouteList.Track}/${libraryItem.id}`);
      },
      onLeftArrow: () => {
        const tileLeftOffset = searchResultRef?.current?.offsetLeft;

        // A rough check to see if this is a tile in the first column.
        if (
          tileLeftOffset &&
          tileLeftOffset > SIDEBAR_WIDTH &&
          tileLeftOffset < SIDEBAR_WIDTH + 50
        ) {
          return focusManager.setFocus([FocusKey.Navigation]);
        }
      },
    });
  }

  return (
    <Box ref={searchResultRef} pb="5px" pl="5px" pr="5px">
      <Focusable
        onFocus={scrollIntoView}
        onKeyPress={onKeyPress}
        focusKey={libraryItem.id}
      >
        {({ focused }) => {
          return (
            <LibraryItemTile
              libraryItem={libraryItem}
              isActive={focused}
              size="md"
            />
          );
        }}
      </Focusable>
    </Box>
  );
};

export default memo(SearchResultTile);
