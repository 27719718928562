import { Box } from "@chakra-ui/react";
import React from "react";

const BG_IMAGE_GRADIENTS = [
  `radial-gradient(ellipse at right top, transparent 50%, transparent 50%, #151717 85%)`,
  `linear-gradient(90deg, #151717 0%, transparent 40%)`,
  `linear-gradient(0deg, #151717 0%, transparent 35%)`,
];

interface Props {
  imageUrl: string;
}

const HeroBackgroundImage = ({ imageUrl }: Props) => {
  return (
    <Box
      backgroundImage={`${BG_IMAGE_GRADIENTS.join(",")}, url(${imageUrl})`}
      bgPos="center"
      bgSize="cover"
      pos="absolute"
      right={0}
      top={0}
      h="inherit"
      w="65%"
      overflow="hidden"
      zIndex={0}
    />
  );
};

export default HeroBackgroundImage;
