import { Spinner } from "@chakra-ui/react";
import React, { memo } from "react";
import QRCode from "react-qr-code";
import useSWR from "swr";
import {
  BranchCampaign,
  getBranchUrl,
  QR_CODE_WEB_URL,
} from "../../common/utils/branch";

interface Props {
  campaign: BranchCampaign;
  size?: number;
}

const MemberPlusQRCode = ({ size = 300, campaign }: Props) => {
  const { data: qrUrl, isValidating } = useSWR("branch/qr", () =>
    getBranchUrl(QR_CODE_WEB_URL, campaign)
  );

  if (isValidating || !qrUrl) {
    return <Spinner size="xl" color="black" />;
  }

  return <QRCode value={qrUrl} size={size} />;
};

export default memo(MemberPlusQRCode);
