import { LibraryItemSummary } from "../common/types/Item";
import { getLocalStorage, setLocalStorage } from "../common/utils/localStorage";

const RECENTLY_PLAYED_KEY = "recently-played";

const useRecentlyPlayed = () => {
  function getRecentlyPlayed(): LibraryItemSummary[] {
    const localStorage = getLocalStorage(RECENTLY_PLAYED_KEY);

    return localStorage ? JSON.parse(localStorage) : [];
  }

  function storeRecentlyPlayed(libraryItem: LibraryItemSummary): void {
    const localStorage = getLocalStorage(RECENTLY_PLAYED_KEY);
    const recentlyPlayed: LibraryItemSummary[] = localStorage
      ? JSON.parse(localStorage)
      : null;

    const filteredRecentlyPlayed = recentlyPlayed
      ? recentlyPlayed.filter((item) => item.id !== libraryItem.id)
      : [];

    setLocalStorage(
      RECENTLY_PLAYED_KEY,
      JSON.stringify([libraryItem, ...filteredRecentlyPlayed])
    );
  }

  return { recentlyPlayed: getRecentlyPlayed(), storeRecentlyPlayed };
};

export default useRecentlyPlayed;
