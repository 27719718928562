// eslint-disable-next-line
export enum TrackingEventName {
  // Navigation
  homeMenuClicked = "home_menu_clicked",
  searchMenuClicked = "search_menu_clicked",
  liveMenuClicked = "live_menu_clicked",
  coursesMenuClicked = "course_menu_clicked",
  sleepMenuClicked = "sleep_menu_clicked",
  musicMenuClicked = "music_menu_clicked",
  parentsMenuClicked = "parents_menu_clicked",

  // Tile
  libraryItemTileClicked = "library_item_tile_clicked",
  courseTileClicked = "course_tile_clicked",
  liveTileClicked = "live_tile_clicked",
  timeTileClicked = "time_tile_clicked",
  topicTileClicked = "topic_tile_clicked",

  // Track Overview
  playTrackButtonClicked = "play_track_button_clicked",

  // Track Player
  pausePlayButtonClicked = "pause_play_button_clicked",
  seekRewindClicked = "seek_rewind_clicked",
  seekForwardClicked = "seek_forward_clicked",

  // Course Overview
  startCourseButtonClicked = "start_course_button_clicked",

  // Courses
  unlockCoursesButtonClicked = "unlock_courses_button_clicked",

  // Time Filter
  topicFilterClicked = "topic_filter_clicked",

  // Live
  liveHeroSlideClicked = "live_hero_slide_clicked",
}

export interface ClickEventAttributes {
  click_event_name?: TrackingEventName;
  library_item_id?: string;
  library_item_type?: string;
  live_event_id?: string;
  course_id?: string;
  time_id?: string;
  topic_id?: string;
}
