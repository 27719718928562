import {
  Box,
  Flex,
  Heading,
  Image,
  ModalBody,
  Text,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { Focusable } from "react-sunbeam";
import MemberPlusImage from "../../assets/member-plus.svg";
import { LibraryItemSummary } from "../../common/types/Item";
import { BranchCampaign } from "../../common/utils/branch";
import MemberPlusQRCode from "../MemberPlusAd/MemberPlusQRCode";
import CourseQRModalImage from "./CourseQRModalImage";
import PartnershipLogo from "./PartnershipLogo";

interface Props {
  onClose: () => void;
  course: LibraryItemSummary;
  campaign: BranchCampaign;
}

const CourseOverviewModalContent = ({ onClose, course, campaign }: Props) => {
  return (
    <Focusable onKeyPress={onClose} style={{ height: "100%" }}>
      <ModalBody h="100%" pb={0}>
        <Box position="absolute" top="60px" right="60px">
          <PartnershipLogo />
        </Box>

        <Flex h="100%" justify="center" align="center">
          <Flex
            flex={2}
            flexDir="column"
            align="center"
            justify="center"
            h="100%"
          >
            <VStack maxW="75%" justify="center" spacing={6}>
              <Image src={MemberPlusImage} />

              <Heading
                size="lg"
                fontSize={70}
                textAlign="center"
                fontWeight="bold"
                lineHeight="72px"
              >
                Scan the QR code to unlock this course
              </Heading>

              <Text
                textAlign="center"
                maxW="80%"
                fontSize="28px"
                lineHeight="42px"
              >
                Get{" "}
                <strong style={{ color: "white" }}>1 year free Premium</strong>{" "}
                and unlock the full experience on the worlds most loved
                meditation app
              </Text>

              <Box height="5px" />

              <Flex
                align="center"
                justify="center"
                my={8}
                borderWidth={8}
                borderColor="courseYellow"
                borderRadius="40px"
                boxShadow="0px 0px 75px black"
                background="white"
                width="250px"
                height="250px"
              >
                <MemberPlusQRCode size={185} campaign={campaign} />
              </Flex>

              <Text fontSize="sm">Courses must be accessed via the app</Text>
            </VStack>
          </Flex>

          <Flex flex={1} align="flex-end" justify="center" h="100%" pr={8}>
            <CourseQRModalImage course={course} />
          </Flex>
        </Flex>
      </ModalBody>
    </Focusable>
  );
};

export default CourseOverviewModalContent;
