import { Box, Flex, Heading, Image } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import IconSearch from "../../assets/icons/icon-search.svg";
import { SIDEBAR_WIDTH_PX } from "../../common/utils/helpers";
import { SEARCH_QUERY, useSearchQuery } from "../../hooks/useSearchQuery";

const SearchBar = () => {
  const [searchParams] = useSearchParams();
  const { searchQuery, setSearchQuery } = useSearchQuery();

  useEffect(() => {
    const query = searchParams.get(SEARCH_QUERY);
    if (query) setSearchQuery(query);
  }, [searchParams, setSearchQuery]);

  return (
    <Flex
      position="sticky"
      top={0}
      align="center"
      ml={SIDEBAR_WIDTH_PX}
      pt="6rem"
      pb="60px"
      bg={`linear-gradient(180deg, #151717 ${
        searchQuery.length > 0 ? "0%" : "90%"
      }, transparent 100%)`}
      zIndex={20}
    >
      <Box
        position="absolute"
        height="400%"
        background="linear-gradient(180deg, #151717 55%, rgba(21, 23, 23, 0) 100%);"
        width="100%"
      />
      <Image src={IconSearch} w={20} h={20} zIndex={1} />
      <Heading
        size="xl"
        opacity={searchQuery ? "1" : "0.2"}
        fontWeight={700}
        textTransform="unset"
        zIndex={1}
      >
        {searchQuery || "Tracks, courses or teachers"}
      </Heading>
    </Flex>
  );
};

export default SearchBar;
