import { LibraryItemSummary } from "./Item";

export interface FeaturedItem {
  item_summary: {
    library_item_summary?: LibraryItemSummary;
    type: "PLAYLISTS" | "LIBRARY_ITEMS";
  };
  metadata: {
    description?: string;
  };
}

// eslint-disable-next-line
export enum FeaturedListEntryPoint {
  Guided = "guided_shopfront",
  TopTrack = "web_top_tracks",
  StaffPick = "new_today_staff_picks",
  Sleep = "sleep_shopfront",
  Music = "music_shopfront",
  Talks = "talks_shopfront",
  Kids = "kids_shopfront",
  Courses = "courses_shopfront",
  CoursesMisc1 = "courses_misc_1",
  CoursesMisc2 = "courses_misc_2",
  CoursesMisc3 = "courses_misc_3",
  PopularThisWeek = "learn_popular_this_week",
  Anxiety = "anxiety_shopfront",
  Beginners = "learn_to_meditate",
}
