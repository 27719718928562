import { Flex, Spinner } from "@chakra-ui/react";
import React from "react";

const CarouselLoadingSpinner = () => {
  return (
    <Flex justify="center" align="center" h="350px">
      <Spinner size="lg" />
    </Flex>
  );
};

export default CarouselLoadingSpinner;
