import { Box, Flex, Heading, HStack, Image } from "@chakra-ui/react";
import React, { memo } from "react";
import StarIcon from "../../assets/icons/icon-star.svg";
import { LibraryItemSummary } from "../../common/types/Item";
import { mediaLengthString } from "../../common/utils/helpers";

interface Props {
  libraryItem: LibraryItemSummary;
}

const TrackMeta = ({ libraryItem }: Props) => {
  const trackLength = mediaLengthString(libraryItem.media_length ?? 0);

  return (
    <HStack spacing={4} ml={1}>
      {libraryItem.rating_score && (
        <>
          <Flex>
            <Image src={StarIcon} mr={2} />
            <Heading size="xs" fontWeight={600} color="#E6E8E8">
              {libraryItem.rating_score}
            </Heading>
          </Flex>

          <Box h="6px" w="6px" bg="white" borderRadius={12} />
        </>
      )}

      <Heading size="xs" fontWeight={600} color="#E6E8E8">
        {libraryItem.content_type}
      </Heading>

      <Box h="6px" w="6px" bg="white" borderRadius={12} />

      <Heading size="xs" fontWeight={600} color="#E6E8E8">
        {trackLength}
      </Heading>
    </HStack>
  );
};

export default memo(TrackMeta);
