export function getLocalStorage(key: string): string | null {
  if (typeof window === "undefined") {
    return null;
  }

  try {
    return window.localStorage.getItem(key);
  } catch (error) {
    console.error(`Error reading localStorage key “${key}”:`, error);
    return null;
  }
}

export function setLocalStorage(key: string, value: string): void {
  if (typeof window === "undefined") {
    console.error(
      `Tried setting localStorage key “${key}” even though environment is not a client`
    );
  }

  try {
    window.localStorage.setItem(key, value);
  } catch (error) {
    console.error(`Error setting localStorage key “${key}”:`, error);
  }
}
