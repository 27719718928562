import { Button, GridItem, Heading, Image } from "@chakra-ui/react";
import React, { memo } from "react";
import { Focusable } from "react-sunbeam";
import { FocusKey, focusManager } from "../../common/global/focusManager";
import { handleKeyPress } from "../../common/utils/keyboard";
import { useCursor } from "../../hooks/useCursor";

const CHARS_TO_GO_NAVBAR = ["space", "a", "g", "m", "s", "y", "5"];

interface Props {
  character: string;
  image?: string;
  colSpan?: number;
  onKeyPress: (character: string) => void;
}

const KeyboardButton = ({
  character,
  image,
  colSpan = 1,
  onKeyPress,
}: Props) => {
  const { isCursorVisible } = useCursor();

  function onEnter(event: KeyboardEvent) {
    handleKeyPress(event, {
      onEnter: () => onKeyPress(character),
      onLeftArrow: () => {
        if (CHARS_TO_GO_NAVBAR.includes(character)) {
          return focusManager.setFocus([
            FocusKey.Navigation,
            FocusKey.NavigationSearch,
          ]);
        }
      },
    });
  }

  function handleOnClick() {
    onKeyPress(character);
  }

  return (
    <GridItem colSpan={colSpan} h="70px">
      <Focusable style={{ width: "100%", height: "100%" }} onKeyPress={onEnter}>
        {({ focused }) => {
          const isSelected = !isCursorVisible && focused;

          return (
            <Button
              background="bgGrayMedium"
              borderRadius="6px"
              width="100%"
              height="100%"
              borderWidth="3px"
              borderColor={isSelected ? "white" : "bgGray"}
              _hover={{
                background: "bgGrayMedium",
                borderColor:
                  isCursorVisible || focused ? "white" : "bgGrayMedium",
              }}
              onClick={handleOnClick}
            >
              <Heading fontSize="xl">
                {image ? <Image src={image} /> : character}
              </Heading>
            </Button>
          );
        }}
      </Focusable>
    </GridItem>
  );
};

export default memo(KeyboardButton);
