import { ChakraProvider } from "@chakra-ui/react";
import "focus-visible/dist/focus-visible";
import React from "react";
import { HashRouter } from "react-router-dom";
import { SunbeamProvider } from "react-sunbeam";
import "swiper/swiper.min.css";
import { SWRConfig } from "swr";
import InsightTV from "./InsightTV";
import Fonts from "./common/global/Fonts";
import { focusManager } from "./common/global/focusManager";
import theme from "./common/global/theme";
import * as Branch from "./common/utils/branch";
import SplashScreen from "./components/SplashScreen/SplashScreen";
import { AnalyticsProvider } from "./hooks/useAnalytics";
import { CursorProvider } from "./hooks/useCursor";
import { FocusKeyProvider } from "./hooks/useFocusKey";

Branch.branchInit((branchData) => {
  Branch.saveUTMs(window.location, branchData);
});

const App = () => {
  return (
    <React.StrictMode>
      <HashRouter>
        <SWRConfig
          value={{
            revalidateOnFocus: false,
          }}
        >
          <CursorProvider>
            <AnalyticsProvider>
              <ChakraProvider theme={theme}>
                <FocusKeyProvider>
                  <Fonts />
                  <SunbeamProvider focusManager={focusManager}>
                    <InsightTV />
                  </SunbeamProvider>

                  {process.env.NODE_ENV === "production" && <SplashScreen />}
                </FocusKeyProvider>
              </ChakraProvider>
            </AnalyticsProvider>
          </CursorProvider>
        </SWRConfig>
      </HashRouter>
    </React.StrictMode>
  );
};

export default App;
