import { Flex, Image } from "@chakra-ui/react";
import { css, keyframes } from "@emotion/react";
import React, { useState } from "react";
import InsightLogo from "../../assets/images/insight-logo.svg";

const fadeOutAnimation = keyframes`
  0% {
    opacity: 1;
  },
  75% {
    opacity: 1;
  },
  100% {
    opacity: 0;
  }
`;

const ANIMATION_LENGTH = "7s" as const;

const SplashScreen = () => {
  const [hideSplash, setHideSplash] = useState(false);

  function handleHideSplash() {
    setHideSplash(true);
  }

  if (hideSplash) return null;

  return (
    <Flex
      h="100vh"
      w="100vw"
      position="fixed"
      align="center"
      justify="center"
      zIndex={30}
      top="0"
      bg="bgGray"
      pointerEvents="none"
      css={css`
        animation: ${fadeOutAnimation} ${ANIMATION_LENGTH} ease;
      `}
      onAnimationEnd={handleHideSplash}
    >
      <Image src={InsightLogo} w="300px" />
    </Flex>
  );
};

export default SplashScreen;
