import { Box, Text, useDisclosure } from "@chakra-ui/react";
import React from "react";
import { useParams } from "react-router-dom";
import { Focusable } from "react-sunbeam";
import useSWR from "swr";
import LockIcon from "../assets/icons/icon-lock.svg";
import { getCourse } from "../common/api/LibraryItems";
import { FocusKey, focusManager } from "../common/global/focusManager";
import { TrackingEventName } from "../common/types/Tracking";
import { BranchCampaign } from "../common/utils/branch";
import { handleKeyPress } from "../common/utils/keyboard";
import HeroButton from "../components/Button/HeroButton";
import CourseDayCarousel from "../components/Carousel/CourseDayCarousel";
import CourseInfo from "../components/Course/CourseInfo";
import CoursePublisher from "../components/Course/CoursePublisher";
import CourseQRModal from "../components/CourseQRModal/CouseQRModal";
import MemberPlusAd from "../components/MemberPlusAd/MemberPlusAd";
import CourseMeta from "../components/Meta/CourseMeta";
import TrackOverviewHeroSection from "../components/Section/TrackOverviewHeroSection";
import FullPageSpinner from "../components/Spinner/FullPageSpinner";
import { useAnalytics } from "../hooks/useAnalytics";
import { useFocusKey } from "../hooks/useFocusKey";

const CourseOverview = () => {
  const { trackClick, viewImpression } = useAnalytics();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { modalIsOpen } = useFocusKey();
  const params = useParams();
  const courseId = params.id;

  const { activeNavItem } = useFocusKey();

  const { data: course } = useSWR(`api/libraryItem/${courseId}`, () => {
    if (courseId) return getCourse(courseId);
  });

  function onKeyPress(event: KeyboardEvent) {
    handleKeyPress(event, {
      onEnter: () => {
        trackClick({
          click_event_name: TrackingEventName.startCourseButtonClicked,
          course_id: courseId,
        });
        viewImpression();
        onOpen();
      },
      onLeftArrow: () => {
        if (!modalIsOpen) {
          focusManager.setFocus([FocusKey.Navigation, activeNavItem.focusKey]);
        }
      },
    });
  }

  if (!course) return <FullPageSpinner />;

  return (
    <Box>
      <TrackOverviewHeroSection
        item={course}
        preHeadingElements={<CourseMeta course={course} />}
        heroButton={
          <Focusable onKeyPress={onKeyPress}>
            {({ focused }) => {
              return (
                <>
                  <HeroButton
                    isFocused={focused}
                    icon={LockIcon}
                    bg={focused ? "courseYellow" : "buttonUnfocused"}
                    color="white"
                    iconFilter="invert(100%)"
                    onClick={onOpen}
                  >
                    Start Day 1 of Course
                  </HeroButton>

                  <Box height={4} />

                  {course.number_of_students && (
                    <Text fontSize="20px">
                      <span style={{ color: "white" }}>
                        {course.number_of_students.toLocaleString()}
                      </span>{" "}
                      happy students completed this course
                    </Text>
                  )}
                </>
              );
            }}
          </Focusable>
        }
      />

      <CourseInfo course={course} />

      <CoursePublisher course={course} />

      <Box my="50px">
        <CourseDayCarousel course={course} />
      </Box>

      <MemberPlusAd campaign={BranchCampaign.courseDetail} />

      <CourseQRModal
        isOpen={isOpen}
        onClose={onClose}
        course={course}
        campaign={BranchCampaign.courseDetailModal}
      />
    </Box>
  );
};

export default CourseOverview;
