import { Box } from "@chakra-ui/react";
import React from "react";
import useSWR from "swr";
import {
  getFilteredLive,
  LIVE_EVENTS_REFRESH_INTERVAL,
} from "../common/api/LiveEvents";
import { today } from "../common/utils/helpers";
import LiveEventsHeroSection from "../components/Section/LiveEventsHeroSection";
import LiveEventsSection from "../components/Section/LiveEventsSection";
import FullPageSpinner from "../components/Spinner/FullPageSpinner";

const LiveEvents = () => {
  const now = today();

  const { data: todayEvents, isValidating: isTodayEventsLoading } = useSWR(
    "api/events/today",
    () =>
      getFilteredLive({
        offset: 0,
        limit: 20,
        start_date_from: now.valueOf(),
        start_date_to: now.add(1, "days").subtract(1, "seconds").valueOf(),
      }),
    {
      refreshInterval: LIVE_EVENTS_REFRESH_INTERVAL,
    }
  );

  const { data: tomorrowEvents, isValidating: isTomorrowEventsLoading } =
    useSWR("api/events/tomorrow", () =>
      getFilteredLive({
        offset: 0,
        limit: 20,
        start_date_from: now.add(1, "day").valueOf(),
        start_date_to: now.add(2, "day").subtract(1, "seconds").valueOf(),
      })
    );

  const { data: nextDayEvents, isValidating: isNextDayEventsLoading } = useSWR(
    "api/events/next-day",
    () =>
      getFilteredLive({
        offset: 0,
        limit: 20,
        start_date_from: now.add(2, "day").valueOf(),
        start_date_to: now.add(3, "day").subtract(1, "seconds").valueOf(),
      })
  );

  if (!todayEvents) return <FullPageSpinner />;

  return (
    <Box>
      <LiveEventsHeroSection liveEvents={todayEvents} />

      <LiveEventsSection
        title="Today's schedule"
        events={todayEvents}
        isLoading={isTodayEventsLoading}
      />

      <LiveEventsSection
        title="Tomorrow"
        events={tomorrowEvents}
        isLoading={isTomorrowEventsLoading}
      />

      <LiveEventsSection
        title={now.add(2, "day").format("dddd")}
        events={nextDayEvents}
        isLoading={isNextDayEventsLoading}
      />
    </Box>
  );
};

export default LiveEvents;
