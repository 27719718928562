import gsap from "gsap";
import ScrollToPlugin from "gsap/ScrollToPlugin";
import React from "react";
import { render } from "react-dom";
import App from "./App";

gsap.registerPlugin(ScrollToPlugin);

const appElement = <App />;

// In a browser environment, render instead of exporting
if (typeof window !== "undefined") {
  render(appElement, document.getElementById("root"));
}

export default appElement;
