import gsap from "gsap";
import React from "react";

const useScrollIntoView = (
  ref?: React.RefObject<HTMLDivElement>,
  scrollOffset = 50
) => {
  function scrollToTop() {
    gsap.to(window, {
      duration: 0.3,
      scrollTo: 0,
      ease: "power4",
    });
  }

  function scrollIntoView() {
    if (ref?.current) {
      gsap.to(window, {
        duration: 0.3,
        scrollTo: ref.current.offsetTop - scrollOffset,
        ease: "power4",
      });
    } else {
      console.error(
        "You must pass a ref into to useScrollIntoView when using the scrollIntoView function!"
      );
    }
  }

  return {
    scrollToTop,
    scrollIntoView,
  };
};

export default useScrollIntoView;
