import { Box } from "@chakra-ui/react";
import React from "react";

interface Props {
  isVisible: boolean;
}

const PlayerBottomGradient = ({ isVisible }: Props) => {
  return (
    <Box
      pos="absolute"
      bottom={0}
      h="50%"
      w="100%"
      bg={`linear-gradient(0deg, black 0%, transparent 100%)`}
      opacity={isVisible ? 0.4 : 0}
      transition="opacity 0.3s"
      zIndex={5}
    />
  );
};

export default PlayerBottomGradient;
