import { Box } from "@chakra-ui/react";
import React from "react";
import { getFeaturedList } from "../common/api/FeaturedList";
import { getFilteredCourses } from "../common/api/LibraryItems";
import { FeaturedListEntryPoint } from "../common/types/FeaturedList";
import { BranchCampaign } from "../common/utils/branch";
import LazyLibraryItemCarousel from "../components/Carousel/LazyLibraryItemCarousel";
import MemberPlusAd from "../components/MemberPlusAd/MemberPlusAd";
import CourseHeroSection from "../components/Section/CourseHeroSection";

const Courses = () => {
  function getFeatured() {
    return getFeaturedList(FeaturedListEntryPoint.Courses);
  }

  function getNewest() {
    return getFilteredCourses({ sort_option: "newest" });
  }

  function getMisc1() {
    return getFeaturedList(FeaturedListEntryPoint.CoursesMisc1);
  }

  function getMisc2() {
    return getFeaturedList(FeaturedListEntryPoint.CoursesMisc2);
  }

  function getMisc3() {
    return getFeaturedList(FeaturedListEntryPoint.CoursesMisc3);
  }

  return (
    <Box>
      <CourseHeroSection />

      <Box mt="-10rem">
        <LazyLibraryItemCarousel
          focusKey="COURSES-FEATURED"
          title="Featured"
          swrKey="api/courses/featured"
          fetcherFn={getFeatured}
        />

        <LazyLibraryItemCarousel
          focusKey="COURSES-NEW-COURSES"
          title="New Courses"
          swrKey="api/courses/new"
          fetcherFn={getNewest}
        />

        <MemberPlusAd campaign={BranchCampaign.courses} />

        <LazyLibraryItemCarousel
          focusKey="COURSES-MISC-1"
          swrKey="api/courses/misc_1"
          fetcherFn={getMisc1}
          size="lg"
        />

        <LazyLibraryItemCarousel
          focusKey="COURSES-MISC-2"
          swrKey="api/courses/misc_2"
          fetcherFn={getMisc2}
          size="lg"
        />

        <LazyLibraryItemCarousel
          focusKey="COURSES-MISC-3"
          swrKey="api/courses/misc_3"
          fetcherFn={getMisc3}
          size="lg"
        />

        <Box height={24} />
      </Box>
    </Box>
  );
};

export default Courses;
