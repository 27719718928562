import { useCallback, useEffect } from "react";
import { focusManager } from "../common/global/focusManager";

let isScrolling = false;
const scrollDuration = 600;

export const useScrollWheelNavigation = () => {
  const onTouchMove = useCallback((event) => {
    if (isScrolling) return;

    isScrolling = true;

    if (event.deltaY > 0) {
      focusManager.moveDown();
    } else if (
      event.deltaY < 0 &&
      document.body.getBoundingClientRect().top < 0
    ) {
      focusManager.moveUp();
    }

    setTimeout(() => {
      isScrolling = false;
    }, scrollDuration);
  }, []);

  useEffect(() => {
    const instance = document.body;
    instance.style.overflow = "hidden";
    instance?.addEventListener("mousewheel", onTouchMove);
  }, [onTouchMove]);
};
