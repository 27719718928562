import { Flex } from "@chakra-ui/react";
import React from "react";
import { Focusable } from "react-sunbeam";
import { useFocusKey } from "../../hooks/useFocusKey";
import { NavigationItem } from "./SideBarNavItems";
import SidebarNavMenuItemContents from "./SidebarNavMenuItemContents";

interface Props {
  index: number;
  navItem: NavigationItem;
  navbarIsOpen: boolean;
  onFocus: (index: number) => void;
}

const SidebarNavMenuItem = ({
  index,
  navItem,
  navbarIsOpen,
  onFocus,
}: Props) => {
  const { activeNavItem } = useFocusKey();
  const isCurrentRoute = activeNavItem === navItem;

  function handleOnFocus() {
    onFocus(index);
  }

  return (
    <Focusable
      key={navItem.focusKey}
      focusKey={navItem.focusKey}
      onFocus={handleOnFocus}
    >
      {({ focused }) => {
        const isActiveNav = (isCurrentRoute && !navbarIsOpen) || focused;
        return (
          <Flex
            align="center"
            justify="flex-start"
            h="65px"
            w={navbarIsOpen ? "350px" : "65px"}
            ml={5}
            pl="17px"
            opacity={isActiveNav ? 1 : 0.8}
            bg={isActiveNav ? "white" : undefined}
            borderRadius={16}
            role="group"
            _hover={{
              bg: "white",
            }}
          >
            <SidebarNavMenuItemContents
              navItem={navItem}
              navbarIsOpen={navbarIsOpen}
              isActive={isActiveNav}
            />
          </Flex>
        );
      }}
    </Focusable>
  );
};

export default SidebarNavMenuItem;
