import { Box, Button, Heading } from "@chakra-ui/react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import React, { useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import { Focusable } from "react-sunbeam";
import useSWR from "swr";
import { getMoreFromTeacher } from "../common/api/LibraryItems";
import {
  getAllLive,
  LIVE_EVENTS_REFRESH_INTERVAL,
} from "../common/api/LiveEvents";
import { FocusKey, focusManager } from "../common/global/focusManager";
import { EventSummary } from "../common/types/Event";
import { handleKeyPress } from "../common/utils/keyboard";
import LazyLibraryItemCarousel from "../components/Carousel/LazyLibraryItemCarousel";
import LiveEventHeroSlide from "../components/Carousel/LiveEventHeroSlide";
import LiveEventsCarousel from "../components/Carousel/LiveEventsCarousel";
import { useFocusKey } from "../hooks/useFocusKey";
import useScrollIntoView from "../hooks/useScrollIntoView";

dayjs.extend(relativeTime);

interface Props {
  event: EventSummary;
  hasEnded: boolean;
}

const LiveEventLobby = ({ event, hasEnded }: Props) => {
  const carouselRef = useRef(null);
  const { scrollToTop, scrollIntoView } = useScrollIntoView(carouselRef);
  const startsIn = dayjs(event._next_occurrences[0].start_date.epoch).fromNow();
  const [, setSearchParams] = useSearchParams();
  const { activeNavItem } = useFocusKey();

  const { data: liveEvents, isValidating } = useSWR(
    "/api/home/live",
    () => getAllLive(),
    {
      refreshInterval: LIVE_EVENTS_REFRESH_INTERVAL,
    }
  );

  function onKeyPress(keyboardEvent: KeyboardEvent) {
    handleKeyPress(keyboardEvent, {
      onLeftArrow: () =>
        focusManager.setFocus([FocusKey.Navigation, activeNavItem.focusKey]),
    });
  }

  function getMoreFromTeacherTracks() {
    return getMoreFromTeacher(event.owner.id);
  }

  useEffect(() => {
    setSearchParams({}, { replace: true });
  }, [setSearchParams]);

  return (
    <>
      <Focusable onFocus={scrollToTop} onKeyPress={onKeyPress}>
        <LiveEventHeroSlide
          event={event}
          showDescription={!hasEnded}
          descriptionNoOfLines={6}
          heroButton={
            hasEnded ? (
              <Heading size="sm" pt={12}>
                This event has ended
              </Heading>
            ) : (
              <Button
                bg="bgGrayMedium"
                color="#AFB4B4"
                height="75px"
                paddingX="50px"
                borderRadius={14}
                fontSize="md"
                _hover={{
                  bg: "bgGrayMedium",
                  color: "white",
                }}
              >
                Starts {startsIn}
              </Button>
            )
          }
        />
      </Focusable>

      <Box ref={carouselRef} mt={-16}>
        <LiveEventsCarousel
          focusKey={`LIVE-EVENTS-${event.id}`}
          events={liveEvents ?? []}
          title="Live Events"
          isLoading={isValidating}
          onFocus={scrollIntoView}
        />
      </Box>

      <LazyLibraryItemCarousel
        focusKey={`MORE-FROM-TEACHER-${event.id}`}
        title="More From This Teacher"
        swrKey={`api/live/${event.owner.id}/more-from-teacher`}
        fetcherFn={getMoreFromTeacherTracks}
        size="md"
      />

      <Box height={16} />
    </>
  );
};

export default LiveEventLobby;
