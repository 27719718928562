import { Box, Flex, FlexProps, Heading } from "@chakra-ui/react";
import React, { memo } from "react";
import BackgroundTopicImage from "../../assets/images/background-topic.jpg";
import { SIDEBAR_WIDTH_PX } from "../../common/utils/helpers";

interface Props extends FlexProps {
  heading: string;
  subHeading: string;
}

const GeneralTopicHeroSection = ({ heading, subHeading, ...props }: Props) => {
  return (
    <Flex
      height="65vh"
      backgroundImage={`linear-gradient(180deg, rgba(21, 23, 23, 0) 0%, #151717 80%), url(${BackgroundTopicImage});`}
      backgroundSize="cover"
      backgroundPosition="center"
      align="center"
      {...props}
    >
      <Box ml={SIDEBAR_WIDTH_PX} pl="10px" width="60%">
        <Heading
          fontSize="82px"
          fontWeight={700}
          mb="16px"
          textTransform="unset"
        >
          {heading}
        </Heading>

        <Heading size="md" fontWeight={500} lineHeight={1.25}>
          {subHeading}
        </Heading>
      </Box>
    </Flex>
  );
};

export default memo(GeneralTopicHeroSection);
