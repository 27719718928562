import { Heading, Text, VStack } from "@chakra-ui/react";
import React, { memo } from "react";
import { LibraryItemSummary } from "../../common/types/Item";
import CourseDayCountBadge from "../Badge/CourseDayCountBadge";
import TrackLengthBadge from "../Badge/TrackLengthBadge";

interface Props {
  libraryItem: LibraryItemSummary;
}

const LibraryItemTileContent = ({ libraryItem }: Props) => {
  return (
    <VStack spacing={1.25} align="flex-start" zIndex={15}>
      {libraryItem.media_length && (
        <TrackLengthBadge libraryItem={libraryItem} />
      )}

      {libraryItem.item_type === "COURSES" && libraryItem.days && (
        <CourseDayCountBadge days={libraryItem.days} />
      )}

      <Heading size="sm" noOfLines={2} pr={8} fontSize="28px" lineHeight="36px">
        {libraryItem.title}
      </Heading>

      <Text fontSize={22} fontWeight={500} noOfLines={2}>
        {libraryItem.publisher.name}
      </Text>
    </VStack>
  );
};

export default memo(LibraryItemTileContent);
