import { BareFetcher } from "swr";
import useSWRInfinite, { SWRInfiniteKeyLoader } from "swr/infinite";
import { LibraryItemSummary } from "../common/types/Item";

const useInfiniteScroll = <Data extends LibraryItemSummary[]>(
  swrKey: SWRInfiniteKeyLoader,
  fetcher: BareFetcher<Data>,
  limit: number
) => {
  const { data, size, setSize, error, isValidating } = useSWRInfinite(
    swrKey,
    fetcher,
    {
      revalidateFirstPage: false,
    }
  );

  const isLoadingInitialData = !data && !error;
  const isLoadingMore =
    isLoadingInitialData ||
    (size > 0 && data && typeof data[size - 1] === "undefined");
  const isReachingEnd =
    (data != null && data[size - 1] && data[size - 1].length < limit) || false;

  return {
    data,
    size,
    setSize,
    isLoadingInitialData,
    isLoadingMore,
    isReachingEnd,
    isValidating,
  };
};

export default useInfiniteScroll;
