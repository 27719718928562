import { Box, BoxProps, Heading } from "@chakra-ui/react";
import React, { Dispatch, memo, SetStateAction, useEffect } from "react";
import { Topic } from "../../common/types/Topic";
import { TrackingEventName } from "../../common/types/Tracking";
import { useAnalytics } from "../../hooks/useAnalytics";

interface Props extends BoxProps {
  topic: Topic;
  isActive: boolean;
  isLast?: boolean;
  onActive: Dispatch<SetStateAction<Topic>>;
}

const TabItem = ({
  topic,
  isActive,
  isLast = false,
  onActive,
  ...props
}: Props) => {
  const { trackClick } = useAnalytics();

  useEffect(() => {
    if (isActive) {
      trackClick({
        click_event_name: TrackingEventName.topicFilterClicked,
        topic_id: topic.id,
      });
      onActive(topic);
    }
  }, [isActive, onActive, topic, trackClick]);

  return (
    <Box
      pb={8}
      borderBottomWidth="5px"
      borderBottomColor={isActive ? "white" : undefined}
      borderBottomStyle={isActive ? "solid" : "none"}
      mr={isLast ? "150px" : 0}
      {...props}
    >
      <Heading
        size="md"
        color={isActive ? "white" : "bgGrayLight"}
        fontWeight={700}
        transition="0.3s"
      >
        {topic.name}
      </Heading>
    </Box>
  );
};

export default memo(TabItem);
