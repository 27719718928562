import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

export const HIDE_SIDEBAR_KEY = "hs" as const;

const useHideSidebarNav = () => {
  const [, setSearchParams] = useSearchParams();

  useEffect(() => {
    // "hs" for "hide sidebar"
    setSearchParams({ [HIDE_SIDEBAR_KEY]: "true" }, { replace: true });
  }, [setSearchParams]);
};

export default useHideSidebarNav;
