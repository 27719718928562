import { Button, ButtonProps, Image } from "@chakra-ui/react";
import React, { memo, MouseEvent } from "react";

interface Props extends ButtonProps {
  isFocused: boolean;
  icon?: string;
  iconSize?: string;
  isDisabled?: boolean;
  iconFilter?: string;
  onClick?: (event: MouseEvent) => void;
}

const HeroButton: React.FC<Props> = ({
  isFocused,
  icon,
  iconSize = "20px",
  isDisabled = false,
  iconFilter,
  onClick,
  children,
  ...props
}) => {
  const iconImageFilter = iconFilter
    ? iconFilter
    : isFocused
    ? "invert(0%)"
    : "invert(100%)";

  return (
    <Button
      bg={isFocused ? "white" : "buttonUnfocused"}
      color={isFocused ? "black" : "white"}
      height="75px"
      paddingX="50px"
      borderRadius={14}
      fontSize="md"
      isDisabled={isDisabled}
      onClick={onClick}
      {...props}
    >
      {icon && (
        <Image
          src={icon}
          width={iconSize}
          height={iconSize}
          filter={iconImageFilter}
          mr={4}
        />
      )}
      {children}
    </Button>
  );
};

export default memo(HeroButton);
